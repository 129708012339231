import React from 'react'
import './css/ShowEducationModal.css'
import CloseIcon from '@mui/icons-material/Close';

const ShowExperienceModal = ({ experienceData, onClose }) => {
    return (
        <>
            <div className='sem__container'>
                <div className="sem__main">
                    <h1>All Experiences
                        <span onClick={() => { onClose() }} >
                            <CloseIcon fontSize='4rem' color='white' />
                        </span>
                    </h1>
                    <div className="profile__about-exp">
                        {/* <h1>Experience</h1> */}
                        {experienceData?.map((exp, index) => (
                            <div className="profile__about-exp-comp" key={index}>
                                <div className="profile__about-exp-comp-img">
                                    <img src={exp.companyImage} alt="" />
                                    <h2>{exp.companyName}</h2>
                                </div>
                                <div className="profile__about-exp-comp-text">
                                    <h1>{exp.title}</h1>
                                    <span>
                                        {exp.startMonth} {exp.startYear} - {exp.endMonth} {exp.endYear}
                                    </span>
                                </div>
                                <p style={{ color: 'white' }}>
                                    {exp.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShowExperienceModal