import React from "react";
import Navbar2 from "../../components/Navbar2";
import ScrollTop from "../../components/miscellenius/ScrollTop";

const TermsCondition = () => {
  return (
    <>
      <Navbar2 />
      <div className="bg-gray-900 text-white min-h-screen" style={{background:
        "linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent 130%), linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent 130%), linear-gradient(to bottom, #714FB4, #714FB4)"}}>
        <div className="container mx-auto py-8">
          <h1 className="text-5xl font-bold mb-4">
            Terms and Conditions for inQbator Technologies LLP
          </h1>
          <p>Last Updated: 14th November</p>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">
              1. Acceptance of Terms
            </h2>
            <p className="text-2xl mb-2">
              By accessing and using the inQbator website (inqbator.in), you
              agree to comply with and be bound by these Terms and Conditions.
              If you do not agree to these terms, please refrain from using our
              website.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">
              2. Mentorship Agreement
            </h2>
            <p className="text-2xl mb-2">
              a. Eligibility: Users seeking mentorship services must meet the
              eligibility criteria outlined by inQbator.
            </p>
            <p className="text-2xl mb-2">
              b. Responsibilities: Mentors and mentees agree to engage in a
              professional and respectful manner. inQbator is not responsible
              for the outcomes of mentorship relationships.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">
              3. Data Collection and Privacy
            </h2>
            <p className="text-2xl mb-2">
              a. Information Collection: inQbator collects and stores user data
              for legitimate business purposes. This may include personal
              information, which is handled in accordance with our Privacy
              Policy.
            </p>
            <p className="text-2xl mb-2">
              b. Cookies: The Website may use cookies to enhance user
              experience. Users can manage cookie preferences through their
              browser settings.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">
              4. Intellectual Property
            </h2>
            <p className="text-2xl mb-2">
              a. Content Ownership: inQbator retains ownership of all content on
              the Website, including but not limited to text, graphics, logos,
              and software.
            </p>
            <p className="text-2xl mb-2">
              b. User-Generated Content: Users grant inQbator a non-exclusive,
              royalty-free license to use, reproduce, and distribute
              user-generated content for website-related purposes.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">
              5. Limitation of Liability
            </h2>
            <p className="text-2xl mb-2">
              inQbator is not liable for any direct, indirect, incidental,
              consequential, or punitive damages arising from the use of the
              Website.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">
              6. Modifications to Terms
            </h2>
            <p className="text-2xl mb-2">
              inQbator reserves the right to update, modify, or replace these
              Terms and Conditions at any time without notice. Users are
              responsible for regularly reviewing these terms.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">
              7. Refund Policy for Mentorship Sessions
            </h2>
            <p className="text-2xl mb-2">
              a. Payment for Mentorship Sessions: Users may be required to pay
              for mentorship sessions through the inQbator platform.
            </p>
            <p className="text-2xl mb-2">
              b. Refund Request: In the event that a paid mentorship session is
              not attended by the mentor or is rescheduled, users have the right
              to request a refund.
            </p>
            <p className="text-2xl mb-2">
              c. Refund Process: Refund requests must be submitted to inQbator
              within 2 Hours. inQbator will review the request and, if found
              valid, will process the refund to the user's paid account within
              48 hours.
            </p>
            <p className="text-2xl mb-2">
              d. Cancellation by User: Refunds will not be provided if the user
              cancels a mentorship session without proper notice as outlined in
              the platform's cancellation policy.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">
              8. Dispute Resolution
            </h2>
            <p className="text-2xl mb-2">
              In case of disputes related to refunds or any other matter, users
              agree to attempt to resolve the issue with inQbator through
              negotiation and good faith discussions before pursuing any formal
              legal action.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">
              9. Contact Information
            </h2>
            <p className="text-2xl mb-2">
              For any questions or concerns regarding these terms, please
              contact us at{" "}
              <a href="mailto:inqbatorindia@gmail.com" className="underline">
                inqbatorindia@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <ScrollTop/>
    </>
  );
};

export default TermsCondition;
