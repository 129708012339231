import * as React from 'react';
import './AddExperience.css'; // Replace with the actual CSS file path
import { useState, useEffect } from 'react';
import API_URL from '../../../api/Api';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';

const EditExperience = ({ exp, onClose }) => {
    const [experienceData, setExperienceData] = useState({ ...exp });
    const [deleteBtn, setDeleteBtn] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);

    const imageRef = React.useRef(null);

    function useDisplayImage() {
        const [result, setResult] = React.useState(false);

        function uploader(e) {
            const imageFile = e.target.files[0];

            const reader = new FileReader();
            reader.addEventListener('load', (e) => {
                setResult(e.target.result);
            });

            reader.readAsDataURL(imageFile);
        }

        return { result, uploader };
    }

    const { result, uploader } = useDisplayImage();

    const handleInputChange = (event, fieldName) => {
        const { value } = event.target;
        setExperienceData((prevExperienceData) => ({
            ...prevExperienceData,
            [fieldName]: value,
        }));
    };

    const handleSave = async () => {
        try {
            setSendLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                },
            };

            const response = await axios.patch(
                `${API_URL}/api/mentor/experience/${exp._id}`,
                {
                    title: experienceData.title,
                    employmentType: experienceData.employmentType,
                    location: experienceData.location,
                    companyName: experienceData.companyName,
                    companyImage: result,
                    description: experienceData.description,
                    startMonth: experienceData.startMonth,
                    startYear: experienceData.startYear,
                    endMonth: experienceData.endMonth,
                    endYear: experienceData.endYear,
                },
                config
            );

            if (response.status === 202) {
                console.log(response.data);
                setSendLoading(false)
                onClose(); // Close the modal
                toast.success('Experience edited successfully');
            } else {
                console.log('Failed to update education');
                toast.error('Error while editing experience ');
                setSendLoading(false)
            }
        } catch (error) {
            console.log('Error updating education:', error);
            toast.error('Error while editing experience ');
            setSendLoading(false)
        }
    };


    const handleDelete = async () => {
        try {
            setSendLoading(true)
            await axios.delete(
                `${API_URL}/api/mentor/experience/${exp._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                    },
                }
            );
            onClose();
            setSendLoading(false)
            toast.success('Experience deleted successfully');
        } catch (error) {
            console.log(error);
            setSendLoading(false)
            toast.error('Experience editing experience');
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={sendLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='editEducation__container'>
                <div className="editEducation__main">
                    <h1>Edit Experience:
                        <span onClick={() => { onClose() }} >
                            <CloseIcon fontSize='4rem' color='white' />
                        </span>
                    </h1>
                    <div>
                        <div className='editEducation__map'>
                            <div className='addExperience__formcol'>
                                <div>
                                    <h1>Title*</h1>
                                    <input
                                        type="text"
                                        placeholder='Enter your job title'
                                        value={experienceData.title}
                                        onChange={(e) => handleInputChange(e, 'title')}
                                    />
                                </div>
                            </div>

                            <div className='addExperience__formcol'>
                                <div className='addExperience__form-margin' >
                                    <h1>Employment type*</h1>
                                    <input
                                        type="text"
                                        placeholder='Type of employment'
                                        value={experienceData.employmentType}
                                        onChange={(e) => handleInputChange(e, 'employmentType')}
                                    />
                                </div>
                                <div>
                                    <h1>Location*</h1>
                                    <input
                                        type="text"
                                        placeholder='Location'
                                        value={experienceData.location}
                                        onChange={(e) => handleInputChange(e, 'location')}
                                    />
                                </div>
                            </div>

                            <div className='addExperience__formcol'>
                                <div className='addExperience__form-margin'>
                                    <h1>Company name*</h1>
                                    <input
                                        type="text"
                                        placeholder='Company name'
                                        value={experienceData.companyName}
                                        onChange={(e) => handleInputChange(e, 'companyName')}
                                    />
                                </div>
                                <div>
                                    <h1>Company Image*</h1>
                                    <div className='addExperience__upload'>
                                        <div className='addExperience__upload-img'>
                                            {!experienceData.companyImage ? 'Choose Image' : 'Change'}
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => uploader(e)}
                                            />
                                        </div>

                                        {!result ? <>
                                            {experienceData.companyImage &&
                                                <div className='addExperience__upload-viewimg'>
                                                    <img src={experienceData.companyImage} alt="Uploaded" />
                                                </div>
                                            }
                                        </> : (
                                            <>
                                                <div className='addExperience__upload-viewimg'>
                                                    <img ref={imageRef} src={result} alt="Uploaded" />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='addExperience__formcol'>
                                <div>
                                    <h1>Description*</h1>
                                    <textarea
                                        placeholder='Ex: a brief note about your job'
                                        value={experienceData.description}
                                        onChange={(e) => handleInputChange(e, 'description')}
                                    />
                                </div>
                            </div>

                            <div className='addExperience__formcol'>
                                <div className='addExperience__form-margin'>
                                    <h1>Start Month*</h1>
                                    <input
                                        type="text"
                                        placeholder='Month'
                                        value={experienceData.startMonth}
                                        onChange={(e) => handleInputChange(e, 'startMonth')}
                                    />
                                </div>
                                <div>
                                    <h1>Start year*</h1>
                                    <input
                                        type="text"
                                        placeholder='Year'
                                        value={experienceData.startYear}
                                        onChange={(e) => handleInputChange(e, 'startYear')}
                                    />
                                </div>
                            </div>

                            <div className='addExperience__formcol'>
                                <div className='addExperience__form-margin'>
                                    <h1>End Month*</h1>
                                    <input
                                        type="text"
                                        placeholder='Month'
                                        value={experienceData.endMonth}
                                        onChange={(e) => handleInputChange(e, 'endMonth')}

                                    />
                                </div>
                                <div>
                                    <h1>End year*</h1>
                                    <input
                                        type="text"
                                        placeholder='Year'
                                        value={experienceData.endYear}
                                        onChange={(e) => handleInputChange(e, 'endYear')}
                                    />
                                </div>
                            </div>

                            <div className='editEducation__delete'>
                                {!deleteBtn && <div onClick={() => { setDeleteBtn(true) }}>
                                    Delete
                                </div>}
                                {deleteBtn && <div onClick={() => { setDeleteBtn(false) }}>
                                    <CloseIcon sx={{ fontSize: '2.2rem' }} />
                                </div>}
                                {deleteBtn && <div onClick={handleDelete} style={{ marginLeft: '1rem' }}>
                                    <DoneIcon sx={{ fontSize: '2.2rem' }} />
                                </div>}
                            </div>
                        </div>

                        <div className='editEducation__save' onClick={handleSave}>
                            <div>
                                Save
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditExperience;
