import React, { useState } from 'react'
import CalenderTest from "../BookFreeTrial/CalenderTest";
import CloseIcon from '@mui/icons-material/Close';
import './RescheduleModal.css'
import { DataState } from '../../context/DataProvider';
import API_URL from '../../api/Api';
import axios from 'axios';
import SlotSelection from './SlotSelection';
import { Backdrop, CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';


const TimeSlotSelectionModal = ({ onClose, userId }) => {

    const [availSlotLoading, setAvailSlotLoading] = useState(false);
    const [selectedTime, setSelectedTime] = useState([]);

    const handleAvailSlots = async () => {
        try {
            if (selectedTime.length === 0) {
                toast.error('Select some slots first');
                return;
            }
            setAvailSlotLoading(true)
            const response = await axios.put(
                `${API_URL}/api/mentorAvailableSlot/update`,
                { userId: userId, ids: selectedTime },
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem("token")
                        )}`,
                    },
                }
            );
            if (response.data.ok === true) {
                onClose()
                setAvailSlotLoading(false)
                toast.success('Your availability successfully updated');
            }
        } catch (error) {
            console.log("Experience", error);
            setAvailSlotLoading(false)
            // toast.error('Error availabiling slots');
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={availSlotLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='r_modal__container'>
                <div className="r_modal__main">
                    <h1>Set your availability :
                        <span onClick={() => { onClose() }} >
                            <CloseIcon fontSize='4rem' color='white' />
                        </span>
                    </h1>
                    <div>
                        <div className='cd__cal'>
                            <SlotSelection
                                userId={userId}
                                selectedTime={selectedTime}
                                setSelectedTime={setSelectedTime}
                            />
                        </div>

                    </div>
                    <div className='r_modal__save' onClick={handleAvailSlots}>
                        <div>
                            Save
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TimeSlotSelectionModal