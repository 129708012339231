import React, { useEffect, useState } from "react";
import "./QuestionCard.css";
import axios from "axios";
import API_URL from "../../api/Api";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { DataState } from "../../context/DataProvider";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";

const DateTimeConverter = ({ utcTimestamp }) => {
  const convertUtcToIst = (utcTimestamp) => {
    // Create a Date object from the UTC timestamp
    const utcDate = new Date(utcTimestamp);

    // Convert to IST (Indian Standard Time, UTC+05:30)
    utcDate.setHours(utcDate.getHours()); // Add 5 hours
    utcDate.setMinutes(utcDate.getMinutes()); // Add 30 minutes

    // Format the date and time
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      day: "numeric",
      month: "short",
      year: "numeric",
      // timeZoneName: "short",
    };

    return utcDate.toLocaleDateString("en-US", options);
  };

  const formattedDateTime = convertUtcToIst(utcTimestamp);

  return <>{formattedDateTime}</>;
};

const QuestionCard = ({ ques }) => {
  const { user } = DataState();
  const [userData, setUserData] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [replyInputText, setReplyInputText] = useState("");
  const [openReply, setOpenReply] = useState(false);

  const [likeAndDislike, setLikeAndDislike] = useState(false);

  useEffect(() => {
    const userToken = JSON.parse(localStorage.getItem("token"));
    if (userToken) {
      setAccessToken(userToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/particular-user-data/${ques.from}`
        );
        setUserData(response.data.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [ques.from]);

  const handleReplyClick = () => {
    setOpenReply(!openReply);
    if (!openReply) {
      setReplyInputText("");
    }
  };

  const handleReplyInputChange = (e) => {
    setReplyInputText(e.target.value);
  };

  const handleLikeAndDislike = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/question/getLikeAndDislike/${ques?._id}`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
      setLikeAndDislike(response.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLike = async () => {
    try {
      await axios.post(
        `${API_URL}/api/question/like/${ques?._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      handleLikeAndDislike();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDislike = async () => {
    try {
      await axios.post(
        `${API_URL}/api/question/dislike/${ques?._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      handleLikeAndDislike();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleLikeAndDislike();
  });

  const linkToCopy = `https://inqbator.in/question?queId=${ques._id}`;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="qcard__main">
        <div className="qcard__name">
          <Avatar
            src={userData?.picture}
            sx={{ width: "3.7rem", height: "3.7rem" }}
          ></Avatar>
          <h1>
            Asked by {userData?.name} |{" "}
            {<DateTimeConverter utcTimestamp={ques.createdAt} />}
          </h1>
        </div>

        <div className="qcard__ques">
          <p>{ques?.question}</p>
        </div>

        {ques?.answers?.length > 0 ? (
          <>
            <Link to={`/question?queId=${ques._id}`}>
              <div className="qcard__ans">
                <h3>Answers</h3>
                <p>
                  {ques?.answers[0].message.slice(0, 350)}{" "}
                  {ques?.answers[0].message.length > 350 && (
                    <span> . . .(read more)</span>
                  )}
                </p>
              </div>
            </Link>
            <div className="qcard__profile-main">
              <div className="qcard__profile">
                <div className="qcard__profile-view">
                  <Avatar
                    src={userData?.picture}
                    sx={{ width: "6rem", height: "6rem" }}
                  ></Avatar>
                  <div>
                    <h2>{userData?.name}</h2>
                    <div>
                      <div>
                        <img src="./assets/ms-logo.svg" alt="" />
                      </div>
                      <div>
                        <h2>Microsoft</h2>
                        <p>UI/UX Designer</p>
                      </div>
                    </div>
                  </div>
                </div>
                <h1>View Profile</h1>
              </div>
              <Link to={`/question?queId=${ques._id}`}>
                <div className="qcard__profile-ans">
                  {ques?.answers?.length}{" "}
                  {ques?.answers?.length === 1 ? "answer" : "answers"}
                </div>
              </Link>
            </div>
          </>
        ) : (
          <div className="qcard__profile-main">
            <h2>No answers</h2>
          </div>
        )}

        <div className="qcard__reply-main">
          <Link to={`/question?queId=${ques._id}&openInput=true`}>
            <div className="qcard__reply-btn">
              <p>Share Your Answers</p>
            </div>
          </Link>
          <div className="qcard__reply">
            <div className="qcard__reply-like">
              {!likeAndDislike?.likes?.includes(user?._id) ? (
                <ThumbUpOffAltIcon
                  onClick={handleLike}
                  sx={{ fontSize: "2.5rem" }}
                />
              ) : (
                <ThumbUpAltIcon
                  onClick={handleLike}
                  sx={{
                    fontSize: "2.5rem",
                  }}
                />
              )}
              <span>{likeAndDislike?.likes?.length}</span>
            </div>
            <div className="qcard__reply-dislike">
              {!likeAndDislike?.dislikes?.includes(user?._id) ? (
                <ThumbDownOffAltIcon
                  onClick={handleDislike}
                  sx={{
                    fontSize: "2.5rem",
                  }}
                />
              ) : (
                <ThumbDownAltIcon
                  onClick={handleDislike}
                  sx={{
                    fontSize: "2.5rem",
                  }}
                />
              )}
              <span>{likeAndDislike?.dislikes?.length}</span>
            </div>
            <div
              className="qcard__reply-share"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <img src="./assets/Vector (5).png" alt="" />
            </div>
            <div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={handleClose} sx={{ padding: "0px" }}>
                  <LinkedinShareButton url={linkToCopy}>
                    <div className="qcard__reply-share-items">
                      <LinkedInIcon sx={{ fontSize: "2.5rem" }} />
                      <span>Share on Linkedin</span>
                    </div>
                  </LinkedinShareButton>
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{ padding: "0px" }}>
                  <TwitterShareButton url={linkToCopy}>
                    <div className="qcard__reply-share-items">
                      <TwitterIcon sx={{ fontSize: "2.5rem" }} />
                      <span>Share on Twitter</span>
                    </div>
                  </TwitterShareButton>
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{ padding: "0px" }}>
                  <FacebookShareButton url={linkToCopy}>
                    <div className="qcard__reply-share-items">
                      <FacebookIcon sx={{ fontSize: "2.5rem" }} />
                      <span>Share on Facebook</span>
                    </div>
                  </FacebookShareButton>
                </MenuItem>
              </Menu>
            </div>
          </div>

          {openReply === ques?._id ? (
            <div className="ccard__reply">
              <Avatar
                src={user?.picture}
                sx={{ width: "3.4rem", height: "3.4rem" }}
              />
              <div className="ccard_reply-input">
                <input
                  type="text"
                  placeholder="Add a reply"
                  value={replyInputText}
                  onChange={handleReplyInputChange}
                />
                <div>
                  <span onClick={handleReplyClick}>Cancel</span>
                  <span onClick={""}>Reply</span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default QuestionCard;
