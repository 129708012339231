import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/ProfileIntro.css';

const ProfileIntro = ({ mentorData, checkFreeSessions }) => {
    console.log("checkFreeSessions", checkFreeSessions);

    return (
        <>
            {/* Main Profile */}
            <div className="profileIntro__body">
                <div className="profileIntro__main">
                    <div className="profileIntro__main-top">
                        <div className="profileIntro__main-top-left-main">
                            <div className="profileIntro__main-top-left">
                                <img src={mentorData?.user?.picture} alt="" />
                            </div>
                        </div>
                        <div className="profileIntro__main-top-right">
                            <div className="profileIntro__main-top-right1">
                                <h3>LONG TERM MENTORSHIP WITH</h3>
                                <h1>{mentorData?.user?.name}</h1>
                                <div className="profileIntro__main-top-company">
                                    <h1>{mentorData?.jobRole}</h1>
                                    <div>
                                        <img src={mentorData?.companyImage} alt="" />
                                        <span>{mentorData?.companyName}</span>
                                    </div>
                                </div>
                                <p>
                                    Join {mentorData?.user?.name}'s 1:1 mentorship program
                                    to take your career to next level and get into your
                                    dream job.{" "}
                                </p>
                            </div>
                            <div className="profileIntro__main-top-right2">
                                <div>
                                    <div>
                                        <img src="./assets/profile-bag.svg" alt="" />
                                        <span>{mentorData?.experience}+</span>
                                    </div>
                                    <p>YEARS OF EXPERIENCE</p>
                                </div>
                                <div>
                                    <div>
                                        <img src="./assets/profile-star.svg" alt="" />
                                        <span>{mentorData?.rating}</span>
                                    </div>
                                    <p>MENTOR'S RATING</p>
                                </div>
                                <div>
                                    <div>
                                        <img src="./assets/profile-live.svg" alt="" />
                                        <span>{mentorData?.mentorshipProvided}+</span>
                                    </div>
                                    <p>LIVE SESSIONS</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="profileIntro__main-bottom">
                        <div className="profileIntro__main-bottom-left">
                            <div>
                                <div className="profileIntro__main-bottom-left-icon">
                                    <img src="./assets/profile-phone.svg" alt="" />
                                </div>
                                <div className="profileIntro__main-bottom-left-text">
                                    <h4>Sessions</h4>
                                    <p>{mentorData?.sessions} session(s)/week</p>
                                </div>
                            </div>
                            <div>
                                <div className="profileIntro__main-bottom-left-icon">
                                    <img src="./assets/profile-chat.svg" alt="" />
                                </div>
                                <div className="profileIntro__main-bottom-left-text">
                                    <h4>Chat</h4>
                                    <p>Within 12 hours</p>
                                </div>
                            </div>
                            <div>
                                <div className="profileIntro__main-bottom-left-icon">
                                    <img src="./assets/profile-tick.svg" alt="" />
                                </div>
                                <div className="profileIntro__main-bottom-left-text">
                                    <h4>Tasks & Folloup</h4>
                                    <p>Every {mentorData?.followup} days</p>
                                </div>
                            </div>
                            <div>
                                <div className="profileIntro__main-bottom-left-icon">
                                    <img src="./assets/profile-refer.svg" alt="" />
                                </div>
                                <div className="profileIntro__main-bottom-left-text">
                                    <h4>Job referrals</h4>
                                    <span>& more</span>
                                </div>
                            </div>
                        </div>
                        <div className="profileIntro__main-bottom-right">
                            <div className="profileIntro__main-bottom-right-img">
                                <div className="profileIntro__main-bottom-right-imgstack">
                                    <div>
                                        <img src="https://th.bing.com/th/id/OIP.i-BwyQWeHwwqxnoxFh8cqwHaHW?w=194&h=193&c=7&r=0&o=5&pid=1.7" alt="" />
                                    </div>
                                    <div>
                                        <img src="./assets/profile-img.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="https://th.bing.com/th/id/OIP.mkA8HAQ8-Z2C5AIyFMpJ1wEsEs?w=212&h=212&c=7&r=0&o=5&pid=1.7" alt="" />
                                    </div>
                                    <div>
                                        <img src="https://th.bing.com/th?q=Random+Person+Profile+Picture&w=120&h=120&c=1&rs=1&qlt=90&cb=1&pid=InlineBlock&mkt=en-IN&cc=IN&setlang=en&adlt=moderate&t=1&mw=247" alt="" />
                                    </div>
                                    <div>
                                        <img src="https://th.bing.com/th/id/OIP.NvwwV2UfD-ZSYS43aj1RUwHaHa?w=186&h=199&c=7&r=0&o=5&pid=1.7" alt="" />
                                    </div>
                                </div>
                                <div className="profileIntro__main-bottom-right-imgtext">
                                    {/* {mentorData?.mentorshipProvided <= 10 && mentorData?.mentorshipProvided}{mentorData?.mentorshipProvided > 10 && "10+"} Mentees */}
                                    5+ Mentees
                                </div>
                            </div>


                            {mentorData?.paidOptions === "false" && <>
                                {checkFreeSessions?.freeSession &&
                                    < Link to={`/book-free-sessions?userId=${mentorData._id}`}>
                                        <div className="profileIntro__main-bottom-right-trial">
                                            <p>
                                                Get clarity about how the mentor will personally help
                                                you
                                                <br />
                                                achieve your goals over the next few months.
                                            </p>
                                            <h1>
                                                Free Trial Session
                                                <i className="fa-solid fa-arrow-right" />
                                            </h1>
                                        </div>
                                    </Link>
                                }
                            </>
                            }

                            <Link to={`/book-sessions?userId=${mentorData._id}`}>
                                <div className="profileIntro__main-bottom-right-paid">
                                    <h1>₹{mentorData?.remunerationValue}/ Session</h1>
                                    <p>
                                        Book a Mentorship Session in Exchange of Money
                                        <i className="fa-solid fa-arrow-right" />
                                    </p>
                                </div>
                            </Link>

                        </div>
                    </div>
                    <div className="profileIntro__experties">
                        <div>
                            <div className="profileIntro__area">
                                <h1>
                                    <span>
                                        <img src="./assets/mentorcard-area.svg" alt="" />
                                    </span>{" "}
                                    For
                                </h1>
                                <p>
                                    {mentorData?.areaOfMentorship.map((str, index) => (
                                        <span key={index} >
                                            {str}
                                            {index < mentorData?.targetSector.length - 1 && ' | '}
                                        </span>
                                    ))}
                                </p>
                            </div>
                            <div className="profileIntro__sector">
                                <h1>
                                    <span>
                                        <img src="./assets/mentorcard-sector.svg" alt="" />
                                    </span>
                                    Target sectors
                                </h1>
                                <p>
                                    {mentorData?.targetSector.map((str, index) => (
                                        <span key={index} >
                                            {str}
                                            {index < mentorData?.targetSector.length - 1 && ' | '}
                                        </span>
                                    ))}
                                </p>
                            </div>
                        </div>
                        {/* <div className="profileIntro__arrow-down">
                            <img src="./assets/profile-arrow-down.svg" alt="" />
                        </div> */}
                    </div>
                </div>
            </div >
        </>
    )
}

export default ProfileIntro