import React from "react";
import "./Navbar2.css";
import { Link, NavLink } from "react-router-dom";
import { DataState } from "../context/DataProvider";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useLocation } from "react-router-dom";

const BottomNavbar = () => {
  const location = useLocation();

  const tabs = [
    { link: "/mentorlist", text: "Mentors", icon: "/assets/nav-mentor.svg" },
    { link: "/events", text: "Events", icon: "/assets/nav-event.svg" },
    {
      link: "/askmentors",
      text: "Ask Mentors",
      icon: "/assets/nav-askmentor.svg",
    },
    { link: "https://oqlous.inqbator.in", text: "Oqlous AGI", icon: "./assets/ai-fetching.gif" },
  ];

  return (
    <nav className="nav-mob__menu">
      <ul className="nav-mob__list">
        {tabs.map((tab) => (
          <Link
            to={tab.link}
            key={tab.link}
            className={`nav-mob__item ${
              location.pathname === tab.link ? "selected" : ""
            }`}
          >
            <div>
              <img src={tab.icon} alt={tab.text} />
            </div>
            <p className="nav-mob__item-text">{tab.text}</p>
          </Link>
        ))}
      </ul>
    </nav>
  );
};

const Navbar2 = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { user, accessToken, logout } = DataState();

  return (
    <>
      <div className="new-nav w-nav">
        <a href="https://discord.gg/qTWxjfebR3" target="_blank" className="fixed bottom-32 lg:bottom-4 left-4 bg-white p-3 rounded-full border-2 border-[#5865F2]">
          <img className="h-10 w-10" src="./assets/discord-icon.svg" />
        </a>
        <div className="side-padders relative">
          <div className="container-135-width">
            <div className="nav-main-flex">
              <Link to="/" className="navbar__logo2">
                <img
                  className="image-100"
                  src="./assets/event-logo.svg"
                  alt=""
                />
              </Link>

              <nav className="nav-menu-6">
                <div className="nav-link-flex">
                  <div className="nav-links-wrap">
                    <NavLink className="nav-linking" to="/">
                      Home
                    </NavLink>
                    <NavLink className="nav-linking" to="/mentorlist">
                      Mentors
                    </NavLink>
                    <a
                      className="nav-linking"
                      href="https://roadmap.sh/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Roadmap
                    </a>
                    {/* <NavLink className="nav-linking" to="/feed">Feeds</NavLink> */}
                    <NavLink className="nav-linking" to="/events">
                      Events
                    </NavLink>
                    <NavLink className="nav-linking" to="/askmentors">
                      Ask Mentors
                    </NavLink>
                    <NavLink className="nav-linking-ai" to="https://oqlous.inqbator.in">
                      <div>
                        <img src="./assets/ai-fetching.gif" alt="" />
                      </div>
                      <p>
                      Oqlous AGI<span></span>
                      </p>
                    </NavLink>
                  </div>
                </div>
              </nav>

              {!accessToken ? (
                <Link
                  to="/login
                            "
                >
                  <h1 className="nav-button-wrap">Signup / Signin</h1>
                  <h1 className="nav-button-wrap-mobile">Sign In</h1>
                </Link>
              ) : (
                <>
                  <div className="navbar2__avatar">
                    <Tooltip title="Account settings">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        {user && (
                          <Avatar
                            src={user.picture}
                            sx={{ width: "3.1rem", height: "3.1rem" }}
                          ></Avatar>
                        )}
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          border: "1px solid gray",
                          mt: 1.5,
                          bgcolor: "black",
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            borderTop: "1px solid gray",
                            borderLeft: "1px solid gray",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "black",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      sx={{ padding: "0px" }}
                    >
                      <MenuItem onClick={handleClose} sx={{ padding: "0px" }}>
                        <div className="nav__menu-profile-main">
                          <Link to="/mentordashboard">
                            <div className="nav__menu-profile nav__menu-profile-border-btm">
                              <AccountCircleIcon sx={{ fontSize: "1.8rem" }} />
                              <p>Profile</p>
                            </div>
                          </Link>
                          <div
                            className="nav__menu-profile"
                            onClick={() => {
                              handleClose();
                              logout();
                            }}
                          >
                            <Logout sx={{ fontSize: "1.8rem" }} />
                            <p>Logout</p>
                          </div>
                        </div>
                      </MenuItem>
                    </Menu>
                    {user && <h3>{user.name}</h3>}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="nav-mob__main">
        <BottomNavbar />
      </div>
    </>
  );
};

export default Navbar2;
