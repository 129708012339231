import React, { createContext, useContext, useEffect, useState } from "react";
import API_URL from "../api/Api";
import axios from "axios";


const QuestionContext = createContext();

const QuestionProvider = ({ children }) => {
    const [filter, setFilter] = useState('All');
    const [comFilter, setComFilter] = useState('most_recent');
    const [openSendQue, setOpenSendQue] = useState(false)

    // Set Token
    const [accessToken, setAccessToken] = useState(null);
    useEffect(() => {
        const userToken = JSON.parse(localStorage.getItem("token"));
        if (userToken) {
            setAccessToken(userToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);

    // Get all fetchCategories
    const [categoryLoading, setCategoryLoading] = useState(true);
    const [category, setCategory] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/question/category`);
                setCategory(response.data.data);
                setCategoryLoading(false);
            } catch (error) {
                console.error('Error fetching categories:', error);
                setCategoryLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const [chooseCategory, setChooseCategory] = useState([]);
    const [question, setQuestion] = useState('');
    const handleAskQuestion = async () => {
        try {
            if (!question || (!chooseCategory.length === 0)) {
                return alert("Fill all fields")
            }
            const idsOnly = chooseCategory.map(item => item._id);
            const response = await axios.post(
                `${API_URL}/api/question`,
                {
                    question,
                    to: "64d4d9258a33bdd015bff5f7",
                    category: idsOnly,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            alert(response.data.message);
        } catch (error) {
            console.error('Error:', error.response.data);
        }
    };

    // Get all fetchCategories
    const [quesLoading, setQuesLoading] = useState(true);
    const [getQuestions, setGetQuestions] = useState([]);
    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                setQuesLoading(true);
                let response;
                setGetQuestions([])
                if (filter === "All") {
                    response = await axios.get(`${API_URL}/api/question?term=${comFilter}&page=${'1'}`);
                } else {
                    response = await axios.get(`${API_URL}/api/question?term=${comFilter}&page=${'1'}&category_ids=${filter}`, {
                        headers: {
                            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                        }
                    });
                }
            
                setGetQuestions(response.data.data);
                setQuesLoading(false);
            } catch (error) {
                console.error('Error fetching categories:', error);
                setQuesLoading(false);
            }
        };

        fetchQuestions();
    }, [filter, openSendQue, comFilter]);


    return (
        <QuestionContext.Provider
            value={{
                categoryLoading,
                setCategoryLoading,
                category,
                setCategory,
                getQuestions,
                setGetQuestions,
                filter,
                setFilter,
                question,
                quesLoading,
                setQuesLoading,
                setQuestion,
                chooseCategory,
                setChooseCategory,
                handleAskQuestion,
                comFilter,
                setComFilter,
                openSendQue,
                setOpenSendQue,
                accessToken
            }}
        >
            {children}
        </QuestionContext.Provider>
    );
};

export const QuestionState = () => {
    return useContext(QuestionContext);
};

export default QuestionProvider;




