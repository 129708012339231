import React, { useState, useEffect } from "react";
import axios from "axios";
import "./BookCall.css";
import API_URL from "../../api/Api";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, CircularProgress } from "@mui/material";
import toast from "react-hot-toast";

const BookCall = ({ onClose }) => {
  const navigate = useNavigate();
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [bookcallLoading, setBookcallLoading] = useState(false);
  const interestList = [
    "Career Mentorship",
    "Startup Mentorship",
    "Job Referals",
    "Resume Review",
    "Interview Preparation",
    "Networking",
    "Industry Insights",
    "Career Transition",
  ];

  const handleChipClick = (option) => {
    if (selectedAreas.includes(option)) {
      // If the option is already selected, deselect it
      setSelectedAreas((prevSelected) =>
        prevSelected.filter((item) => item !== option)
      );
    } else {
      // If the option is not selected, select it
      setSelectedAreas((prevSelected) => [...prevSelected, option]);
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    phoneNo: "",
    interestDomain: [],
    description: "",
  });

  formData.interestDomain = selectedAreas;

  const handleInputChange = (event, fieldName) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^(\+?\d{1,4}\s?)?(\d{10})$/;

    return phoneNumberRegex.test(phoneNumber);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // console.log(formData);
      if (!formData.name) {
        toast.error('Name is required!')
        return;
      }
      if (!formData.phoneNo) {
        toast.error('Phone number is required!')
        return;
      }
      const phoneNumber = isValidPhoneNumber(formData.phoneNo);
      if (!phoneNumber) {
        toast.error('Enter Valid Phone No.')
        return;
      }
      if (formData.interestDomain.length === 0) {
        toast.error('Domain is required!')
        return;
      }
      setBookcallLoading(true)
      const response = await axios.post(
        `${API_URL}/api/bookCall/createBooking`,
        formData
      );
      if (response.data.ok) {
        toast.success('We will call you ASAP')
        setBookcallLoading(false)
        onClose();
      } else {
        toast.error('Error')
        setBookcallLoading(false)
        onClose();
      }

      console.log("Data submitted:", response.data);

    } catch (error) {
      toast.error('Error submitting data')
      console.error("Error submitting data:", error);
      setBookcallLoading(false)
      onClose()
    }
  };



  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={bookcallLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className='BookCall__container-main'>
        <div className="BookCall_closeIcon" onClick={() => { onClose() }}>
          <CloseIcon fontSize='4rem' color='white' />
        </div>
        <div className='BookCall__container'>
          <div className="BookCall__main">

            <h1>Ready to meet your mentor? Let's get started !
              <span onClick={() => { onClose() }} >
                <CloseIcon fontSize='4rem' color='white' />
              </span>
            </h1>

            <form className="formBookCall">
              <label className="BookCall_label">
                Name:
              </label>
              <input
                className='BookCall_label-input'
                placeholder="Enter your name "
                value={formData.name}
                // onChange={handleInputChange}
                onChange={(e) => handleInputChange(e, 'name')}

              />
              <br />
              <label className="BookCall_label">
                Mobile Number:
              </label>
              <input
                className='BookCall_label-input'
                placeholder="Enter your mobile no. with country code"
                value={formData.phoneNo}
                // onChange={handleInputChange}
                onChange={(e) => handleInputChange(e, 'phoneNo')}
              />
              <br />
              <label className="BookCall_label">
                Select your domain of interest:
                <div className="interestDomain__chip-container">
                  {interestList.map((area) => (
                    <div
                      key={area}
                      className={`interestDomain__chip ${selectedAreas.includes(area) ? "selected" : ""}`}
                      onClick={() => handleChipClick(area)}
                    >
                      {area}
                    </div>
                  ))}
                </div>
              </label>
              <br />
              <label className="BookCall_label">
                Describe your requirements  & Services your need ?
              </label>
              <textarea
                className='BookCall_label-textarea'
                placeholder="Can you offer guidance on finding a career mentor who can assist with refining professional strategies, navigating challenges, and providing insights into advancing your career and achieving your goals?"
                value={formData.description}
                // onChange={handleInputChange}
                onChange={(e) => handleInputChange(e, 'description')}
              ></textarea>
              <br />
              <div className="BookCall_submit bg-white" onClick={handleSubmit}>
                <p>Get a call</p>
                <img src="./assets/bg-rightArrow.svg" alt="" />
              </div>
            </form>
          </div>
        </div >
      </div >
    </>

  );
};

export default BookCall;
