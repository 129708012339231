import React, { useState, useEffect } from 'react';
import './EditSkills.css';
import axios from 'axios';
import API_URL from '../../../api/Api';
import CloseIcon from '@mui/icons-material/Close';
import toast from 'react-hot-toast';

const EditSkills = ({ skills, setSkills, onClose }) => {
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // Effect to populate the selected skills when the modal opens
  useEffect(() => {
    setSelectedAreas([...skills]);
  }, [skills]);

  const areaList = [
    'JavaScript', 'Python', 'Java', 'C++', 'HTML', 'CSS', 'React', 'Angular', 'Android', 'iOS (Swift)', 'SQL', 'Excel', 'TensorFlow', 'PyTorch', 'AWS', 'Azure', 'Google Cloud Platform', 'Docker', 'Kubernetes', 'Jenkins', '(UX) Design', 'SEO', 'Project Management', 'Cybersecurity', 'Blockchain Development', 'Data Science'
  ];

  const handleChipClick = (area) => {
    if (selectedAreas.length < 50 && !selectedAreas.includes(area)) {
      setSelectedAreas((prevSelected) => [...prevSelected, area]);
    } else if (selectedAreas.length >= 50) {
      toast.error('You can select up to 50 items.');
    }
  };

  const handleChipRemove = (area) => {
    setSelectedAreas((prevSelected) => prevSelected.filter((c) => c !== area));
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const area = inputValue.trim();
      if (selectedAreas.length < 50 && !selectedAreas.includes(area)) {
        setSelectedAreas((prevSelected) => [...prevSelected, area]);
        setInputValue('');
      } else if (selectedAreas.length >= 50) {
        toast.error('You can select up to 50 items.');
      }
    }
  };

  const handleSave = async () => {
    try {
      const response = await axios.patch(`${API_URL}/api/mentor/skills/edit`, { skills: selectedAreas },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
          }
        })
      toast.success('Skills updated successFully');
    } catch (error) {
      console.log(error)
      toast.error('Error updating skills');
    }
    // Save the selected skills to the parent component using setSkills
    // setSkills(selectedAreas);
    onClose(); // Close the modal or take any other appropriate actions
  };

  return (
    <>
      <div className='editAbout__container'>
        <div className="editAbout__main">
          <h1>Edit Skills:
            <span onClick={() => { onClose() }} >
              <CloseIcon fontSize='4rem' color='white' />
            </span>
          </h1>
          <div>
            <div className='personalStep2__form'>
              <h1>Add new skills</h1>

              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleInputKeyPress}
                placeholder="Type and press Enter to add skill"
              />

              <div className="personalStep2__chip-container">
                {selectedAreas.length > 0 && <h2>Selected skills :</h2>}

                {selectedAreas.map((area) => (
                  <>
                    <div key={area} className="personalStep2__chip">
                      {area}
                      <span className="personalStep2__close-icon" onClick={() => handleChipRemove(area)}>
                        &times;
                      </span>
                    </div>
                  </>
                ))}
              </div>
              <div className="personalStep2__area-list">
                <h2>Some top skills : </h2>
                {areaList.map((area) => (
                  <div
                    key={area}
                    className={`personalStep2__chip ${selectedAreas.includes(area) ? 'disabled' : ''}`}
                    onClick={() => handleChipClick(area)}
                  >
                    {area}
                  </div>
                ))}
              </div>
              <div className='editAbout__save'>
                <div onClick={handleSave} >Save</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSkills;
