import { Footer2, EventsCard, ScrollTop, Footer } from "../../components/index";
import "./css/Profile.css";
import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import API_URL from "../../api/Api";
import Navbar2 from "../../components/Navbar2";
import ProfileIntro from "./ProfileIntro";
import ProfileDes from "./ProfileDes";
import ProfileAbout from "./ProfileAbout";
import ProfileSays from "./ProfileSays";
import ProfileRoadmap from "./ProfileRoadmap";
import ProfileFAQ from "./ProfileFAQ";
import ProfileNetwork from "./ProfileNetwork";
import { Skeleton } from "@mui/material";
import { DataState } from '../../context/DataProvider';

const Profile = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  var userId = searchParams.get("userId");

  const [mentorData, setMentorData] = useState(null);

  const fetchMentorData = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/mentor/${userId}`);
      setMentorData(response.data.mentorData[0]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchMentorData();
    }
  }, [userId]);

  const { accessToken } = DataState();
  const [checkFreeSessions, setCheckFreeSessions] = useState();
  const [checkFreeSessionsLoading, setCheckFreeSessionsLoading] = useState();

  const checkFreeSessionFetch = async () => {
    try {
      setCheckFreeSessionsLoading(true)
      const response = await axios.get(
        // `${API_URL}/api/paidMentorMentees?mentorId=${mentorData?._id}`
        `${API_URL}/api/checkTrial`
        ,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setCheckFreeSessions(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setCheckFreeSessionsLoading(false)
    }
  }

  useEffect(() => {
    if (mentorData?._id) {
      checkFreeSessionFetch()
    }
  }, [mentorData]);

  return (
    <>
      <Navbar2 />
      <div className="profile__container">
        {!mentorData ? (
          <div className="profile__loading-main">
            <div className='profile__loading-wrapper'>
              <div className="profile__loading-body">
                <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'100%'} />
                <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'100%'} />
                <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'100%'} />
                <div>
                  <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'100%'} />
                  <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'100%'} />
                  <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'100%'} />
                </div>
              </div>
            </div>
          </div>

        ) : (
          <div className="profile">

            {/* Main Profile */}
            <ProfileIntro mentorData={mentorData} checkFreeSessions={checkFreeSessions} />

            {/* Desription */}
            {mentorData?.about.length != 0 &&
              <ProfileDes mentorData={mentorData} />
            }

            {/* Experience and Education and all */}
            <ProfileAbout mentorData={mentorData} checkFreeSessions={checkFreeSessions} />

            {/* What says */}
            <ProfileSays mentorData={mentorData} checkFreeSessions={checkFreeSessions} />

            {/* Roadmap Image */}
            <ProfileRoadmap mentorData={mentorData} checkFreeSessions={checkFreeSessions} />

            {/* Network */}
            <ProfileNetwork mentorData={mentorData} checkFreeSessions={checkFreeSessions} />

            {/* FAQ */}
            <ProfileFAQ mentorData={mentorData} />

          </div>
        )}
      </div>
      <Footer />
      <ScrollTop />
    </>
  );
};

export default Profile;
