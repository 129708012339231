import React, { useState } from 'react';
import './DeleteChatModal.css';
import CloseIcon from '@mui/icons-material/Close';
import API_URL from '../../api/Api';


const DeleteChatModal = ({ handleDeleteChat, onClose, title, btn, type }) => {

  return (
    <>
      <div className='deleteChatModal__container'>
        <div className="deleteChatModal__main">
          <h1>{title}:
            <span onClick={() => { onClose() }} >
              <CloseIcon fontSize='4rem' color='white' />
            </span>
          </h1>

          <div className={`deleteChatModal__save ${type === "chat" ? 'deleteChatModal__chatBg' : 'deleteChatModal__mentorBg'}`}>
            <div onClick={onClose}>
              Cancel
            </div>
            <div onClick={() => {
              handleDeleteChat()
              onClose()
            }}>
              {btn}
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default DeleteChatModal