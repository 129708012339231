import React from 'react'
import Navbar2 from '../../components/Navbar2'

const Contact = () => {
  return (
    <div>
      <Navbar2/>
      contact
    </div>
  )
}

export default Contact