import React, { useEffect, useState } from "react";
import "./AskMentors.css";
import Navbar3 from "../../components/Navbar/Navbar3";
import { Footer, Footer2, QuestionCard, ScrollTop } from "../../components";
import SendQuestions from "./SendQuestions";
import { QuestionState } from "../../context/QuestionProvider";
import Navbar2 from "../../components/Navbar2";
import { Modal, Skeleton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const AskMentors = () => {
  const {
    categoryLoading,
    category,
    filter,
    setFilter,
    getQuestions,
    comFilter,
    setComFilter,
    openSendQue,
    setOpenSendQue,
    quesLoading,
  } = QuestionState();
  console.log(getQuestions);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setFilteredCategories(category);
  }, [category]);

  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();
    setSearchText(searchText);

    // Filter categories based on search text
    const filtered = category.filter((category) =>
      category.name.toLowerCase().includes(searchText)
    );

    setFilteredCategories(filtered); // Show up to 10 results
  };

  const clearInput = () => {
    setSearchText("");
    setFilteredCategories(category);
  };

  return (
    <>
      <Navbar2 />
      <div className="askmentors__container-main">
        <div className="askmentors__container">
          {/* header */}
          <div className="askmentors__header">
            <div className="askmentors__heading">
              <h1>Guiding Visionaries to Success</h1>
              <p>
                Expert Answers, Industry Insights: Your Source for Professional
                Knowledge
              </p>
            </div>
            <div className="askmentors__head-card">
              <div className="askmentors__head-card-text">
                <p>
                  Facing obstacles on your professional journey ? Ask Industry
                  Professionals and Elevate Your career's Journey to the Stars
                </p>
                <div onClick={() => setOpenSendQue(true)}>
                  <img src="./assets/asm-que.svg" alt="" />
                  <span>Ask your Doubt?</span>
                </div>
                <Modal open={openSendQue} onClose={() => setOpenSendQue(false)}>
                  <SendQuestions
                    key={SendQuestions}
                    onClose={() => setOpenSendQue(false)}
                  />
                </Modal>
              </div>
              <div className="askmentors__head-card-img">
                <img src="./assets/askm-bg.svg" alt="" />
              </div>
            </div>
          </div>

          {/* Main content */}
          <div className="askmentors__main">
            <div className="askmentors__main-com">
              <div className="askmentors__filter">
                <div
                  onClick={() => setComFilter("most_recent")}
                  className={`askmentors__filter-items ${
                    comFilter === "most_recent" ? "selected" : ""
                  }`}
                >
                  Most Recent
                </div>
                <div
                  onClick={() => setComFilter("most_ans")}
                  className={`askmentors__filter-items ${
                    comFilter === "most_ans" ? "selected" : ""
                  }`}
                >
                  Most Answered
                </div>
                <div
                  onClick={() => setComFilter("most_liked")}
                  className={`askmentors__filter-items ${
                    comFilter === "most_liked" ? "selected" : ""
                  }`}
                >
                  Most Likes
                </div>
                <div
                  onClick={() => setComFilter("un_ans")}
                  className={`askmentors__filter-items ${
                    comFilter === "un_ans" ? "selected" : ""
                  }`}
                >
                  Un Answered
                </div>
              </div>

              <div className="askmentors__filter-mobile">
                <div>
                  <li></li>
                  <span>Sort by: </span>
                </div>
                <select onChange={(e) => setComFilter(e.target.value)}>
                  <option
                    value="most_recent"
                    selected={comFilter === "most_recent"}
                  >
                    Most Recent
                  </option>
                  <option value="most_ans" selected={comFilter === "most_ans"}>
                    Most Answered
                  </option>
                  <option
                    value="most_liked"
                    selected={comFilter === "most_liked"}
                  >
                    Most Likes
                  </option>
                  <option value="un_ans" selected={comFilter === "un_ans"}>
                    Un Answered
                  </option>
                </select>
              </div>
              <div className="askmentors__main-com-container">
                {quesLoading ? (
                  <>
                    <div style={{display: 'flex', flexFlow: 'column nowrap', gap: '2rem'}}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ bgcolor: "grey.900", borderRadius: "1rem" }}
                        width={"100%"}
                        height={"25rem"}
                      />
                      <Skeleton
                        variant="rectangular"
                        sx={{ bgcolor: "grey.900", borderRadius: "1rem" }}
                        width={"100%"}
                        height={"25rem"}
                      />
                      <Skeleton
                        variant="rectangular"
                        sx={{ bgcolor: "grey.900", borderRadius: "1rem" }}
                        width={"100%"}
                        height={"25rem"}
                      />
                    </div>
                  </>
                ) : (
                  getQuestions?.map((ques) => (
                    <QuestionCard key={ques._id} ques={ques} />
                  ))
                )}
              </div>
            </div>
            <div className="askmentors__main-search">
              <div className="askmentors__sidebar">
                <div className="askmentors__sidebar-input">
                  <img src="./assets/search.svg" alt="" />
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchText}
                    onChange={handleSearch}
                  />
                  {searchText && <span onClick={clearInput}>&#x2715;</span>}
                </div>
                <div className="askmentors__sidebar-filter">
                  <h1>Filter Questions by Domain</h1>

                  {categoryLoading ? (
                    <p>Loading...</p>
                  ) : (
                    <div className="askmentors__sidebar-filter-scroll">
                      {searchText === "all" && (
                        <div
                          onClick={() => setFilter("All")}
                          className={`askmentors__sidebar-filter-scroll__option ${
                            filter === "All" ? "selected" : ""
                          }`}
                        >
                          All
                        </div>
                      )}
                      {!searchText && (
                        <div
                          onClick={() => setFilter("All")}
                          className={`askmentors__sidebar-filter-scroll__option ${
                            filter === "All" ? "selected" : ""
                          }`}
                        >
                          All
                        </div>
                      )}

                      {filteredCategories.map((category) => (
                        <div
                          key={category._id}
                          className={`askmentors__sidebar-filter-scroll__option ${
                            filter === category._id ? "selected" : ""
                          }`}
                          onClick={() => setFilter(category._id)}
                        >
                          <img src={category.image} alt={category.name} />
                          {category.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollTop />
    </>
  );
};

export default AskMentors;
