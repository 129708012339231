// import React, { useState } from "react";
// import axios from "axios";
// import "./css/DragDrop.css";

// import API_URL from "../../api/Api";
// const DragDrop = ({ uploadedFile, setUploadedFile }) => {
//   const [loading, setLoading] = useState(false);
//   //   const [uploadedFile, setUploadedFile] = useState(null);
//   const [errorMessage, setErrorMessage] = useState("");

//   const handleFileSelect = (event) => {
//     const file = event.target.files[0];
//     handleFile(file);
//   };

//   const handleFileDrop = (event) => {
//     event.preventDefault();
//     event.stopPropagation();
//     const file = event.dataTransfer.files[0];
//     handleFile(file);
//   };

//   const handleFile = (file) => {
//     setLoading(true);
//     setErrorMessage("");

//     const validFileTypes = [
//       "application/pdf",
//       "application/msword",
//       "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//     ];

//     if (file) {
//       if (validFileTypes.includes(file.type)) {
//         if (file.size <= 50 * 1024 * 1024) {
//           const formData = new FormData();
//           formData.append("file", file);

//           axios
//             .post(`${API_URL}/api/upload`, formData) // Replace with your backend API endpoint
//             .then((response) => {
//               setLoading(false);
//               setUploadedFile(response.data.filePath);

//               // Handle the response from the backend, if needed
//               console.log("File uploaded successfully:", response.data);
//             })
//             .catch((error) => {
//               setLoading(false);
//               setErrorMessage("Error uploading file.");

//               // Handle the error, e.g., show an error message
//               console.error("Error uploading file:", error);
//             });
//         } else {
//           setLoading(false);
//           setErrorMessage("File size must be 50MB or smaller.");
//         }
//       } else {
//         setLoading(false);
//         setErrorMessage("Only PDF and DOC files are allowed.");
//       }
//     } else {
//       setLoading(false);
//       setUploadedFile(null);
//       setErrorMessage("No file selected.");
//     }
//   };

//   const handleDragOver = (event) => {
//     event.preventDefault();
//     event.stopPropagation();
//   };

//   const handleRemoveFile = () => {
//     setUploadedFile(null);
//     setErrorMessage("");
//   };

//   const formatFileSize = (size) => {
//     if (size < 1024) {
//       return size + " B";
//     } else if (size < 1024 * 1024) {
//       return (size / 1024).toFixed(2) + " KB";
//     } else {
//       return (size / (1024 * 1024)).toFixed(2) + " MB";
//     }
//   };

//   return (
//     <div>
//       <form className="file-upload-container" encType="multipart/form-data">
//         {!uploadedFile && (
//           <label
//             className={`file-dropzone ${loading ? "loading" : ""} ${
//               errorMessage ? "error" : ""
//             }`}
//             onDrop={handleFileDrop}
//             onDragOver={handleDragOver}
//           >
//             <input
//               type="file"
//               accept=".pdf,.doc,.docx"
//               name="file"
//               onChange={handleFileSelect}
//             />
//             <i className="fa-solid fa-arrow-up-from-bracket" />
//             <h1>Drag Files to upload</h1>
//             <p>or</p>
//             <div>Browse files</div>
//             <p>
//               Max file size: <span>50MB</span>{" "}
//             </p>
//             <p>
//               Supported file types: <span>PDF / DOC</span>
//             </p>
//           </label>
//         )}

//         {uploadedFile && (
//           <label
//             className={`file-dropzone ${loading ? "loading" : ""} ${
//               errorMessage ? "error" : ""
//             }`}
//             onDrop={handleFileDrop}
//             onDragOver={handleDragOver}
//           >
//             <input
//               type="file"
//               accept=".pdf,.doc,.docx"
//               name="file"
//               onChange={handleFileSelect}
//             />

//             <main className="dragddrop__uploaded">
//               <h1>
//                 Uploaded <span> {uploadedFile.name}</span>
//               </h1>
//               <p>File Size: {formatFileSize(uploadedFile.size)}</p>
//               <div>
//                 <i className="fa-solid fa-arrow-up-from-bracket" />
//                 Upload New File
//               </div>
//               <button onClick={handleRemoveFile}>Remove File</button>
//             </main>
//           </label>
//         )}

//         <div className="dragdrop__message">
//           {loading && <div className="loading-indicator">Loading...</div>}
//           {errorMessage && <div className="error-message">{errorMessage}</div>}
//         </div>
//       </form>
//     </div>
//   );
// };

// export default DragDrop;

import React, { useState } from 'react';
import './css/DragDrop.css';

const DragDrop = () => {
    const [loading, setLoading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        handleFile(file);
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const file = event.dataTransfer.files[0];
        handleFile(file);
    };

    const handleFile = (file) => {
        setLoading(true);
        setErrorMessage('');

        const validFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        if (file) {
            if (validFileTypes.includes(file.type)) {
                if (file.size <= 50 * 1024 * 1024) { // 50 MB limit
                    const reader = new FileReader();
                    reader.onload = () => {
                        setLoading(false);
                        setUploadedFile(file);
                    };
                    reader.readAsDataURL(file);
                } else {
                    setLoading(false);
                    setErrorMessage('File size must be 50MB or smaller.');
                }
            } else {
                setLoading(false);
                setErrorMessage('Only PDF and DOC files are allowed.');
            }
        } else {
            setLoading(false);
            setUploadedFile(null);
            setErrorMessage('No file selected.');
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleRemoveFile = () => {
        setUploadedFile(null);
        setErrorMessage('');
    };

    const formatFileSize = (size) => {
        if (size < 1024) {
            return size + ' B';
        } else if (size < 1024 * 1024) {
            return (size / 1024).toFixed(2) + ' KB';
        } else {
            return (size / (1024 * 1024)).toFixed(2) + ' MB';
        }
    };

    return (
        <div>
            <div className="file-upload-container">
                {!uploadedFile && (
                    <label
                        className={`file-dropzone ${loading ? 'loading' : ''} ${errorMessage ? 'error' : ''}`}
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                    >
                        <input type="file" accept=".pdf,.doc,.docx" onChange={handleFileSelect} />
                        <i className="fa-solid fa-arrow-up-from-bracket" />
                        <h1>Drag Files to upload</h1>
                        <p>or</p>
                        <div>Browse files</div>
                        <p>Max file size: <span>50MB</span> </p>
                        <p>Supported file types: <span>PDF / DOC</span></p>
                    </label>
                )}

                {uploadedFile && (
                    <label
                        className={`file-dropzone ${loading ? 'loading' : ''} ${errorMessage ? 'error' : ''}`}
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                    >
                        <input type="file" accept=".pdf,.doc,.docx" onChange={handleFileSelect} />

                        <main className='dragddrop__uploaded'>
                            <h1>Uploaded <span> {uploadedFile.name}</span></h1>
                            <p>File Size: {formatFileSize(uploadedFile.size)}</p>
                            <div><i className="fa-solid fa-arrow-up-from-bracket" />Upload New File</div>
                            <button onClick={handleRemoveFile}>Remove File</button>
                        </main>
                    </label>
                )}

                <div className='dragdrop__message'>
                    {loading && <div className="loading-indicator">Loading...</div>}
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                </div>
            </div>
        </div>
    );
};

export default DragDrop;
