import React from "react";
import Navbar2 from "../../components/Navbar2";
import ScrollTop from "../../components/miscellenius/ScrollTop";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar2 />
      <div className="bg-gray-900 text-white min-h-screen" style={{background:
        "linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent 130%), linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent 130%), linear-gradient(to bottom, #714FB4, #714FB4)"}}>
        <div className="container mx-auto py-8">
          <h1 className="text-5xl font-bold mb-4">
            Privacy Policy for inQbator Technologies LLP
          </h1>
          <p>Last Updated: 14th November</p>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">1. Introduction</h2>
            <p className="text-2xl mb-2">
              Welcome to inQbator Technologies LLP ("us", "we", or "our"). This
              Privacy Policy outlines how we collect, use, and protect
              information obtained from users of our website and services.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-2xl font-semibold mb-3">2. Information We Collect</h2>
            <p className="text-2xl mb-2">
              a. Personal Information: We may collect personal information such
              as names, email addresses, and contact details when users register
              on our website, subscribe to our newsletter, or fill out forms.
            </p>
            <p className="text-2xl mb-2">
              b. Usage Data: We may collect information about how users interact
              with our website, including IP addresses, browser type, pages
              visited, and the time and date of access.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-2xl font-semibold mb-3">
              3. How We Use Your Information
            </h2>
            <p className="text-2xl mb-2">
              We use the collected information for the following purposes:
            </p>
            <p className='text-2xl mb-2'>a. To provide and maintain our services.</p>
            <p className='text-2xl mb-2'>
              b. To personalize the user experience and deliver relevant
              content.
            </p>
            <p className='text-2xl mb-2'>
              c. To send periodic emails related to our services, promotions,
              and updates.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-2xl font-semibold mb-3">4. Data Security</h2>
            <p className='text-2xl mb-2'>
              a. We implement reasonable security measures to protect against
              unauthorized access, alteration, disclosure, or destruction of
              your personal information.
            </p>
            <p className='text-2xl mb-2'>
              b. Despite our best efforts, no method of transmission over the
              internet or electronic storage is 100% secure. Therefore, we
              cannot guarantee its absolute security.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-2xl font-semibold mb-3">
              5. Cookies and Tracking Technologies
            </h2>
            <p className='text-2xl mb-2'>
              a. We use cookies and similar tracking technologies to enhance
              user experience. Users can manage cookie preferences through their
              browser settings.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-2xl font-semibold mb-3">6. Third-Party Links</h2>
            <p className='text-2xl mb-2'>
              a. Our website may contain links to third-party websites. We are
              not responsible for the privacy practices or content of these
              third-party sites.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-2xl font-semibold mb-3">7. Your Choices</h2>
            <p className='text-2xl mb-2'>
              a. Users can choose to opt-out of receiving promotional emails by
              following the unsubscribe instructions provided in the email.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-2xl font-semibold mb-3">8. Children's Privacy</h2>
            <p className='text-2xl mb-2'>
              a. Our services are not intended for individuals under the age of
              13. We do not knowingly collect personal information from
              children. If you are a parent or guardian and believe your child
              has provided us with personal information, please contact us.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-2xl font-semibold mb-3">
              9. Changes to This Privacy Policy
            </h2>
            <p className='text-2xl mb-2'>
              a. We reserve the right to update or change our Privacy Policy at
              any time. Users are encouraged to check this page periodically for
              updates.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-2xl font-semibold mb-3">10. Contact Us</h2>
            <p className='text-2xl mb-2'>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at{" "}
              <a href="mailto:inqbatorindia@gmail.com" className="underline">
                inqbatorindia@gmail.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <ScrollTop/>
    </>
  );
};

export default PrivacyPolicy;
