import React, { useEffect, useState } from "react";
import { Dialog, IconButton, Slide } from "@mui/material";
import { Toaster } from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import { EventsCard } from "../../components";
import "./createEvent.css";
import { DataState } from "../../context/DataProvider";
import API_URL from "../../api/Api";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateEvent = ({ open, handleClose }) => {
  const { accessToken, user } = DataState();
  const [newEvent, setNewEvent] = useState({
    attendees: [],
    color: "#D56A8F",
    date: Date.now(),
    image: "",
    name: "",
    profession: "Mentor",
    tag: "Give a short description of the event here.",
    title: "Title of the event",
    meetLink: "",
  });

  useEffect(() => {
    // Update newEvent with user data when user changes
    setNewEvent((prevState) => ({
      ...prevState,
      image: user?.picture || "", // Set image to user.picture or empty string if user is null/undefined
      name: user?.name || "", // Set name to user.name or empty string if user is null/undefined
    }));
  }, [user]);

  const handleColorChange = (color) => {
    setNewEvent((prevState) => ({
      ...prevState,
      color: color,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setNewEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const createEvent = async () => {
    try {
      const eventData = {
        title: newEvent.title,
        tag: newEvent.tag,
        date: newEvent.date,
        color: newEvent.color,
        name: newEvent.name,
        meetLink: newEvent.meetLink,
      };

      const response = await axios.post(`${API_URL}/api/events`, eventData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Set Content-Type to application/json for JSON data
        },
      });

      console.log("Response:", response.data);
      handleClose();
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        style={{ zIndex: 10000 }}
      >
        <div className="bg-gray-900 h-screen w-screen flex items-center justify-center">
          <div className="cEvent_Wrapper">
            <div className="cEvent__main">
              <div className="cEvent__l">
                <EventsCard isCreateEvent={true} event={newEvent} />
              </div>
              <div className="cEvent__r">
                <label>
                  Title:
                  <input
                    type="text"
                    name="title"
                    maxLength={20}
                    value={newEvent.title}
                    onChange={handleChange}
                    className="cEvent_input"
                  />
                </label>
                <label>
                  Description:
                  <textarea
                    type="text"
                    name="tag"
                    maxLength={50}
                    value={newEvent.tag}
                    onChange={handleChange}
                    className="cEvent_textarea"
                  />
                </label>
                <label>
                  Date:
                  <div>
                    <input
                      type="datetime-local"
                      name="date"
                      value={newEvent.date}
                      onChange={handleDateChange}
                      className="cEvent_input"
                    />
                    <input
                      type="color"
                      name="color"
                      value={newEvent.color}
                      onChange={(e) => handleColorChange(e.target.value)}
                      className="cEvent_colorInput"
                    />
                  </div>
                </label>
                <label>
                  Discord Link:
                  <input
                    type="text"
                    name="meetLink"
                    value={newEvent.meetLink}
                    onChange={handleChange}
                    className="cEvent_input"
                  />
                </label>
                {/* Add other fields similarly */}
                <button
                  type="submit"
                  className="cEvent_Btn"
                  onClick={createEvent}
                >
                  Create Event
                </button>
              </div>
            </div>
          </div>
          <div className="dialog__cancel" onClick={handleClose}>
            <IconButton>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateEvent;
