import React from "react";
import "./css/StartupRegStep1.css";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import API_URL from "../../api/Api";
import { DataState } from "../../context/DataProvider";

const StartupRegStep1 = ({ onNext }) => {
  const { continueFormID, setContinueFormID } = DataState();
  const [formName, setFormName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [linkedin, setLinkedin] = React.useState("");
  const isValidPhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^(\+?\d{1,4}\s?)?(\d{10})$/;
    return phoneNumberRegex.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const isValidLinkedInProfile = (url) => {
    const linkedInRegex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
    return linkedInRegex.test(url);
  };

  const handleContinue = () => {
    const phoneNumber = isValidPhoneNumber(mobile);
    const checkEmail = isValidEmail(email);
    const linkedInProfile = isValidLinkedInProfile(linkedin);
    if (!phoneNumber) {
      alert("Enter Valid Phone No.");
      return;
    }
    if (!checkEmail) {
      alert("Enter Valid Email Id.");
      return;
    }
    if (!linkedInProfile) {
      alert("Enter Valid linkedin Profile link");
      return;
    }
    const formData = {
      personalDetails: {
        name: formName,
        mobile: mobile,
        email: email,
        linkedinID: linkedin,
      },
    };
    console.log(formData);
    console.log(API_URL);
    axios
      .post(`${API_URL}/api/freementorshipForm/fillForm`, formData)
      .then((response) => {
        console.log("Data saved successfully:", response);
        console.log(response.data.id);
        setContinueFormID(response.data.id);
        onNext(); // Proceed to the next step
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  return (
    <div className="personal__main">
      <div className="personal">
        <div className="personal__head">
          <h1>Join Wishlist</h1>
          <img src="./assets/logo.png" alt="" />
        </div>
        <p>
          inQbator is india’s fast growing startup founders community that works
          intensively with the startup in each phase of their journey.
        </p>
        <div className="personal__step">
          <div className="personal__step1">
            <p>1</p>
            <h1>Personal Details</h1>
          </div>
          <div className="personal__step2">
            <p>2</p>
            <h1>Startup Details</h1>
          </div>
        </div>
        <div className="personal__form">
          <h1>Name*</h1>
          <input
            type="text"
            required
            placeholder="Enter you name"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
          />
          <h1>Mobile*</h1>
          <div className='personal__phoneNo'>
            <PhoneInput
              className="PhoneInput"
              required
              defaultCountry="IN"
              placeholder="Enter Phone Number with Country code"
              value={mobile}
              onChange={setMobile}
            />
          </div>
          <h1>Email*</h1>
          <input
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter valid email address"
          />
          <h1>LinkedIn ID (Profile URL)*</h1>
          <input
            type="text"
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
            placeholder="Enter your LinkedIn Profile's link"
            required
          />
          <div onClick={handleContinue}>Continue</div>
        </div>
      </div>
    </div>
  );
};

export default StartupRegStep1;
