import React, { useState, useEffect } from "react";
import axios from "axios";
import "./BookCall.css";
import "./BookCallPage.css";
import API_URL from "../../api/Api";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, CircularProgress } from "@mui/material";

const BookCallPage = () => {
    const navigate = useNavigate();
    const [selectedAreas, setSelectedAreas] = useState([]);
    const [bookcallLoading, setBookcallLoading] = useState(false);
    const interestList = [
        "Startup Mentorship",
        "PreSeed Fund",
        "Seed Fund",
        "Founder Program",
        "Other services",
        "Incubation",
        "Marketing",
        "Networking",
    ];

    const handleChipClick = (option) => {
        if (selectedAreas.includes(option)) {
            // If the option is already selected, deselect it
            setSelectedAreas((prevSelected) =>
                prevSelected.filter((item) => item !== option)
            );
        } else {
            // If the option is not selected, select it
            setSelectedAreas((prevSelected) => [...prevSelected, option]);
        }
    };

    const [formData, setFormData] = useState({
        name: "",
        startupName: "",
        email: "",
        phoneNo: "",
        interestDomain: [],
        description: "",
    });

    formData.interestDomain = selectedAreas;

    const handleInputChange = (event, fieldName) => {
        const { value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    const isValidPhoneNumber = (phoneNumber) => {
        const phoneNumberRegex = /^(\+?\d{1,4}\s?)?(\d{10})$/;

        return phoneNumberRegex.test(phoneNumber);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // console.log(formData);
            if (!formData.name) {
                alert("Name is required!");
                return;
            }
            if (!formData.startupName) {
                alert("Startup name is required!");
                return;
            }
            if (!formData.email) {
                alert("E-mail is required!");
                return;
            }
            if (!formData.phoneNo) {
                alert("Phone number is required!");
                return;
            }
            if (formData.interestDomain.length === 0) {
                alert("Domain is required!");
                return;
            }
            const phoneNumber = isValidPhoneNumber(formData.phoneNo);
            if (!phoneNumber) {
                alert("Enter Valid Phone No.");
                return;
            }
            setBookcallLoading(true)
            const response = await axios.post(
                `${API_URL}/api/bookCall/createBooking`,
                formData
            );
            if (response.data.ok) {
                alert("We will call you ASAP");
                setBookcallLoading(false)
                setFormData({})
                navigate('/')
            } else {
                alert("Error");
                setBookcallLoading(false)
            }

            console.log("Data submitted:", response.data);

        } catch (error) {
            console.error("Error submitting data:", error);
            setBookcallLoading(false)
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={bookcallLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='BCP__wrapper-main'>
                <div className='BCP__wrapper'>
                    <div className="BCP__body">
                        <div className="h-[40] w-full flex justify-center"><img src="./logos/whiteMainLogo.svg" /></div>
                        <h1>Ready to meet your NEEDS? Let's StartUp!</h1>
                        <form className="formBookCall">
                            <label className="BookCall_label">
                                Name:
                            </label>
                            <input
                                className='BookCall_label-input'
                                placeholder="Enter your name "
                                value={formData.name}
                                onChange={(e) => handleInputChange(e, 'name')}

                            />
                            <br />
                            <label className="BookCall_label">
                                Name of your startup:
                            </label>
                            <input
                                className='BookCall_label-input'
                                placeholder="Enter your startup name "
                                value={formData.startupName}
                                onChange={(e) => handleInputChange(e, 'startupName')}

                            />
                            <br />
                            <label className="BookCall_label">
                                Email:
                            </label>
                            <input
                                className='BookCall_label-input'
                                placeholder="Enter your email "
                                value={formData.email}
                                onChange={(e) => handleInputChange(e, 'email')}

                            />
                            <br />
                            <label className="BookCall_label">
                                Mobile Number:
                            </label>
                            <input
                                className='BookCall_label-input'
                                placeholder="Enter your mobile no. with country code"
                                value={formData.phoneNo}
                                // onChange={handleInputChange}
                                onChange={(e) => handleInputChange(e, 'phoneNo')}

                            />
                            <br />
                            <label className="BookCall_label">
                                Select your domain of interest:
                                <div className="interestDomain__chip-container">
                                    {interestList.map((area) => (
                                        <div
                                            key={area}
                                            className={`interestDomain__chip ${selectedAreas.includes(area) ? "selected" : ""}`}
                                            onClick={() => handleChipClick(area)}
                                        >
                                            {area}
                                        </div>
                                    ))}
                                </div>
                            </label>
                            <br />
                            <label className="BookCall_label">
                                Describe your requirements  & Services your need ?
                            </label>
                            <textarea
                                className='BookCall_label-textarea'
                                placeholder="Can you offer guidance on finding a career mentor who can assist with refining professional strategies, navigating challenges, and providing insights into advancing your career and achieving your goals?"
                                value={formData.description}
                                // onChange={handleInputChange}
                                onChange={(e) => handleInputChange(e, 'description')}
                            ></textarea>
                            <br />
                            <div className="BookCall_submit bg-white" onClick={handleSubmit}>
                                <p>Get a call</p>
                                <img src="./assets/bg-rightArrow.svg" alt="" />
                            </div>
                        </form>
                    </div>
                </div >
            </div >

        </>
    )
}

export default BookCallPage