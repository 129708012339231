import React, { useState, useEffect } from "react";
import "./CalDetails.css";
import CalenderTest from "./CalenderTest";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import API_URL from "../../api/Api";
import { DataState } from "../../context/DataProvider";
import { Backdrop, CircularProgress } from "@mui/material";
import toast from "react-hot-toast";


// const CalDetails = ({ onNext, trailSessionId, userData }) => {
const CalDetails = ({ userData, sessionFormId }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search);
  var userId = searchParams.get('userId');

  const [selectedTime, setSelectedTime] = useState(null);
  const [sendLoading, setSendLoading] = useState(false);
  const { accessToken } = DataState();

  // const setMentor_Mentee_Relation = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${API_URL}/api/paidMentorMentees?mentorId=${userId}`, {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       }
  //     );
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error("Error fetching user data:", error);
  //   }
  // }

  const bookSlots = async () => {
    try {
      setSendLoading(true)
      // const response = await axios.post(`${API_URL}/api/bookMentorSlot/book?availableSlotId=${selectedTime}&freeTrial=${trailSessionId}&mentorUserId=${userId}&sessionType=free`
      const response = await axios.post(`${API_URL}/api/session/book?availableSlotId=${selectedTime}&mentorUserId=${userId}&sessionFormId=${sessionFormId}&sessionType=free`
      , {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      console.log(response.data)
      if (response.data.ok === true) {
        // setMentor_Mentee_Relation()
        setSendLoading(false)
        navigate(`/booksessions?id=${response.data.id}`)
      } else {
        setSendLoading(false)
        toast.error('Some Error occur')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={sendLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="cd__main">
        <div className="cd__body">
          <div className="bd__progressBar">
            <img src="./assets/progress bar 1.png" alt="" />
          </div>
          <h1 className="bd__heading-main">
            Schedule your session call with {userData?.name}
            <img className="imgTop" src="./assets/arrow.png" alt="" />
          </h1>
          <div className="cd__content">
            <div className='cd__cal'>
              <CalenderTest userId={userId} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
            </div>
            {/* <Link to={`/book-sessions/${userId}`}> */}
            <div className={`cd__submit ${selectedTime ? '' : 'disabled'}`}
              onClick={bookSlots}
            >
              <div>
                <p>Reserve slot</p>
                <img src="./assets/maki_arrow.png" alt="" />
              </div>
            </div>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CalDetails;
