import React, { createContext, useContext, useEffect, useState } from "react";
import API_URL from "../api/Api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import jwtDecode from 'jwt-decode';

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const navigation = useNavigate();

  // User States
  const [accessToken, setAccessToken] = useState(null);
  const [user, setUser] = useState(false);

  const [continueFormID, setContinueFormID] = useState(null);

  // AI States
  const [selectedChat, setSelectedChat] = React.useState(null);
  const [messages, setMessages] = useState([]);


  useEffect(() => {
    const userToken = JSON.parse(localStorage.getItem("token"));

    if (userToken) {
      const decodedToken = jwtDecode(userToken);
      const tokenExpiration = decodedToken.exp * 1000; // Convert expiration time to milliseconds
      const currentTimestamp = new Date().getTime();

      if (currentTimestamp > tokenExpiration) {
        // Token has expired, remove it from local storage
        localStorage.removeItem("token");
        setAccessToken(null); // Set access token in state to null or any other default value
      } else {
        setAccessToken(userToken);
      }
    }
  }, [accessToken]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/user-info`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        setUser(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUser(null);
      }
    };

    if (accessToken) {
      fetchUserData();
    } else {
      setUser(null);
    }
  }, [accessToken]);

  const logout = async () => {
    localStorage.removeItem("token");
    setUser(null);
    setAccessToken(null);
    navigation("/");
    window.location.reload();
  };
  // Fetching recent chats
  const [recentChats, setRecentChats] = useState([]);
  const fetchChats = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/chat`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const chats = response.data;

      setRecentChats(chats);
    } catch (error) {
      console.error(error);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <DataContext.Provider
      value={{
        accessToken,
        user,
        setUser,
        setAccessToken,
        logout,
        selectedChat,
        setSelectedChat,
        messages,
        setMessages,
        continueFormID,
        setContinueFormID,
        fetchChats,
        recentChats, 
        setRecentChats,
        searchTerm, 
        setSearchTerm
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const DataState = () => {
  return useContext(DataContext);
};

export default DataProvider;
