// GoogleAuth.js
import React, { useEffect } from "react";
import API_URL from "../../api/Api";
import { useNavigate } from "react-router-dom";

const GoogleAuth = ({ redirectUrl, selectedRole }) => {
  const navigate = useNavigate();
  console.log("in google auth", selectedRole)

  const handleGoogle = async (response) => {
    try {
      const storedRole = localStorage.getItem('selectedRole');
      console.log(storedRole);
      const res = await fetch(`${API_URL}/api/google-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          role: storedRole,
          idToken: response.credential
        }),
      });

      const data = await res.json();
      console.log(data)
      if (data?.token) {
        localStorage.setItem("token", JSON.stringify(data?.token));
        navigate(redirectUrl);
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (window?.google?.accounts?.id) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });

      const parent = document.getElementById("googlePop-up");
      window.google.accounts.id.renderButton(parent, {
        size: "large",
      });
    }
  }, []);

  return (
    <div>
      <div className="login__google-button">
        <div className="login__google-logo">
          <img src="./assets/google.svg" />
        </div>
        <div className="login__google-button-text">Continue with Google</div>
        <div id="googlePop-up"></div>
      </div>
    </div>
  );
};

export default GoogleAuth;
