import React, { useState } from 'react';
import './EditAbout.css';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import API_URL from '../../../api/Api';
import toast from 'react-hot-toast';

const EditAbout = ({ about, setAbout, setOpenEditAbout, onClose }) => {
    const [currentAbout, setCurrentAbout] = useState(about);

    const handleInputChange = (e) => {
        setCurrentAbout(e.target.value);
    };

    const handleSave = async () => {

        try {
            const response = await axios.patch(`${API_URL}/api/mentor/about/edit`, { about: currentAbout }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            })
            setOpenEditAbout(false);
            console.log(response.data.msg)
            toast.success('About data updated');
        } catch (error) {
            console.log(error)
            toast.error('Error updating data');
            setOpenEditAbout(false);
        }
    };


    return (
        <>
            <div className='editAbout__container'>
                <div className="editAbout__main">
                    <h1>About:
                        <span onClick={() => { onClose() }} >
                            <CloseIcon fontSize='4rem' color='white' />
                        </span>
                    </h1>
                    <div>
                        <div className='editAbout__formcol'>
                            <div>
                                <h1>You can write about your years of experience, industry, or skills. People also talk about their achievements or previous job experiences.</h1>
                                <textarea
                                    type="text"
                                    value={currentAbout} 
                                    onChange={handleInputChange} 
                                    placeholder="Give your brief introduction"
                                />
                            </div>
                        </div>
                        <div className='editAbout__save'>
                            <div onClick={handleSave}> 
                                Save
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditAbout;
