import React, { useState } from 'react';
import axios from 'axios';
import API_URL from '../api/Api';
import { useNavigate } from 'react-router-dom';

function Dummyuser() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [picture, setPicture] = useState('');
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${API_URL}/api/dummyUser/create`, { name, email, picture, role: 'mentor' });
            console.log('User created:', response.data);
            if (response?.data?.token) {
                localStorage.setItem("token", JSON.stringify(response?.data?.token));
                navigate('/');
                window.location.reload();
              }
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-white">Create Dummy User</h2>
                </div>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <input
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-700 placeholder-gray-500 text-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div>
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-700 placeholder-gray-500 text-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Picture"
                                value={picture}
                                onChange={(e) => setPicture(e.target.value)}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-700 placeholder-gray-500 text-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                {/* <!-- Heroicon name: lock-closed --> */}
                                <svg
                                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 2a8 8 0 0 0-8 8v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6a8 8 0 0 0-8-8zM7 10V8a1 1 0 0 1 2 0v2a1 1 0 0 1-2 0zM11 10V8a1 1 0 1 1 2 0v2a1 1 0 0 1-2 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </span>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Dummyuser;


// import React, { useState } from 'react';
// import axios from 'axios';

// const Dummyuser = () => {
//     const [query, setQuery] = useState('');
//     const [results, setResults] = useState([]);

//     const handleSearch = async () => {
//         try {
//             const response = await axios.get(`http://localhost:5000/api/oqlous?q=${query}`);
//             setResults(response.data);
//         } catch (error) {
//             console.error('Error fetching search results:', error);
//         }
//     };

//     return (
//         <div className="container mx-auto p-4">
//             <div className="mb-4">
//                 <input
//                     type="text"
//                     value={query}
//                     onChange={(e) => setQuery(e.target.value)}
//                     placeholder="Search..."
//                     className="w-full p-2 border border-gray-300 rounded"
//                 />
//                 <button 
//                     onClick={handleSearch} 
//                     className="mt-2 p-2 bg-blue-500 text-white rounded"
//                 >
//                     Search
//                 </button>
//             </div>
//             <ul className="space-y-4">
//                 {results.map((result, index) => (
//                     <li key={index} className="p-4 border border-gray-300 rounded shadow">
//                         {result.pagemap?.metatags && result.pagemap.metatags[0]['og:image'] && (
//                             <img 
//                                 src={result.pagemap.metatags[0]['og:image']} 
//                                 alt={result.title} 
//                                 className="w-52 h-auto mb-4 rounded"
//                             />
//                         )}
//                         <a 
//                             href={result.link} 
//                             target="_blank" 
//                             rel="noopener noreferrer"
//                             className="text-xl font-bold text-blue-600 hover:underline"
//                         >
//                             {result.title}
//                         </a>
//                         <p className="text-gray-700">{result.snippet}</p>
//                     </li>
//                 ))}
//             </ul>
//         </div>
//     );
// };

// export default Dummyuser;
