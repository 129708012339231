import * as React from 'react';
import './AddEducation.css';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import API_URL from '../../../api/Api';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';

const AddEducation = ({ onClose }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [school, setSchool] = useState('');
    const [degree, setDegree] = useState('');
    const [field, setField] = useState('');
    const [startYear, setStartYear] = useState('');
    const [endYear, setEndYear] = useState('');
    const [sendLoading, setSendLoading] = useState(false);


    const imageRef = React.useRef(null);

    function useDisplayImage() {
        const [result, setResult] = React.useState(false);

        function uploader(e) {
            const imageFile = e.target.files[0];

            const reader = new FileReader();
            reader.addEventListener('load', (e) => {
                setResult(e.target.result);
            });

            reader.readAsDataURL(imageFile);
        }

        return { result, uploader };
    }

    const { result, uploader } = useDisplayImage();


    const handleSave = async () => {
        if (!school || !degree || !field || !startYear || !endYear) {
            toast.error('Please fill all required fields.');
            return;
        }
        try {
            setSendLoading(true)
            const response = await axios.post(`${API_URL}/api/mentor/education/add`, {
                schoolImage: result,
                school,
                degree,
                fieldOfStudy: field,
                startYear,
                endYear
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            })
            console.log(response.data);
            onClose();
            setSendLoading(false)
            toast.success('Education added successfully');
        } catch (error) {
            console.log(error)
            setSendLoading(false)
        }
    };

    return (
        <>
             <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={sendLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='editAbout__container'>
                <div className="editAbout__main">
                    <h1>Add Education:
                        <span onClick={() => { onClose() }} >
                            <CloseIcon fontSize='4rem' color='white' />
                        </span>
                    </h1>
                    <div>
                        <div className='editAbout__formcol'>
                            <div>
                                <h1>School*</h1>
                                <input
                                    type="text"
                                    placeholder='Enter your school/college/university name'
                                    value={school}
                                    onChange={(e) => setSchool(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='editAbout__formcol'>
                            <div className='editAbout__form-margin'>
                                <h1>Degree*</h1>
                                <input
                                    type="text"
                                    placeholder='Enter Degree'
                                    value={degree}
                                    onChange={(e) => setDegree(e.target.value)}
                                />
                            </div>
                            <div>
                                <h1>School Image*</h1>
                                <div className='editIntro__upload'>
                                    <div className='editIntro__upload-img'>
                                        {!result ? 'Choose Image' : 'Change'}
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => uploader(e)}
                                        />
                                    </div>

                                    {result && (
                                        <div className='editIntro__upload-viewimg'>
                                            <img ref={imageRef} src={result} alt="Uploaded" />
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>

                        <div className='editAbout__formcol'>
                            <div>
                                <h1>Field of study*</h1>
                                <input
                                    type="text"
                                    placeholder='Field of study'
                                    value={field}
                                    onChange={(e) => setField(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='editAbout__formcol'>
                            <div className='editAbout__form-margin'>
                                <h1>Start year*</h1>
                                <input
                                    type="text"
                                    placeholder='year'
                                    value={startYear}
                                    onChange={(e) => setStartYear(e.target.value)}
                                />
                            </div>
                            <div>
                                <h1>End year*</h1>
                                <input
                                    type="text"
                                    placeholder='year'
                                    value={endYear}
                                    onChange={(e) => setEndYear(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='editAbout__save' onClick={handleSave}>
                            <div>
                                Save
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddEducation;
