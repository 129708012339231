import React, { useState } from 'react';
import '../Experience/AddExperience.css'; // Replace with the actual CSS file path
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import API_URL from '../../../api/Api';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';

const AddExperience = ({ onAddExperience, onClose }) => {
    const [jobTitle, setJobTitle] = useState('');
    const [jobType, setJobType] = useState('');
    const [company, setCompany] = useState('');
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');
    const [startMonth, setStartMonth] = useState('');
    const [startYear, setStartYear] = useState('');
    const [endMonth, setEndMonth] = useState('');
    const [endYear, setEndYear] = useState('');
    const imageRef = React.useRef(null);
    const [sendLoading, setSendLoading] = useState(false);

    function useDisplayImage() {
        const [result, setResult] = React.useState(false);

        function uploader(e) {
            const imageFile = e.target.files[0];

            const reader = new FileReader();
            reader.addEventListener('load', (e) => {
                setResult(e.target.result);
            });

            reader.readAsDataURL(imageFile);
        }

        return { result, uploader };
    }

    const { result, uploader } = useDisplayImage();

    const handleSave = async () => {
        // if (!result || !jobTitle || !jobType || !company || !location || !description || !startMonth || !startYear || !endMonth || !endYear) {
        if (!jobTitle || !jobType || !company || !location) {
            window.alert('Please fill all required fields.');
            return;
        }
        const img = result ? result : "";
        try {
            setSendLoading(true)
            const response = await axios.post(`${API_URL}/api/mentor/experience/add`, {
                companyImage: img,
                title: jobTitle,
                employmentType: jobType,
                companyName: company,
                location,
                description,
                startMonth,
                startYear,
                endMonth,
                endYear,
            }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            })
            console.log(response.data);
            onClose();
            setSendLoading(false)
            toast.success('Experience added successfully');
        } catch (error) {
            console.log(error)
            toast.error('Error adding experience');
            setSendLoading(false)
        }
    };

    return (
        <>
             <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={sendLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='addExperience__container'>
                <div className="addExperience__main">
                    <h1>Add Experience:
                        <span onClick={() => { onClose() }} >
                            <CloseIcon fontSize='4rem' color='white' />
                        </span>
                    </h1>
                    <div>
                        <div className='addExperience__formcol'>
                            <div>
                                <h1>Title*</h1>
                                <input
                                    type="text"
                                    placeholder='Enter your job title'
                                    value={jobTitle}
                                    onChange={(e) => setJobTitle(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='addExperience__formcol'>
                            <div className='addExperience__form-margin' >
                                <h1>Employment type*</h1>
                                <input
                                    type="text"
                                    placeholder='Type of employment'
                                    value={jobType}
                                    onChange={(e) => setJobType(e.target.value)}
                                />
                            </div>
                            <div>
                                <h1>Location*</h1>
                                <input
                                    type="text"
                                    placeholder='Location'
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='addExperience__formcol'>
                            <div className='addExperience__form-margin'>
                                <h1>Company name*</h1>
                                <input
                                    type="text"
                                    placeholder='Company name'
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                />
                            </div>
                            <div>
                                <h1>Company Image*</h1>
                                <div className='addExperience__upload'>
                                    <div className='addExperience__upload-img'>
                                        {!result ? 'Choose Image' : 'Change'}
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => uploader(e)}
                                        />
                                    </div>

                                    {result && (
                                        <div className='addExperience__upload-viewimg'>
                                            <img ref={imageRef} src={result} alt="Uploaded" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='addExperience__formcol'>
                            <div>
                                <h1>Description*</h1>
                                <textarea
                                    placeholder='Ex: a brief note about your job'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='addExperience__formcol'>
                            <div className='addExperience__form-margin'>
                                <h1>Start Month*</h1>
                                <input
                                    type="text"
                                    placeholder='Month'
                                    value={startMonth}
                                    onChange={(e) => setStartMonth(e.target.value)}
                                />
                            </div>
                            <div>
                                <h1>Start year*</h1>
                                <input
                                    type="text"
                                    placeholder='Year'
                                    value={startYear}
                                    onChange={(e) => setStartYear(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='addExperience__formcol'>
                            <div className='addExperience__form-margin'>
                                <h1>End Month*</h1>
                                <input
                                    type="text"
                                    placeholder='Month'
                                    value={endMonth}
                                    onChange={(e) => setEndMonth(e.target.value)}
                                />
                            </div>
                            <div>
                                <h1>End year*</h1>
                                <input
                                    type="text"
                                    placeholder='Year'
                                    value={endYear}
                                    onChange={(e) => setEndYear(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='addExperience__save' onClick={handleSave}>
                            <div>
                                Save
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddExperience;
