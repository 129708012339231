import React, { useState, useEffect, Fragment } from "react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { useParams } from "react-router-dom";
import API_URL from "../../api/Api";
import toast from "react-hot-toast";

const VideoRoom = () => {
  const { roomId } = useParams();
  const [data, setData] = useState(null);
  const [fetchComplete, setFetchComplete] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  function fetchToken() {
    setIsLoading(true);
    fetch(`${API_URL}/api/room/auth?roomId=${roomId}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data1) => {
        if (data1.ok) {
          console.log(data1);
          const { token, appId, userId, name } = data1;
          setData({ token, appId, userId, name });

          setError(false);
          setFetchComplete(true);
        } else {
          console.log(data1.msg);
          toast.error(data1.msg);
          setError(true);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    fetchToken();
  }, []);

  const myMeeting = async (element) => {
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForProduction(
      data.appId,
      data.token,
      roomId,
      data.userId,
      data.name
    );

    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);

    // start the call
    zp.joinRoom({
      container: element,
      scenario: {
        mode: ZegoUIKitPrebuilt.VideoConference,
      },
    });
  };

  return (
    <>
      {isLoading && <div>Is Loading</div>}

      {!isLoading && !error && (
        <div
          className="myCallContainer"
          ref={myMeeting}
          style={{ width: "100vw", height: "100vh" }}
        ></div>
      )}

      {!isLoading && error && <div>There was an error</div>}
    </>
  );
};

export default VideoRoom;
