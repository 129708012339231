import React, { useState } from "react";
import "./Home.css";
import {
  Card,
  Footer,
  HomeCycle,
  HomeFeedback,
  HomeSuccess,
  ScrollTop,
  Slider,
} from "../../components";
import { Link, useNavigate } from "react-router-dom";
import Navbar2 from "../../components/Navbar2";
import BookCall from "../BookCall/BookCall";
import { Modal } from "@mui/material";
import { Cursor, Typewriter, useTypewriter } from "react-simple-typewriter";
import { DataState } from "../../context/DataProvider";

const Home = () => {
  const navigate = useNavigate();
  const { searchTerm, setSearchTerm } = DataState();
  const [openCallModal, setOpenCallModal] = useState(false);

  const cardDetails = [
    {
      number: "1",
      text: "Shortlist a mentor for you according to your domain & Preference",
      btntext: "View Mentors",
    },
    {
      number: "2",
      text: "Book a free session to see how the mentor can help you",
      btntext: "Book a Free Trial",
    },
    {
      number: "3",
      text: "Start getting mentored with long term mentorship with your mentor",
      btntext: "Get Started",
    },
  ];

  const handleOpen = () => setOpenCallModal(true);
  const handleClose = () => setOpenCallModal(false);

  return (
    <>
      <Navbar2 />
      <div className="home__container-main">
        <div className="home__container">
          <div className="home__body">
            <div class="hero">
              <div class="diagonal-hero-bg">
                <div class="stars">
                  <div class="small"></div>
                  <div class="medium"></div>
                  <div class="big"></div>
                  <div className="Data">
                    <div className="home">
                      <div className="home__top">
                        <p>
                          Learn a new skill, launch a project, land your dream
                          career.
                        </p>
                        <div className="home__top_head">
                          <h1>1-on-1</h1>
                          <h1>Mentorship</h1>
                          <h1>on</h1>
                          <h1>
                            <Typewriter
                              words={typingWords}
                              loop={5}
                              cursor
                              cursorStyle="|"
                              cursorColor="#fff"
                              typeSpeed={70}
                              deleteSpeed={60}
                            />
                          </h1>
                        </div>
                        <div className="home__inputBox">
                          <input
                            placeholder="Search by company, skills or role..."
                            value={searchTerm}
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                            }}
                          />
                          <Link to={`/mentorlist`}>
                            <button>Search</button>
                          </Link>
                        </div>
                        <div className="home__searchTerms">
                          {searchItems.map((item, index) => (
                            <div
                              onClick={() => {
                                setSearchTerm(item);
                                navigate("/mentorlist");
                              }}
                              to={`/mentorlist`}
                              key={index}
                            >
                              {item}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Slider />

            <div className="home__card">
              <div className="home__card-head">
                <h1>Let's Build your career</h1>
                <img src="./assets/biglogo.png" alt="" loading="lazy" />
              </div>
              <div className="home__cardMain">
                {cardDetails.map((card, index) => (
                  <Card
                    key={index}
                    number={card.number}
                    text={card.text}
                    buttonText={card.btntext}
                  />
                ))}
              </div>
            </div>

            <HomeCycle />
            <HomeSuccess />
            <HomeFeedback />
          </div>
        </div>
      </div>
      <Footer />
      <ScrollTop />
    </>
  );
};

const typingWords = [
  "AI/ML",
  "Backend",
  "Blockchain Technology",
  "Business",
  "Cloud",
  "Cloud Computing",
  "Computer Graphics",
  "Consulting",
  "Cyber Security",
  "Cybersecurity",
  "Data Science",
  "Database Management",
  "Design",
  "DevOps",
  "Finance",
  "Frontend",
  "Full Stack",
  "Game Development",
  "Human Resource",
  "IOT",
  "IT Consulting",
  "IT Governance",
  "Management",
  "Marketing",
  "ML",
  "Network",
  "Networking",
  "Web Development",
];

const searchItems = [
  "Frontend",
  "Backend",
  "Full Stack",
  "Design",
  "DevOps",
  "Finance",
  "Frontend",
  "Startup",
  "Product Manager",
  "UI/UX Designer",
  "Marketing",
];

export default Home;
