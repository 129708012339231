


import React, { useRef, useEffect, useState } from 'react';
import './EditAvatar.css';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar } from '@mui/material';
import axios from 'axios';
import API_URL from '../../../api/Api';

const EditAvatar = ({ avatar, onClose }) => {
  const imageRef = useRef(null);

  function useDisplayImage() {
    const [result, setResult] = useState(false);

    function uploader(e) {
      const imageFile = e.target.files[0];

      const reader = new FileReader();
      reader.addEventListener('load', (e) => {
        setResult(e.target.result);
      });

      reader.readAsDataURL(imageFile);
    }

    return { result, uploader };
  }
  const { result, uploader } = useDisplayImage();

  const handleProfilePic = async () => {
    try {
      const newAvatar = result ? result : avatar;
  
      const formData = new FormData();
      formData.append('picture', newAvatar);
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
        },
      };
  
      const response = await axios.put(
        `${API_URL}/api/updateUserInfo`,
        formData,
        config
      );
  
      if (response.status === 200) {
        console.log(response.data.msg);
        window.location.reload();
        onClose();
      } else {
        console.error(response.data.error);
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  
    onClose();
  };
  

  return (
    <>
      <div className='editAvatar__container'>
        <div className='editAvatar__main'>
          <div className='editAvatar__head'>
            Change Photo
            <span onClick={() => onClose()}>
              <CloseIcon fontSize='4rem' color='white' />
            </span>
          </div>
          <div className='editAvatar__image'>
            <h1>Help others recognize you!</h1>
            <div>
              {result ? (
                <Avatar ref={imageRef} src={result} sx={{ width: '17rem', height: '17rem' }} />
              ) : (
                <Avatar src={avatar} sx={{ width: '17rem', height: '17rem' }} />
              )}
            </div>
            <p>Prefer size "500x450px"</p>
          </div>
          <div className='editAvatar__upload'>
            {!result ? (
              <div className='editAvatar__input'>
                Select Image
                <input type='file' name='image' onChange={(e) => uploader(e)} />
              </div>
            ) : (
              <div className='editAvatar__input'>
                Change Image
                <input type='file' name='image' onChange={(e) => uploader(e)} />
              </div>
            )}
            {result && <div className='editAvatar__submit' onClick={handleProfilePic} style={{ marginLeft: '1rem' }}>
              Save
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAvatar;
