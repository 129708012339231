import React from "react";
import "./css/ProfileDes.css";
import { Menu, MenuItem } from "@mui/material";

const ProfileDes = ({ mentorData }) => {
  //about
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="profile__desciption">
        <span>
          <img src="./assets/profile-des-sign.png" alt="" />
        </span>
        <div className="profile__desciption-text">
          <code>
            {mentorData?.about[0]?.about?.slice(0, 300)}
            {mentorData?.about[0]?.about?.length > 300 && (
                  ". . ."
                )}
            {mentorData?.about[0]?.about?.length > 300 && (
              <span onClick={handleClick}>more</span>
            )}
          </code>
        </div>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "hidden",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              border: "1px solid gray",
              mt: 1.5,
              bgcolor: "#121212",
              maxHeight: "35rem",
            },
          }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          sx={{ padding: "0px" }}
        >
          <MenuItem onClick={handleClose} sx={{ padding: "0px" }}>
            <div className="mc__about-menu-main">
              <div className="mc__about-menu">
                <p>{mentorData?.about[0]?.about}</p>
              </div>
            </div>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};

export default ProfileDes;
