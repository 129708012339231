import React from 'react';

const PaymentCancelledScreen = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-black text-white">
      <div className="max-w-md p-8 rounded-lg shadow-lg border border-gray-800">
        <h2 className="text-3xl font-bold mb-4">Payment Cancelled</h2>
        <p className="mb-6">Unfortunately, your payment process has been cancelled.</p>
        <button
          onClick={() => {
            // Redirect logic here
            window.location.href = '/'; // Redirect to home page
          }}
          className="px-4 py-2 bg-red-600 hover:bg-red-700 rounded-md text-white focus:outline-none"
        >
          Go back
        </button>
      </div>
    </div>
  );
};

export default PaymentCancelledScreen;
