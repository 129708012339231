import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Login.css";
import GoogleAuth from "./GoogleAuth";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";

const Login = () => {

  // const storedRole = localStorage.getItem('selectedRole');
  // const [selectedRole, setSelectedRole] = useState(storedRole || null);

  // const handleRoleSelection = (role) => {
  //   setSelectedRole(role);
  //   localStorage.setItem('selectedRole', role);
  // };

  const storedRole = localStorage.getItem('selectedRole');
  const [selectedRole, setSelectedRole] = useState(storedRole || 'mentee'); // Set default value to 'mentee'

  useEffect(() => {
    if (!storedRole) {
      // If no role is stored in localStorage, set it to 'mentee'
      setSelectedRole('mentee');
      localStorage.setItem('selectedRole', 'mentee');
    }
  }, []); // Empty dependency array ensures this effect runs only once, on mount

  const handleRoleSelection = (role) => {
    setSelectedRole(role);
    localStorage.setItem('selectedRole', role);
  };
  return (
    <div className="login__wrapper-main">
      <div className="login__wrapper">
        <div className="login__body">
          <Link to="/">
            <div className="login__back">
              <ArrowCircleLeftRoundedIcon sx={{ fontSize: "2rem" }} />
              Home
            </div>
          </Link>
          <div className="login__left">
            <div>
              <SkipPreviousIcon
                sx={{
                  position: "absolute",
                  top: "5rem",
                  left: "1.2rem",
                  fontSize: "3.8rem",
                  color: "white",
                }}
              />
              <p>
                Welcome to a community of learning, growing, and succeeding
                together.
              </p>
            </div>
          </div>
          <div className="login__right">
            <Link to="/">
              <div className="login__logo">
                <img src="./assets/event-logo.svg" alt="" />
              </div>
            </Link>
            <div className="login__main">
              <div className="login__content">
                <h1>Sign in</h1>
                <p>We'd love to know who you are</p>
                <div>
                  <div
                    onClick={() => handleRoleSelection("mentee")}
                    className={`login__roles 
                    ${selectedRole === "mentee" ? "selected" : ""}`}
                  >
                    Mentee
                  </div>
                  <span>or</span>
                  <div
                    onClick={() => handleRoleSelection("mentor")}
                    className={`login__roles 
                    ${selectedRole === "mentor" ? "selected" : ""}`}
                  >
                    Mentor
                  </div>
                </div>
                <line />
                <GoogleAuth redirectUrl={"/"} selectedRole={selectedRole} />
                <p>
                  By signing up, you agree to the <span>terms of service</span>{" "}
                  & <span>privacy policy</span>, including{" "}
                  <span>cookie use</span>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
