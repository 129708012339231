import './CalenderTest.css';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfToday,
} from 'date-fns';
import { Fragment, useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import API_URL from '../../api/Api';
import axios from 'axios';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example({ userId, selectedTime, setSelectedTime }) {

  let today = startOfToday()
  let [selectedDay, setSelectedDay] = useState(today)
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())
  const [selectedMeetTime, setSelectedMeetTime] = useState([]);


  let days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  })

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  const [slots, setSlots] = useState([])
  const [slotfetchLoading, setSlotfetchLoading] = useState(false)
  useEffect(() => {
    const fetchSlots = async () => {
      try {
        setSlotfetchLoading(true)
        // const response = await axios.get(`${API_URL}/api/mentorAvailableSlot/getbymentor?user=${userId}`, {
        const response = await axios.get(`${API_URL}/api/mentorAvailableSlot/onlyAvailable?user=${userId}`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
          }
        })
        // console.log(response.data)
        setSlots(response.data)
        setSlotfetchLoading(true)
      } catch (error) {
        console.log(error)
      }
    }
    fetchSlots()
  }, [userId])

  let selectedDayMeetings = slots.filter((meeting) =>
    isSameDay(parseISO(meeting.start_time), selectedDay)
  )

  return (

    <div className="pt-16 calender-main">
      <div className="max-w-md p-18 mx-auto sm:p-18 md:max-w-6xl md:p-20 calender">
        <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200">
          <div className="md:pr-14">
            <div className="flex items-center">
              <h2 className="flex-auto font-semibold text-white text-2xl">
                {format(firstDayCurrentMonth, 'MMMM yyyy')}
              </h2>
              <button
                type="button"
                onClick={previousMonth}
                className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-white hover:text-gray-200"
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon sx={{ fontSize: '1.6rem' }} />
              </button>
              <button
                onClick={nextMonth}
                type="button"
                className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-white hover:text-gray-20"
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon sx={{ fontSize: '1.6rem' }} />
              </button>
            </div>
            <div className="grid grid-cols-7 mt-10 text-2xl leading-6 text-center  text-white">
              <div>S</div>
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>T</div>
              <div>F</div>
              <div>S</div>
            </div>
            <div className="grid grid-cols-7 mt-2 text-lg">
              {days.map((day, dayIdx) => (
                <div
                  key={day.toString()}
                  className={classNames(
                    dayIdx === 0 && colStartClasses[getDay(day)],
                    'py-1.5'
                  )}
                >
                  <button
                    type="button"
                    onClick={() => setSelectedDay(day)}
                    className={classNames(
                      isEqual(day, selectedDay) && 'text-black',
                      !isEqual(day, selectedDay) &&
                      isToday(day) &&
                      'text-red-400',
                      !isEqual(day, selectedDay) &&
                      !isToday(day) &&
                      isSameMonth(day, firstDayCurrentMonth) &&
                      'text-white',
                      !isEqual(day, selectedDay) &&
                      !isToday(day) &&
                      !isSameMonth(day, firstDayCurrentMonth) &&
                      'text-gray',
                      isEqual(day, selectedDay) && isToday(day) && 'bg-red-400',
                      // isEqual(day, selectedDay) && isToday(day) && 'ct__selectedDate',
                      isEqual(day, selectedDay) &&
                      !isToday(day) &&
                      'bg-white',
                      !isEqual(day, selectedDay) && 'hover:bg-gray-800',
                      (isEqual(day, selectedDay) || isToday(day)) &&
                      'font-semibold',
                      'mx-auto flex h-12 w-12 items-center justify-center rounded-full text-2xl'
                    )}
                  >
                    <time dateTime={format(day, 'yyyy-MM-dd')}>
                      {format(day, 'd')}
                    </time>
                  </button>

                  <div className="w-1 h-1 mx-auto mt-1">
                    {slots.some((meeting) =>
                      isSameDay(parseISO(meeting.start_time), day)
                    ) && (
                        <div className="w-1 h-1 rounded-full bg-sky-500"></div>
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <section className="mt-12 md:mt-0 md:pl-14">
            {!selectedMeetTime?.length <= 0 &&
              <>
                <h2 className="text-white text-2xl">
                  Schedule for{' '}
                  {selectedMeetTime[0]}
                </h2>
                <h2 className='text-white text-xl'> at {selectedMeetTime[1]}</h2>
              </>
            }
            <div className="ct__time-container">
              {selectedDayMeetings.length > 0 ? (
                selectedDayMeetings.map((meeting) => (
                  // <Meeting meeting={meeting} key= {meeting.id} />
                  <Meeting key={meeting.id} meeting={meeting} selectedTime={selectedTime} setSelectedTime={setSelectedTime} setSelectedMeetTime={setSelectedMeetTime} />

                ))
              ) : (
                <p className='text-white'>No slots for that day.</p>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

function Meeting({ meeting, selectedTime, setSelectedTime, setSelectedMeetTime }) {
  let start_time = new Date(meeting.start_time);
  let end_time = new Date(meeting.end_time);

  const formattedTimeInterval = `${format(start_time, 'h:mm a')} - ${format(end_time, 'h:mm a')}`
  const formattedDate = format(start_time, 'dd MMM yyyy')

  const handleTimeSelect = () => {
    if (selectedTime === meeting._id) {
      setSelectedTime(null);
      setSelectedMeetTime(null);
    } else {
      setSelectedTime(meeting._id);
      setSelectedMeetTime([formattedDate, formattedTimeInterval]);
    }
  };

  return (
    <div className={`ct__meeting ${selectedTime === meeting._id ? 'selectedtime' : ''}`} onClick={handleTimeSelect}>
      <div className="ct__meeting-info">
        {formattedTimeInterval}
      </div>
    </div>
  );
}

let colStartClasses = [
  '',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
  'col-start-7',
]