import React from "react";
import Navbar2 from "../../components/Navbar2";
import ScrollTop from "../../components/miscellenius/ScrollTop";

const About = () => {
  return (
    <>
      <Navbar2 />
      <div
        className="bg-gray-900 text-white min-h-screen"
        style={{
          background:
            "linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent 130%), linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent 130%), linear-gradient(to bottom, #714FB4, #714FB4)",
        }}
      >
        <div className="container mx-auto py-8">
          <h1 className="text-5xl font-bold mb-4">About Us</h1>
          <p className="text-2xl mb-2">
            Welcome to inQbator Technologies LLP, where innovation meets
            expertise to drive the success of startups and businesses.
          </p>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">Our Vision</h2>
            <p className="text-2xl mb-2">
              At inQbator, we envision a dynamic and collaborative ecosystem
              where aspiring entrepreneurs and seasoned professionals converge
              to turn ideas into thriving ventures. We are committed to
              fostering innovation, providing mentorship, and creating a
              supportive environment for the growth of groundbreaking ideas.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">Who We Are</h2>
            <p className="text-2xl mb-2">
              Established in 2023, inQbator Technologies LLP is a dedicated
              platform designed to nurture and propel startups to new heights.
              Our team comprises passionate individuals who bring a wealth of
              experience in diverse industries, including technology, business
              development, and mentorship.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">What Sets Us Apart</h2>
            <p className="text-2xl mb-2">
              Holistic Support: We go beyond traditional incubation by offering
              comprehensive support, including mentorship, technical consulting,
              and a collaborative community.
            </p>
            <p className="text-2xl mb-2">
              Experienced Mentors: Our network of mentors includes industry
              experts, successful entrepreneurs, and seasoned professionals who
              are committed to guiding and empowering the next generation of
              innovators.
            </p>
            <p className="text-2xl mb-2">
              Innovation Hub: inQbator serves as a hub for creativity and
              collaboration, providing startups with the resources and
              connections needed to thrive in today's competitive business
              landscape.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">Our Mission</h2>
            <p className="text-2xl mb-2">
              Our mission is to empower entrepreneurs with the tools and
              guidance needed to transform their visions into successful
              enterprises. We are dedicated to creating a vibrant community that
              fosters collaboration, learning, and innovation.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">How We Work</h2>
            <p className="text-2xl mb-2">
              Mentorship Programs: Our tailored mentorship programs connect
              startups with experienced mentors who provide guidance, insights,
              and a roadmap for success.
            </p>
            <p className="text-2xl mb-2">
              Technical Consulting: Through our technical consulting services,
              we help startups navigate the complexities of technology, ensuring
              they have the right foundation for growth.
            </p>
            <p className="text-2xl mb-2">
              Collaborative Workspace: inQbator offers a collaborative workspace
              that encourages networking, idea sharing, and a sense of community
              among our members.
            </p>
          </div>

          <div className="mt-14">
            <h2 className="text-3xl font-semibold mb-3">
              Join Us on the Innovation Journey
            </h2>
            <p className="text-2xl mb-2">
              Whether you are a budding entrepreneur with a groundbreaking idea
              or an industry veteran looking to share your expertise, inQbator
              welcomes you to join us on the innovation journey. Together, let's
              build a future where every idea has the potential to change the
              world.
            </p>
            <p className="text-2xl mb-2">
              Contact us at{" "}
              <a href="mailto:inqbatorindia@gmail.com" className="underline">
                inqbatorindia@gmail.com
              </a>{" "}
              to explore how inQbator can be the catalyst for your success. Feel
              free to modify this template to better fit the specific details
              and ethos of inQbator Technologies LLP.
            </p>
          </div>
        </div>
      </div>
      <ScrollTop/>
    </>
  );
};

export default About;
