import React, { useState } from 'react'
import './css/ProfileFAQ.css'

const Question = ({ title, info }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <article
                className="profile__question"
                onClick={() => setExpanded(!expanded)}
            >
                <div>
                    <h4 className="profile__question-title">{title}</h4>
                </div>
            </article>
            <div
                className={`profile__question-ans ${expanded ? "" : "closed active"}`}
            >
                <p>{info}</p>
            </div>
        </>
    );
};

const ProfileFAQ = () => {
    return (
        <>
            <div className=".profile__faq-main">
                <div className="profile__faq">
                    <div className="profile__faq-body">
                        <div className="profile__faq-body-head">
                            <h3>FAQ's</h3>
                        </div>
                        <div className="profile__faq-body-head-ques">
                            {questions.map((question) => (
                                <Question key={question.id} {...question} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileFAQ

const questions = [
    {
        id: 1,
        title: "How can I find a suitable career mentor on the platform?",
        info: "Navigate to the 'Mentors' section, filter based on your preferences, and explore mentor profiles to find the right match for your career goals.",
    },
    {
        id: 2,
        title: "What kind of guidance can I expect from a career mentor?",
        info: "Career mentors offer insights into industry trends, assist with goal setting, provide feedback on professional development, and help navigate career challenges.",
    },
    {
        id: 3,
        title: "How do I initiate communication with a potential mentor?",
        info: "Once you've identified a mentor, use the platform's ask mentor feature to send a respectful and personalized message expressing your interest in their mentorship and further book a 1:1 mentorship session.",
    },
    {
        id: 4,
        title: "Can I connect with multiple mentors simultaneously on the platform?",
        info: "Yes, you can connect with multiple mentors based on your career needs and interests. The platform encourages diverse mentorship experiences to enrich your professional journey.",
    },
    {
        id: 5,
        title: "Is mentorship limited to specific industries or career fields?",
        info: "No, the platform provides a diverse range of mentors across various industries, ensuring you can find mentorship tailored to your specific career path and goals.",
    },
];
