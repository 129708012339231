import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./css/CreatePost.css";
import { DataState } from "../../context/DataProvider";
import { Avatar, Modal } from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import ArticleIcon from "@mui/icons-material/Article";
import MakePost from "./MakePost";
import CustomDialog from "../../components/custom/CustomDialog";

import PropTypes from "prop-types";
import axios from "axios";
import API_URL from "../../api/Api";

const Editor = ({ placeholder }) => {
  const { accessToken } = DataState();
  const [editorHtml, setEditorHtml] = useState("");

  console.log(editorHtml);

  const handleChange = (html) => {
    setEditorHtml(html);
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  const handleSave = async () => {
    try {
      const token = "your-bearer-token"; // Replace with your actual bearer token
      const response = await axios.post(
        `${API_URL}/api/post/create`,
        { description: editorHtml },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        console.log("Article saved successfully");
      } else {
        console.error("Failed to save article");
      }
    } catch (error) {
      console.error("Error saving article:", error);
    }
  };

  return (
    <div className="bg-[#212121] h-full w-full py-10 px-32">
        <button onClick={handleSave}>Save</button>
      <ReactQuill
        className="custom-editor" // Apply custom CSS class for styling
        onChange={handleChange}
        value={editorHtml}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
      />
    </div>
  );
};

Editor.propTypes = {
  placeholder: PropTypes.string,
};

const CreatePost = () => {
  const { user } = DataState();
  const [openMakePost, setOpenMakePost] = useState(false);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = useState("");

  return (
    <div className="cp__main">
      <div className="cp__top">
        <Avatar src={user?.picture} sx={{ height: "4rem", width: "4rem" }} />
        <div className="cp__top-div" onClick={() => setOpenMakePost(true)}>
          Start a post
        </div>
      </div>
      <div className="cp__btm">
        <div onClick={() => setOpenMakePost(true)}>
          <InsertPhotoIcon sx={{ color: "#70b5f9", fontSize: "2rem" }} />
          <span>Photo</span>
        </div>
        <div onClick={handleOpen}>
          <ArticleIcon sx={{ color: "#fc9295", fontSize: "2rem" }} />
          <span>Write article</span>
        </div>

        <CustomDialog open={open} setOpen={setOpen} handleClose={handleClose}>
          {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
          <Editor />
        </CustomDialog>
      </div>
      <div className="mentordash__modal">
        <Modal open={openMakePost} onClose={() => setOpenMakePost(false)}>
          <MakePost onClose={() => setOpenMakePost(false)} />
        </Modal>
      </div>
    </div>
  );
};

export default CreatePost;
