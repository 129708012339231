import React, { useEffect, useRef, useState } from 'react'
import './SingleQuestion.css'
import Navbar2 from '../../components/Navbar2';
import Footer from '../Footer/Footer';
import API_URL from '../../api/Api';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, Modal, Skeleton } from '@mui/material';
import Answers from './Answers';
import SendQuestions from '../../Pages/AskMentors/SendQuestions';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { DataState } from '../../context/DataProvider';
import ScrollTop from '../miscellenius/ScrollTop';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import {
    LinkedinShareButton,
    TwitterShareButton,
    FacebookShareButton,
    generateShareIcon,
} from "react-share";

const DateTimeConverter = ({ utcTimestamp }) => {
    const convertUtcToIst = (utcTimestamp) => {
        // Create a Date object from the UTC timestamp
        const utcDate = new Date(utcTimestamp);

        // Convert to IST (Indian Standard Time, UTC+05:30)
        utcDate.setHours(utcDate.getHours()); // Add 5 hours
        utcDate.setMinutes(utcDate.getMinutes()); // Add 30 minutes

        // Format the date and time
        const options = {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            day: "numeric",
            month: "short",
            year: "numeric",
            // timeZoneName: "short",
        };

        return utcDate.toLocaleDateString("en-US", options);
    };

    const formattedDateTime = convertUtcToIst(utcTimestamp);

    return <>{formattedDateTime}</>;
};


const SingleQuestion = () => {
    const location = useLocation();
    var queId = new URLSearchParams(location.search).get("queId");
    const openInput = new URLSearchParams(location.search).get('openInput');

    // Create a ref for the answer input field
    const answerInputRef = useRef(null);

    // To handle the reply operation
    const [replyInputText, setReplyInputText] = useState('');
    const [openReply, setOpenReply] = useState(false);
    const { user, accessToken } = DataState()
    const [singleQuesLoading, setSingleQuesLoading] = useState(true);
    const [getSingleQuestions, setGetSingleQuestions] = useState([]);
    const [userData, setUserData] = useState(null);
    const [openSendQue, setOpenSendQue] = useState(false)
    const [likeAndDislike, setLikeAndDislike] = useState(false);

    const fetchSingleQuestion = async () => {
        try {
            setSingleQuesLoading(true)
            const response = await axios.get(`${API_URL}/api/question/answers/${queId}`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            });
            setGetSingleQuestions(response.data.data);
            setSingleQuesLoading(false)
        } catch (error) {
            console.error('Error fetching categories:', error);
            setSingleQuesLoading(false)
        }
    };
    useEffect(() => {
        if (queId) {
            fetchSingleQuestion();
        }
    }, [queId]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/particular-user-data/${getSingleQuestions?.from}`);
                setUserData(response.data.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        if (getSingleQuestions?.from) {
            fetchUserData();
        }
    }, [getSingleQuestions?.from]);

    // To handle the like and dislike operation
    const handleLikeAndDislike = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/question/getLikeAndDislike/${queId}`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            });
            setLikeAndDislike(response.data.data[0])
        } catch (error) {
            console.log(error)
        }
    };

    const handleLike = async () => {
        try {
            const response = await axios.post(`${API_URL}/api/question/like/${queId}`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            handleLikeAndDislike()
        } catch (error) {
            console.log(error)
        }
    }

    const handleDislike = async () => {
        try {
            const response = await axios.post(`${API_URL}/api/question/dislike/${queId}`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            handleLikeAndDislike()
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        handleLikeAndDislike()
    }, [])

    const handleReplyClick = () => {
        setOpenReply(!openReply);
        if (!openReply) {
            setReplyInputText('');
        }
    };

    const handleReplyInputChange = (e) => {
        setReplyInputText(e.target.value);
    };

    // To handle the copy operation
    const handleAnswerReply = async () => {
        try {
            const response = await axios.post(`${API_URL}/api/question/answers/${queId}`, {
                message: replyInputText
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            handleReplyClick()
            fetchSingleQuestion()
            setReplyInputText('')
        } catch (error) {
            console.log(error)
        }
    }

    // To handle the copy operation
    const [linkToCopy, setLinkToCopy] = useState(`http://localhost:3000/question?queId=${queId}`);

    const handleInputChanges = () => {
        setOpenReply(true);
        console.log("openReply", openReply);
        answerInputRef.current.focus();
    }
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const openInputParam = searchParams.get('openInput');

        if (openInputParam === 'true' && answerInputRef.current) {
            handleInputChanges()
        }
    }, [answerInputRef, openReply]);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <Navbar2 />
            <div className="sq__container-main">
                <div className="sq__container">
                    <div className='sq__main'>

                        <div className='sq__main-com'>

                            <div className="sq__question">
                                <div className="sq__question-name">
                                    <Avatar src={userData?.picture} sx={{ width: '3.7rem', height: '3.7rem' }}></Avatar>
                                    <h1>Asked by {userData?.name} | {<DateTimeConverter utcTimestamp={getSingleQuestions?.createdAt} />}</h1>
                                </div>
                                <div className='sq__question-ques'>
                                    <p>{getSingleQuestions?.question}</p>
                                </div>
                                <div className="sq__reply-main">
                                    <div className="sq__reply-btn" onClick={() => setOpenReply(true)}>
                                        <p>Share Your Answers</p>
                                    </div>
                                    <div className="qcard__reply">
                                        <div className='qcard__reply-like'>
                                            {!likeAndDislike?.likes?.includes(user?._id) ? (
                                                <ThumbUpOffAltIcon
                                                    onClick={handleLike}
                                                    sx={{ fontSize: '2.5rem' }}
                                                />
                                            ) : (
                                                <ThumbUpAltIcon
                                                    onClick={handleLike}
                                                    sx={{
                                                        fontSize: '2.5rem',
                                                    }}
                                                />
                                            )}
                                            <span>{likeAndDislike?.likes?.length}</span>
                                        </div>
                                        <div className='qcard__reply-dislike'>
                                            {!likeAndDislike?.dislikes?.includes(user?._id) ? (
                                                <ThumbDownOffAltIcon
                                                    onClick={handleDislike}
                                                    sx={{
                                                        fontSize: '2.5rem',
                                                    }}
                                                />
                                            ) : (
                                                <ThumbDownAltIcon
                                                    onClick={handleDislike}
                                                    sx={{
                                                        fontSize: '2.5rem',
                                                    }}
                                                />
                                            )}
                                            <span>{likeAndDislike?.dislikes?.length}</span>
                                        </div>
                                        <div className="qcard__reply-share"
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                        >
                                            <img src="./assets/Vector (5).png" alt="" />
                                        </div>
                                        <div>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                        mt: 1.5,
                                                        '& .MuiAvatar-root': {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        '&:before': {
                                                            content: '""',
                                                            display: 'block',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 14,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: 'background.paper',
                                                            transform: 'translateY(-50%) rotate(45deg)',
                                                            zIndex: 0,
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                <MenuItem onClick={handleClose} sx={{ padding: '0px' }}>
                                                    <LinkedinShareButton url={linkToCopy}>
                                                        <div className="qcard__reply-share-items">
                                                            <LinkedInIcon sx={{ fontSize: '2.5rem' }} />
                                                            <span>Share on Linkedin</span>
                                                        </div>
                                                    </LinkedinShareButton>
                                                </MenuItem>
                                                <MenuItem onClick={handleClose} sx={{ padding: '0px' }}>
                                                    <TwitterShareButton url={linkToCopy}>
                                                        <div className="qcard__reply-share-items">
                                                            <TwitterIcon sx={{ fontSize: '2.5rem' }} />
                                                            <span>Share on Twitter</span>
                                                        </div>
                                                    </TwitterShareButton>
                                                </MenuItem>
                                                <MenuItem onClick={handleClose} sx={{ padding: '0px' }}>
                                                    <FacebookShareButton url={linkToCopy}>
                                                        <div className="qcard__reply-share-items">
                                                            <FacebookIcon sx={{ fontSize: '2.5rem' }} />
                                                            <span>Share on Facebook</span>
                                                        </div>
                                                    </FacebookShareButton>
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </div>

                                </div>
                                {openReply === true ? (<div className="sq___reply">
                                    <Avatar src={user?.picture} sx={{ width: '3.4rem', height: '3.4rem' }} />
                                    <div className='sq__reply-input'>
                                        <textarea
                                            ref={answerInputRef}
                                            autoFocus={openInput === 'true'}
                                            type='text'
                                            placeholder='Add a reply' value={replyInputText} onChange={handleReplyInputChange}
                                        />
                                        <div>
                                            <span onClick={handleReplyClick}>Cancel</span>
                                            <span onClick={handleAnswerReply}>Reply</span>
                                        </div>
                                    </div>
                                </div>) : ('')}
                            </div >

                            <div className="sq__ques-count">
                                <h5>
                                    Answers
                                    <span> ( {getSingleQuestions?.answers?.length} )</span>
                                </h5>
                                <span> </span>
                            </div>

                            <div className='sq__main-com-container'>
                                {singleQuesLoading ? (<>
                                    <div style={{ display: 'flex', flexFlow: 'column nowrap', gap: '2rem' }}>
                                        <Skeleton
                                            variant="rectangular"
                                            sx={{ bgcolor: "grey.900", borderRadius: "1rem" }}
                                            width={"100%"}
                                            height={"25rem"}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            sx={{ bgcolor: "grey.900", borderRadius: "1rem" }}
                                            width={"100%"}
                                            height={"25rem"}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            sx={{ bgcolor: "grey.900", borderRadius: "1rem" }}
                                            width={"100%"}
                                            height={"25rem"}
                                        />
                                    </div>
                                </>) : (<>
                                    {getSingleQuestions?.answers?.map((ans) => (
                                        <Answers key={ans?._id} ans={ans} />
                                    ))}
                                </>)}
                            </div>
                        </div>
                        <div className='sq__side'>
                            <div className="sq__side-ask">
                                <div className="sq__side-ask-head">
                                    <h2>Customer Acquisition ?</h2>
                                    <h2>Scalability ?</h2>
                                    <h2>Regulatory and Legal Compliance ?</h2>
                                </div>
                                <p>Clarify your inquiries with industry professionals</p>
                                <div className="sq__side-ask-btn" onClick={() => setOpenSendQue(true)}>
                                    <p>Ask your Doubt ?</p>
                                </div>
                                <Modal
                                    open={openSendQue}
                                    onClose={() => setOpenSendQue(false)}
                                >
                                    <SendQuestions key={SendQuestions} onClose={() => setOpenSendQue(false)} />
                                </Modal>
                            </div>
                            <div className="side__side-mentor">
                                <p>Are you a fresher seeking assistance from industry professionals?</p>
                                <Link to='/mentorlist'>
                                    <div className="side__side-mentor-btn">
                                        <p>Explore Mentors</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
            <Footer />
            <ScrollTop />
        </>
    )
}

export default SingleQuestion