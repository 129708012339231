import React, { useState, useEffect } from "react";
import axios from "axios";
import "../BookFreeTrial/Steps/BasicDetails.css";
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from "react-router-dom";
import API_URL from "../../api/Api";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import { Backdrop, CircularProgress } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import toast from "react-hot-toast";

const ChipSelector = ({ listItems, title, placeholderValue, selectedAreas, setSelectedAreas }) => {
    const [inputValue, setInputValue] = useState("");

    const Items1 = [
        "Upto 1 months",
        "Upto 6 months",
        "Upto 1 year",
    ];

    const handleChipClick = (area) => {
        if (selectedAreas.length < 5 && !selectedAreas.includes(area)) {
            setSelectedAreas((prevSelected) => [...prevSelected, area]);
        } else if (selectedAreas.length >= 5) {
            toast.error("You can select up to 5 items.");
        }
    };

    const handleChipRemove = (area) => {
        setSelectedAreas((prevSelected) => prevSelected.filter((c) => c !== area));
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleInputKeyPress = (event) => {
        if (event.key === "Enter" && inputValue.trim() !== "") {
            const area = inputValue.trim();
            if (selectedAreas.length < 5 && !selectedAreas.includes(area)) {
                setSelectedAreas((prevSelected) => [...prevSelected, area]);
                setInputValue("");
            } else if (selectedAreas.length >= 5) {
                toast.error("You can select up to 5 items.");
            }
        }
    };

    return (
        <div className="bd__form">
            <h1>{title}</h1>

            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleInputKeyPress}
                placeholder={placeholderValue}
            />

            <div className="bd__chip-container">
                {selectedAreas.map((area) => (
                    <>
                        <div key={area} className="bd__chip selected">
                            {area}
                            <CloseIcon onClick={() => handleChipRemove(area)} sx={{ marginLeft: '.7rem', fontSize: '1.5rem' }} />
                        </div>
                    </>
                ))}
            </div>
            <div className="bd__area-list">
                {listItems.map((area) => (
                    <div
                        key={area}
                        className={`bd__chip ${selectedAreas.includes(area) ? "disabled" : ""
                            }`}
                        onClick={() => handleChipClick(area)}
                    >
                        {area}
                    </div>
                ))}
            </div>
        </div>
    )
}

const PaidBasicDetails = ({ onNext, setSessionFormId }) => {
    const location = useLocation()
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    var userId = searchParams.get('userId');

    const [whyNeedMentorShip, setWhyNeedMentorShip] = useState();
    const [describeYourNeed, setDescribeYourNeed] = useState();
    const [mobileNumber, setMobileNumber] = useState();
    const [sendLoading, setSendLoading] = useState(false);
    const [open, setOpen] = React.useState(false);

    const isValidPhoneNumber = (phoneNumber) => {
        const phoneNumberRegex = /^(\+?\d{1,4}\s?)?(\d{10})$/;

        return phoneNumberRegex.test(phoneNumber);
    };

    const handleClickOpen = () => {
        if (!whyNeedMentorShip || !describeYourNeed || !mobileNumber) {
            toast.error("fill all fields first")
            return;
        }
        const phoneNumber = isValidPhoneNumber(mobileNumber);
        if (!phoneNumber) {
            toast.error("Enter Valid Phone No.");
            return;
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleBasicDetails = async () => {
        try {
            handleClose()
            if (!whyNeedMentorShip || !describeYourNeed || !mobileNumber) {
                toast.error("fill all fields first")
                return;
            }
            const phoneNumber = isValidPhoneNumber(mobileNumber);
            if (!phoneNumber) {
                toast.error("Enter Valid Phone No.");
                return;
            }
            setSendLoading(true)

            const response = await axios.post(`${API_URL}/api/session/saveForm?sessionType=paid`,
                {
                    mentor: userId,
                    number: mobileNumber,
                    whyNeedMentorShip: whyNeedMentorShip,
                    // description: describeYourNeed,
                    describeYourNeed: describeYourNeed,
                },
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                    }
                }
            )
            console.log(response.data.id)
            if (response.data.ok) {
                // setTrailSessionId(response.data.id)
                setSessionFormId(response.data.id);
                setSendLoading(false)

                // setFormData((data) => { return {...data, mentor: userId,
                //         number: mobileNumber,
                //         whyNeedMentorShip: whyNeedMentorShip,
                //         describeYourNeed: describeYourNeed,
                //         // howMentorship: selectedHowMentorship,
                //         // expectedTimeline: selectedExpectedTimeline
                //   }})
                onNext();
                }
        } catch (error) {
            console.log(error)
        }
    }

    const areFieldsEmpty = () => {
        return (
            !whyNeedMentorShip ||
            !describeYourNeed
            // selectedHowMentorship.length === 0
        );
    };

    const goBack = () => {
        navigate(-1); // Navigates to the previous page
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div
                    className="p-[24px] flex flex-col gap-[24px]"
                    style={{
                        background: "#000",
                        color: 'white',
                        border: '1px solid black',
                    }}
                >
                    <div id="alert-dialog-description" className="flex flex-col justify-start align-top gap-[10px]">
                        <h2 className="text-[#A0A0A0] text-[24px] w-full">Honor and respect your mentor's time</h2>
                        <h3 className="text-[#A0A0A0] text-[16px] w-full">Follow these guidelines for an excellent session</h3>

                        <ol>
                            <li className="text-[#fff] text-[16px]">1. Ensure you're on time and join punctually</li>
                            <li className="text-[#fff] text-[16px]">2. If you can't make it, please reschedule with advance notice</li>
                            <li className="text-[#fff] text-[16px]">3. Use the inQbator chat to keep your mentor updated</li>
                        </ol>
                        <div className="flex flex-row gap-2 align-middle justify-start" style={{ minWidth: '100%' }}>
                            <input className="cursor-pointer" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                            <p>I agree and will sure to follow the above rules</p>
                        </div>
                        <button
                            className={`${!isChecked ? 'bg-[#4f408b2a]' : 'bg-[#4F408B]'} text-[#fff] flex flex-row align-middle justify-center py-[12px] px-[20px] rounded-[10px]`}
                            disabled={!isChecked}
                            onClick={handleBasicDetails}
                        >
                            Book Session
                        </button>
                    </div>
                </div>
            </Dialog>
            <div className="bd__main">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={sendLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="bd__body">
                    <div className="bd__progressBar">
                        <div className="bd__back" onClick={goBack}>
                            <ArrowCircleLeftRoundedIcon sx={{ fontSize: "3.4rem" }} />
                        </div>
                        <div className='bd__progressBar-img'>
                            <img
                                src="./assets/progress bar 1.png"
                                alt=""
                            />
                        </div>
                    </div>

                    <h1 className="bd__heading-main">
                        Let Mentor know about your goals & problems and Vision
                        <img className="imgTop" src="./assets/arrow.png" alt="" />
                    </h1>

                    <form className="bd__form-main">
                        <div className="bd__form">
                            <h1>Mentorship areas for this session?</h1>
                            <textarea
                                name="whyNeedMentorShip"
                                // placeholder="Ex: In launching product / how to seek investment  "
                                placeholder="Enter mentorship areas."
                                value={whyNeedMentorShip}
                                onChange={(e) => setWhyNeedMentorShip(e.target.value)}
                                required
                            />
                        </div>

                        <div className="bd__form">
                            <h1>What do you like in mentor and where should the mentor focus on ?</h1>
                            <textarea
                                // placeholder="Describe your need , Problems , Conflicts You are facing and  get Solved "
                                placeholder="Describe where should the mentor focus on."
                                // name="describeYourNeed"
                                value={describeYourNeed}
                                onChange={(e) => setDescribeYourNeed(e.target.value)}
                                required
                            />
                        </div>

                        <div className="bd__form">
                            <h1>Enter your number</h1>
                            <input
                                placeholder="Your number required to connected with you"
                                name="describeYourNeed"
                                value={mobileNumber}
                                onChange={(e) => setMobileNumber(e.target.value)}
                                required
                            />
                        </div>

                        {/* <ChipSelector selectedAreas={selectedHowMentorship} setSelectedAreas={setSelectedHowMentorship} listItems={Items} placeholderValue={'Include Your Own'} title='How do you want the mentor to help via long-term mentorship?*' /> */}

                        {/* <ChipSelector selectedAreas={selectedExpectedTimeline} setSelectedAreas={setSelectedExpectedTimeline} listItems={Items1} placeholderValue={'Include Your Own'} title='Your expected Mentorship timeline?*' /> */}

                        {areFieldsEmpty() ? (
                            // If fields are empty, disable the div
                            <div className="bd__submit disabled">
                                <div className='disabledTrialBtn'>
                                    <p className="btn_schedule">Schedule</p>
                                    <img className="imgBtn" src="./assets/maki_arrow.png" alt="" />
                                </div>
                            </div>
                        ) : (
                            // If fields have values, enable the div
                            <div className="bd__submit" onClick={() => {
                                handleBasicDetails();
                                // handleClickOpen()
                            }}>
                                <div>
                                    <p className="btn_schedule">Schedule</p>
                                    <img className="imgBtn" src="./assets/maki_arrow.png" alt="" />
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
};

export default PaidBasicDetails;

const Items = [
    "Clear path",
    "Solve doubts",
    "Connections",
    "Investor & Referrals",
    "Social Impact",
    "Legal Terms",
    "Consultation & End to End Support",
];

const Items1 = [
    "Upto 1 months",
    "Upto 6 months",
    "Upto 1 year",
];