import React, { useState } from 'react'
import CalenderTest from "../BookFreeTrial/CalenderTest";
import CloseIcon from '@mui/icons-material/Close';
import './RescheduleModal.css'
import { DataState } from '../../context/DataProvider';
import API_URL from '../../api/Api';
import axios from 'axios';
import toast from 'react-hot-toast';


const RescheduleModal = ({ onClose, mentorUserId, idWhichReschedule }) => {
    const [selectedTime, setSelectedTime] = useState()
    const { accessToken } = DataState();

    const saveNewSlot = async () => {
        try {
            if (!selectedTime || !idWhichReschedule) {
                toast.error('Select any schedule to save');
                return;
            }
            // const response = await axios.patch(`${API_URL}/api/bookMentorSlot/reschedule?availableSlotId=${selectedTime}&bookingId=${idWhichReschedule}`
            const response = await axios.patch(`${API_URL}/api/session/reschedule?availableSlotId=${selectedTime}&bookingId=${idWhichReschedule}`
            , {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            toast.success('Your session is rescheduled');
            onClose()
        } catch (error) {
            console.log(error);
            toast.error('Error while rescheduling');
        }
    };

    return (
        <>
            <div className='r_modal__container'>
                <div className="r_modal__main">
                    <h1>Reschedule:
                        <span onClick={() => { onClose() }} >
                            <CloseIcon fontSize='4rem' color='white' />
                        </span>
                    </h1>
                    <div>
                        <div className='cd__cal'>
                            <CalenderTest userId={mentorUserId} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
                        </div>

                    </div>
                    <div className='r_modal__save' onClick={saveNewSlot}>
                        <div>
                            Save
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RescheduleModal