import React, { useEffect, useState } from 'react'
import './CommentCard.css'
import axios from 'axios';
import API_URL from '../../api/Api';
import { Avatar } from '@mui/material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { format } from 'date-fns';
import { DataState } from '../../context/DataProvider';

const CommentCard = ({ com }) => {

    const [userData, setUserData] = useState(null);
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/particular-user-data/${com.user}`);
                setUserData(response.data.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [com.user]);

    return (
        <div className='ccard__main'>
            <div className="ccard__name">
                <Avatar src={userData?.picture} sx={{ width: '3.4rem', height: '3.4rem' }} />
                <p>Answered by {userData?.name}</p>
            </div>
            <div className="ccard__ans">
                <p>{com.message}</p>
            </div>
        </div>
    )
}

export default CommentCard