import React, { useEffect, useState } from "react";
import "./Events.css";
import { EventsCard, Footer, ScrollTop } from "../../components";
import API_URL from "../../api/Api";
import axios from "axios";
import Navbar2 from "../../components/Navbar2";
import PastEventCard from "../../components/Events/PastEventCard";
import EventCardLoading from "../../components/Events/EventCardLoadng";
import CreateIcon from "@mui/icons-material/Create";
import CreateEvent from "./CreateEvent";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const storedRole = localStorage.getItem("selectedRole");

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/events/getFuture`);
      setEvents(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchEvents();
  },[open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="event__container-main">
        <div className="event__container">
          <div className="event__body">
            <div className="events">
              <Navbar2 />
              <CreateEvent
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
              />
              <div className="events__head">
                <div>
                  <h1>Upskill Yourself</h1>
                  <p>
                    with live group events from top
                    <br />
                    mentors at inQbator
                  </p>
                  {storedRole === "mentor" && (
                    <button
                      onClick={handleClickOpen}
                      className="events__create"
                    >
                      Create Event
                      <CreateIcon />
                    </button>
                  )}
                </div>
              </div>

              <div className="events__content">
                <h1>Mentor Events</h1>
                {loading ? (
                  <div className="events__card-container">
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                  </div>
                ) : (
                  <div className="events__card-container">
                    {events?.map((event) => (
                      <EventsCard key={event._id} event={event} />
                    ))}
                  </div>
                )}
                {/* <h1>Past Group Session</h1>
                {loading ? (
                  <div className="events__card-container">
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                    <EventCardLoading />
                  </div>
                ) : (
                  <div className="events__card-container">
                    {events?.map((event) => (
                      <PastEventCard key={event._id} event={event} />
                    ))}
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollTop />
    </>
  );
};

export default Events;
