import React, { useEffect, useRef, useState } from "react";
import "./css/StartupRegStep3.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import API_URL from "../../api/Api";
import DropDown from "../../components/miscellenius/DropDown";
import DragDrop from "../../components/miscellenius/DragDrop";
import SelectImage from "../../components/miscellenius/SelectImage";
import { DataState } from "../../context/DataProvider";

const StartupRegStep3 = ({ onPrevious, onNext, selectedAreas }) => {
  const navigate = useNavigate();
  const [startupName, setStartupName] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [code, setCode] = useState("");
  const [country, setCountry] = useState("");
  const [cofounderName, setCofounderName] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedStage, setSelectedStage] = useState([]);
  const [selectedExpectation, setSelectedExpectation] = useState([]);
  const [selectedImageDivs, setSelectedImageDivs] = useState([]);
  const [description, setDescription] = useState("");
  const [legal, setLegal] = useState(false);
  const { continueFormID, setContinueForDataState } = DataState();

  //uploaded file

  const [uploadedFile, setUploadedFile] = useState(null);

  const handleSubmit = async () => {
    // Create an object with all the field information\

    console.log("uploadedFile", uploadedFile);
    const startupDetails = {
      startupName: startupName,
      startupAreas: selectedAreas,
      startupWebsite: websiteURL,
      startupServices: selectedServices,
      startupMentorshipArea: selectedArea,
      startupDescription: description,
      startupBusinessPlan: uploadedFile,
      startupStage: selectedImageDivs,
      startupFundingStage: selectedStage,
      startupExpectationFromInQbator: selectedExpectation,
      startupCoFounderName: cofounderName,
      startupCoFounderEmail: email,
      startupCity: city,
      startupState: state,
      startupPostalcode: code,
      startupCountry: country,
      isStartupRegistered: legal,
    };
    console.log(startupDetails);

    try {
      // Make a POST request to the backend endpoint
      const response = await axios.patch(
        `${API_URL}/api/freementorshipForm/update/${continueFormID}`,
        { startupDetails }
      );
      console.log(response);
      // Handle the response, such as showing a success message
      if (response.data.ok) {
        navigate("/success");
      } else {
        alert("Error");
      }
      console.log("Response from server:", response.data);
    } catch (error) {
      // Handle error, such as displaying an error message
      console.error("Error submitting form:", error);
    }
  };
  return (
    <div className="startup__main">
      <div className="startup">
        <div className="startup__head">

          {/* <div> */}
            <h1>Join Wishlist</h1>
          {/* </div> */}
          <img src="./assets/main-logo-right.svg" alt="" />
        </div> 

        <p>
          inQbator is India’s fast-growing startup founders community that works
          intensively with startups in each phase of their journey.
        </p>
        <div className="startup__step">
          <img src="./assets/stepper-img.svg" alt="" />
        </div>
        <h1>Explain your Idea or Startup Ideation</h1>
        <p>
          It's never too late or too early to start up with us. If you are in
          the ideating phase, we just need the Name, Description & Solution. The
          more you share, the better we build!
        </p>
        <div className="startup__form">
          <div className="startup__formcol">
            <div className="startup__form-margin">
              <h1>Name*</h1>
              <input
                type="text"
                placeholder="Name of your Startup"
                value={startupName}
                onChange={(e) => setStartupName(e.target.value)}
              />{" "}
            </div>
            <div>
              <h1>Startup or Project Website?</h1>
              <input
                type="text"
                placeholder="Startup Website URL if you have one*"
                value={websiteURL}
                onChange={(e) => setWebsiteURL(e.target.value)}
              />
            </div>
          </div>
          <div className="startup__formcol">
            <div className="startup__form-margin">
              <h1>What Other Services You Need?</h1>
              <DropDown
                title={"Our Services"}
                options={servicesOptions}
                selectedOptions={selectedServices}
                onSelect={setSelectedServices}
              />
            </div>
            <div>
              <h1>For Which Area Do You Need Mentorship?</h1>
              <DropDown
                title={"Mentorship Area"}
                options={areaOptions}
                selectedOptions={selectedArea}
                onSelect={setSelectedArea}
              />
            </div>
          </div>
          <div className="startup__formcol">
            <div>
              <h1>Provide a Description of Your Startup or Project.*</h1>
              <textarea
                type="text"
                placeholder="Provide us with your Business Plan, Solution, Value Proposition, target market, & Product differentiation."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="startup__formcol">
            <div>
              <h1>Uploading Business / Financial Plan</h1>
              <DragDrop
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
              />
            </div>
          </div>
        </div>
        <h1>Provide us with a Few More Details</h1>
        <p>
          Every business grows at a different pace. Help us understand where you
          are right now in your journey. What development stage best describes
          your startup or project?
        </p>
        <div className="startup__mid">
          <SelectImage
            selected={selectedImageDivs}
            setSelected={setSelectedImageDivs}
          />
        </div>
        <div className="startup__form">
          <div className="startup__formcol">
            <div className="startup__form-margin">
              <h1>Stage of Funding? If Done</h1>
              <DropDown
                title={"No Funding / Self Funded"}
                options={stageOptions}
                selectedOptions={selectedStage}
                onSelect={setSelectedStage}
              />
            </div>
            <div>
              <h1>What Is Your Expectation from InQbator?</h1>
              <DropDown
                title={"Select Your Expectation"}
                options={expectationOptions}
                selectedOptions={selectedExpectation}
                onSelect={setSelectedExpectation}
              />
            </div>
          </div>
          <div className="startup__formcol">
            <div className="startup__form-margin">
              <h1>
                Share Any Other Co-Founder Partners Affiliated with Your
                Business Concept.
              </h1>
              <input
                type="text"
                placeholder="Enter the name of your partner"
                value={cofounderName}
                onChange={(e) => setCofounderName(e.target.value)}
              />
            </div>
            <div>
              <h1>Email Address of Your Co-Founder</h1>

              <input
                type="email"
                placeholder="Enter a valid email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="startup__formcol">
            <div className="startup__form-margin">
              <h1>City*</h1>
              <input
                type="text"
                placeholder="Enter your city*"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div>
              <h1>State or Province*</h1>
              <input
                type="text"
                placeholder="Enter your state or province*"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
          </div>
          <div className="startup__formcol">
            <div className="startup__form-margin">
              <h1>Postal Code*</h1>
              <input
                type="text"
                placeholder="Enter your Postal code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <div>
              <h1>Country*</h1>
              <input
                type="text"
                placeholder="Enter your country*"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
          </div>
          <label className="startup__checkbox-container">
            Is your Startup registered legally?
            <input
              type="checkbox"
              checked={legal}
              onChange={(e) => setLegal(e.target.checked)}
            />
            <span className="startup__checkbox-checkmark"></span>
          </label>
        </div>
        <div className="startup__submit" onClick={handleSubmit}>
          Submit Proposal
        </div>
        <div className="startup__bottom-img">
          <img src="./assets/inQbator-text.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default StartupRegStep3;

const servicesOptions = [
  "Mentorship",
  "StartUp India Registration",
  "Web & App Developement",
  "Incubation",
  "Funding and VC / Angel Pitch",
];

const areaOptions = [
  "Idea Validation",
  "Market Research  &  Analysis",
  "Funding & Finance",
  "Legal & Regulatory Compliances",
  "Team Building & HR",
  "Product Development",
  "Marketing & Sales",
  "Business Strategy",
  "Networking & Connection",
  "Technology & Security",
];
const stageOptions = [
  "No funding / Self funded",
  "Seed funded",
  "Angel funded",
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Series D+",
];
const expectationOptions = [
  "Mentorship",
  "Investement",
  "Networking",
  "Advertising",
];
