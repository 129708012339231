import React, { useState, useEffect, useRef } from 'react';
import './SendQuestions.css'
import { QuestionState } from '../../context/QuestionProvider';
import { DataState } from '../../context/DataProvider';
import { Avatar, Backdrop, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import API_URL from '../../api/Api';
import toast, { Toaster } from 'react-hot-toast';

const CategoryChips = ({ categories, selectedCategories, setSelectedCategories }) => {
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        setFilteredCategories(categories.slice(0, showMore ? categories.length : 10));
    }, [categories, showMore]);

    const handleCategoryClick = (categoryId) => {
        if (selectedCategories.includes(categoryId)) {
            setSelectedCategories(selectedCategories.filter((id) => id !== categoryId));
        } else {
            setSelectedCategories([...selectedCategories, categoryId]);
        }
    };

    const handleSearch = (e) => {
        const searchText = e.target.value.toLowerCase();
        setSearchText(searchText);

        // Filter categories based on search text
        const filtered = categories.filter((category) =>
            category.name.toLowerCase().includes(searchText)
        );

        setFilteredCategories(filtered.slice(0, 10)); // Show up to 10 results
    };

    const clearInput = () => {
        setSearchText('');
        setFilteredCategories(categories.slice(0, showMore ? categories.length : 10));
    };

    const handleShowMoreClick = () => {
        setShowMore(!showMore);
        if (!showMore) {
            setFilteredCategories(categories); // Show all categories
        } else {
            setFilteredCategories(categories.slice(0, 10)); // Show only the first 10 categories
        }
    };

    return (
        <div className="sendQ__category-chips-container">

            {/* Search input */}
            <h2>Select your question's domain</h2>
            <div className="sendQ__search-input-container">
                <div>
                    <SearchIcon sx={{ fontSize: '1.8rem' }} />
                </div>
                <input
                    type="text"
                    placeholder="Search categories"
                    value={searchText}
                    onChange={handleSearch}
                />
                {searchText && (
                    <button onClick={clearInput}>
                        &#x2715;
                    </button>
                )}
            </div>

            {/* Selected categories */}
            {selectedCategories && <div className="sendQ__category-list">
                {selectedCategories.map((selectedCategory) => (
                    <div key={selectedCategory._id} className="sendQ__chip selected">
                        {selectedCategory.name}
                        <button
                            onClick={() => {
                                setSelectedCategories(selectedCategories.filter((category) => category !== selectedCategory));
                            }}
                        >

                            &#x2715;
                        </button>
                    </div>
                ))}
            </div>}

            {/* List of categories */}
            <div className="sendQ__category-list">
                {filteredCategories.map((category) => (
                    <div
                        key={category._id}
                        className={`sendQ__chip ${selectedCategories.includes(category) ? 'selected' : ''}`}
                        onClick={() => handleCategoryClick(category)}
                    >
                        <img src={category.image} alt={category.name} />
                        {category.name}
                    </div>
                ))}
                <span onClick={handleShowMoreClick} >
                    {showMore ? 'show Less' : '+ more'}
                </span>
            </div>
        </div>
    );
};

const SendQuestions = ({ onClose }) => {
    const {
        category,
    }= QuestionState();
    const { user, accessToken } = DataState();

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [question, setQuestion] = useState('');
    const [questionSendLoading, setQuestionSendLoading] = useState(false);

    const handleAskQuestion = async () => {
        try {
            if (!question || (!selectedCategories.length === 0)) {
                // toast('Fill all fields',
                //     {
                //         style: {
                //             borderRadius: '10px',
                //             background: '#333',
                //             color: '#fff',
                //         },
                //     }
                // )
                toast.error('Fill all fields')
                return;
            }

            const arrayOfIds = selectedCategories.map(item => item._id);
            setQuestionSendLoading(true)

            const response = await axios.post(
                `${API_URL}/api/question`,
                {
                    question,
                    categories: arrayOfIds,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            if (response) {
                setQuestionSendLoading(false)
                toast.success('Question sent successfully')
                onClose()
            }
        } catch (error) {
            setQuestionSendLoading(false)
            toast.error('Error sending question')
            console.error('Error:', error.response.data);
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={questionSendLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="sendQ__container">
                <div className="sendQ__main">
                    <div className="sendQ__close" onClick={onClose}>
                        <CloseIcon sx={{ color: 'white', fontSize: '2rem' }} />
                    </div>
                    <h1>Send Questions</h1>
                    <CategoryChips categories={category} selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} />

                    <div className="sendQ__box">
                        <div className="sendQ__box-text">
                            <Avatar src={user?.picture} sx={{ width: '4rem', height: '4rem' }} />
                            <p>asking by {user?.name}</p>
                        </div>
                        <textarea
                            type="text"
                            placeholder='Ask any questions...'
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                        />
                    </div>
                    <div className="sendQ__submit">
                        <span onClick={onClose}>Cancel</span>
                        <div onClick={handleAskQuestion}>Send Your Question</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SendQuestions