import React from "react";
import { Link } from "react-router-dom";
import "./css/ProfileRoadmap.css";

const ProfileRoadmap = ({ mentorData, checkFreeSessions }) => {
    return (
        <>
            <div className="profile__roadmap-main">
                <div className="profile__roadmap">
                    <div className="profile_roadmap-text">
                        <h1>
                            How does inQbator long term
                            <br />
                            Mentorship Work?
                        </h1>
                        {mentorData?.paidOptions === "true" ? (
                            <Link to={`/book-sessions?userId=${mentorData._id}`}>
                                <div className="profile__says-left-trial">
                                    Get Session<i className="fa-solid fa-play"></i>
                                </div>{" "}
                            </Link>
                        ) : (
                            <>
                                {checkFreeSessions?.freeSession ? (
                                    <Link to={`/book-free-sessions?userId=${mentorData._id}`}>
                                        <div className="profile__says-left-trial">
                                            free trial session<i className="fa-solid fa-play"></i>
                                        </div>{" "}
                                    </Link>
                                ) : (
                                    <Link to={`/book-sessions?userId=${mentorData._id}`}>
                                        <div className="profile__says-left-trial">
                                            Get Session<i className="fa-solid fa-play"></i>
                                        </div>{" "}
                                    </Link>
                                )
                                }
                            </>
                        )}
                    </div>
                    <div className="profile_roadmap-img">
                        <img src="./homePageImages/roadMapImage.svg" alt="" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileRoadmap;
