import React, { useEffect, useState } from 'react'
import './css/Post.css'
import { Avatar, IconButton } from '@mui/material'
import PublicIcon from '@mui/icons-material/Public';
import axios from 'axios';
import API_URL from '../../api/Api';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

const Post = ({ post }) => {
  const [userData, setUserData] = useState(null);
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);

  const handleLike = () => {
    if (liked) {
      setLikeCount(likeCount - 1);
    } else {
      setLikeCount(likeCount + 1);
    }
    setLiked(!liked);
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/infoById/${post.createdBy._id}`
        );
        setUserData(response.data.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [post.createdBy._id]);

  function getTimeDifference(postDate) {
    const currentDate = new Date();
    const postDateTime = new Date(postDate);
    const timeDifference = currentDate - postDateTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
      return `${seconds} sec`;
    } else if (minutes < 60) {
      return `${minutes}min`;
    } else if (hours < 24) {
      return `${hours}h`;
    } else if (days < 30) {
      return `${days}d`;
    } else if (months < 12) {
      return `${months}m`;
    } else {
      return `${years}y`;
    }
  }

  const timeAgo = getTimeDifference(post.createdAt);

  return (
    <div className='post__main'>
      <div className="post__head">
        <Avatar src={userData?.picture} sx={{ height: '4rem', width: '4rem' }} />
        <div className="post__head-text">
          <h3>{userData?.name}</h3>
          <div>
            <span>{timeAgo}.</span>
            <PublicIcon sx={{ color: '#a7a7a7', fontSize: '.9rem' }} />
          </div>
        </div>
      </div>
      <div className="post__des">
        {/* <p>{post.description}</p> */}
          {/* <h1 className="article-title">{article}</h1> */}
          <div className="article-content" dangerouslySetInnerHTML={{ __html: post.description.slice(0,1000) }}></div>
          {/* <p className="article-author">Written by: {article.author}</p> */}
      </div>
      {post.image && <div className="post__img">
        <img src={post.image} alt="" />
      </div>}
      <div className="post__likes-count">
        <p>{likeCount} Like</p>
      </div>
      <div className="post__likes">
        <div className="post__likes-item" onClick={handleLike}>
          {!liked ? <IconButton>
            <FavoriteBorderIcon sx={{ fontSize: "2.1rem", color: "white" }} />
          </IconButton> :
            <IconButton >
              <FavoriteIcon sx={{ fontSize: "2.1rem", color: "rgb(247, 50, 76)" }} />
            </IconButton>}
          <p>Like</p>
        </div>
        <div className="post__likes-item">
        </div>
      </div>
    </div>
  )
}

export default Post