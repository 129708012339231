import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./css/ProfileAbout.css";
import { Menu, MenuItem, Modal } from "@mui/material";
import ShowExperienceModal from "./ShowExperienceModal";
import ShowEducationModal from "./ShowEducationModal";

const ProfileAbout = ({ mentorData, checkFreeSessions }) => {
  //about
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //skills
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  //experiences
  // const [anchorEl3, setAnchorEl3] = React.useState(null);
  // const open3 = Boolean(anchorEl3);
  // const handleClick3 = (event) => {
  //   setAnchorEl3(event.currentTarget);
  // };
  // const handleClose3 = () => {
  //   setAnchorEl2(null);
  // };


  const [anchorElArray, setAnchorElArray] = React.useState(Array(mentorData?.experienceData?.length).fill(null));

  const handleClick3 = (event, index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = event.currentTarget;
    setAnchorElArray(newAnchorElArray);
  };

  const handleClose3 = (index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = null;
    setAnchorElArray(newAnchorElArray);
  };



  const [openExpModal, setOpenExpModal] = useState(false);
  const [openEduModal, setOpenEduModal] = useState(false);

  return (
    <>
      <div className="profile__aboutmain">
        <div className="profile__about">
          <div className="profile__about-left">
            <div className="profile__about-exp">
              <h1>Experience</h1>
              {mentorData?.experienceData?.slice(0, 2)?.map((exp, index) => (
                <div className="profile__about-exp-comp" key={index}>
                  <div className="profile__about-exp-comp-img">
                    <img src={exp.companyImage} alt="" />
                    <h2>{exp.companyName}</h2>
                  </div>
                  <div className="profile__about-exp-comp-text">
                    <h1>{exp.title}</h1>
                    <span>
                      {exp.startMonth} {exp.startYear} - {exp.endMonth} {exp.endYear}
                    </span>
                  </div>
                  <p>
                    {exp.description?.slice(0, 170)}
                    {exp.description.length > 170 && ". . . "}
                    {exp.description.length > 170 && (
                      <>
                        <span
                          onClick={(event) => handleClick3(event, index)}
                          style={{ cursor: 'pointer', fontWeight: 'bold' }}
                        >
                          more
                        </span>
                      </>
                    )}
                  </p>
                  {/* <Menu
                    anchorEl={anchorElArray[index]}
                    open={Boolean(anchorElArray[index])}
                    onClose={() => handleClose3(index)}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        // overflow: 'visible',
                        // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        // border: '1px solid gray',
                        // mt: 1.5,
                        bgcolor: '#121212',
                      },
                    }}
                    transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    sx={{ padding: '0px' }}
                  >
                    <MenuItem onClick={() => handleClose3(index)} sx={{ padding: '0px', margin: "0" }}>
                      <div className="mc__about-menu-main">
                        <div className="mc__about-menu">
                          <p>{exp.description}</p>
                        </div>
                      </div>
                    </MenuItem>
                  </Menu> */}
                  <Menu
                    id="account-menu"
                    anchorEl={anchorElArray[index]}
                    open={Boolean(anchorElArray[index])}
                    onClose={() => handleClose3(index)}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "hidden",
                        bgcolor: "#121212",
                        maxHeight: "35rem",
                      },
                    }}
                    transformOrigin={{ horizontal: "center", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    sx={{ padding: "0px" }}
                  >
                    <MenuItem onClick={() => handleClose3(index)} sx={{ padding: "0px", margin: "0" }}>
                      <div className="mc__about-menu-main">
                        <div className="mc__about-menu">
                          <p>{exp.description}</p>
                        </div>
                      </div>
                    </MenuItem>
                  </Menu>
                </div>
              ))}
              {mentorData?.experienceData?.length > 2 && <button onClick={() => setOpenExpModal(true)}>Show all {mentorData?.experienceData?.length} experiences</button>}
              <Modal
                open={openExpModal}
                onClose={() => setOpenExpModal(false)}
              >
                <ShowExperienceModal
                  onClose={() => setOpenExpModal(false)}
                  experienceData={mentorData?.experienceData}
                />
              </Modal>
            </div>
            <div className="profile__edu profile__about-exp">
              <h1>Education</h1>
              {mentorData?.education?.slice(0, 2)?.map((edu, index) => (
                <div className="profile__about-exp-comp" key={index}>
                  <div className="profile__about-exp-comp-img">
                    <img src={edu.schoolImage} alt="" />
                    <h2>{edu.school}</h2>
                  </div>
                  <div className="profile__about-exp-comp-text">
                    <h1>
                      {edu.degree} {edu.fieldOfStudy.length === 0 && "-"} {edu.fieldOfStudy}
                    </h1>
                    <span>
                      {edu.startYear} - {edu.endYear}
                    </span>
                  </div>
                </div>
              ))}
              {mentorData?.education.length > 2 && <button onClick={() => setOpenEduModal(true)}>Show all {mentorData?.education?.length} educations</button>}
              <Modal
                open={openEduModal}
                onClose={() => setOpenEduModal(false)}
              >
                <ShowEducationModal
                  onClose={() => setOpenEduModal(false)}
                  eduData={mentorData?.education}
                />
              </Modal>
            </div>
          </div>

          <div className="profile__about-right">
            <div className="profile__about-right-desc">
              <h2>About</h2>
              <h1>{mentorData?.user?.name}</h1>
              <p>
                {mentorData?.about[0]?.about?.slice(0, 400)}
                {mentorData?.about[0]?.about?.length > 400 && ". . ."}
                {mentorData?.about[0]?.about?.length > 400 && (
                  <span onClick={handleClick}>more</span>
                )}
              </p>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "hidden",
                    // border: "1px solid gray",
                    bgcolor: "#121212",
                    maxHeight: "35rem",
                  },
                }}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                sx={{ padding: "0px" }}
              >
                <MenuItem onClick={handleClose} sx={{ padding: "0px", margin: "0" }}>
                  <div className="mc__about-menu-main">
                    <div className="mc__about-menu">
                      <p>{mentorData?.about[0]?.about}</p>
                    </div>
                  </div>
                </MenuItem>
              </Menu>
              <div className="profile__about-skills">
                <div className="profile__about-skills-head">
                  <img src="./assets/mentorlist-book.svg" alt="" />
                  <h1>Skills & Experties</h1>
                </div>
                <div>
                  {mentorData?.skills[0]?.skills?.slice(0, 12)?.map((skill) => (
                    <div key={skill}>{skill}</div>
                  ))}
                  {mentorData?.skills[0]?.skills?.length > 12 && (
                    <span onClick={handleClick2}>more</span>
                  )}

                  <Menu
                    anchorEl={anchorEl2}
                    id="account-menu2"
                    open={open2}
                    onClose={handleClose2}
                    onClick={handleClose2}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        bgcolor: "#121212",
                      },
                    }}
                    transformOrigin={{ horizontal: "center", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    sx={{ padding: "0px" }}
                  >
                    <MenuItem onClick={handleClose} sx={{ padding: "0px" }}>
                      <div className="mc__about-menu-main">
                        <div className="mc__about-menu">
                          <div className="profile__about-skills">
                            <div>
                              {mentorData?.skills[0]?.skills
                                .map((skill) => (
                                  <div key={skill}>{skill}</div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>

            {mentorData?.paidOptions === "false" && <>
              {checkFreeSessions?.freeSession &&
                <div className="profile__about-right-trial">
                  <div className="profile__about-right-trial-float">
                    Trial Session
                  </div>
                  <p>
                    Gain a clear understanding of how the mentor intends to
                    provide personal mentorship & guidance to help you accomplish
                    your goals .
                  </p>
                  <Link to={`/book-free-sessions?userId=${mentorData._id}`}>
                    <div className="profile__about-right-trial-btn">
                      <div>
                        Book a Free Trial Session
                        <i className="fa-solid fa-arrow-right" />
                      </div>
                    </div>{" "}
                  </Link>
                </div>
              }
            </>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileAbout;
