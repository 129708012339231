import React, { useState, useEffect, useRef } from 'react';
import './EditIntro.css';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, IconButton } from '@mui/material';
import axios from 'axios';
import API_URL from '../../../api/Api';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';

const MultiSelectDropDown = ({ options, selectedOptions, onSelect, title }) => {
    const [inputValue, setInputValue] = useState("");
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const dropdownRef = useRef(null);

    useEffect(() => {
        // Attach a click event listener to the document to close the dropdown when clicking outside
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const handleOptionClick = (option) => {
        if (!selectedOptions.includes(option)) {
            onSelect((prevSelected) => [...prevSelected, option]);
        } else {
            onSelect((prevSelected) =>
                prevSelected.filter((item) => item !== option)
            );
        }
        setInputValue("");
    };

    const toggleDropdown = () => {
        setDropdownVisible((prevVisible) => !prevVisible);
    };

    return (
        <div className="editIntro__custom-dropdown" ref={dropdownRef}>
            <div className="editIntro__chips-container" onClick={toggleDropdown}>
                {selectedOptions.map((option) => (
                    <div key={option} className="startup__chip">
                        {option}
                        <span
                            className="startup__remove-icon"
                            onClick={() => handleOptionClick(option)}
                        >
                            &times;
                        </span>
                    </div>
                ))}
                {selectedOptions.length === 0 && <span>{title}</span>}
            </div>
            <div
                className={`startup__dropdown-arrow ${dropdownVisible ? "open" : ""} ${selectedOptions.length > 0 ? "selected" : ""
                    }`}
                onClick={toggleDropdown}
            >
                &#9650;
            </div>
            {dropdownVisible && (
                <div className="startup__options-list">
                    {options.map((option) => (
                        <div
                            key={option}
                            className={`startup__option ${selectedOptions.includes(option)
                                ? "startup__option_selected"
                                : ""
                                }`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                            {selectedOptions.includes(option) && (
                                <span className="startup__tick">✓</span>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const SingleSelectDropDown = ({ options, selectedOptions, onSelect, title }) => {
    const [inputValue, setInputValue] = useState("");
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const dropdownRef = useRef(null);

    useEffect(() => {
        // Attach a click event listener to the document to close the dropdown when clicking outside
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    // const handleOptionClick = (option) => {
    //     if (!selectedOptions.includes(option)) {
    //         onSelect((prevSelected) => [...prevSelected, option]);
    //     } else {
    //         onSelect((prevSelected) =>
    //             prevSelected.filter((item) => item !== option)
    //         );
    //     }
    //     setInputValue("");
    // };
    const handleOptionClick = (option) => {
        if (selectedOptions.length === 1 && selectedOptions.includes(option)) {
            // If the selected option is the only one selected, deselect it
            onSelect([]);
        } else {
            // Otherwise, select the clicked option
            onSelect([option]);
        }
    };



    const toggleDropdown = () => {
        setDropdownVisible((prevVisible) => !prevVisible);
    };

    return (
        <div className="editIntro__custom-dropdown" ref={dropdownRef}>
            <div className="editIntro__chips-container" onClick={toggleDropdown}>
                {selectedOptions.map((option) => (
                    <div key={option} className="startup__chip">
                        {option}
                        <span
                            className="startup__remove-icon"
                            onClick={() => handleOptionClick(option)}
                        >
                            &times;
                        </span>
                    </div>
                ))}
                {selectedOptions.length === 0 && <span>{title}</span>}
            </div>
            <div
                className={`startup__dropdown-arrow ${dropdownVisible ? "open" : ""} ${selectedOptions.length > 0 ? "selected" : ""
                    }`}
                onClick={toggleDropdown}
            >
                &#9650;
            </div>
            {dropdownVisible && (
                <div className="startup__options-list">
                    {options.map((option) => (
                        <div
                            key={option}
                            className={`startup__option ${selectedOptions.includes(option)
                                ? "startup__option_selected"
                                : ""
                                }`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                            {selectedOptions.includes(option) && (
                                <span className="startup__tick">✓</span>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const StartupRegStep2 = ({ areaList, selectedAreas, setSelectedAreas }) => {
    const [inputValue, setInputValue] = useState("");

    const handleChipClick = (area) => {
        if (selectedAreas.length < 5 && !selectedAreas.includes(area)) {
            setSelectedAreas((prevSelected) => [...prevSelected, area]);
        } else if (selectedAreas.length >= 5) {
            window.alert("You can select up to 5 items.");
        }
    };

    const handleChipRemove = (area) => {
        setSelectedAreas((prevSelected) => prevSelected.filter((c) => c !== area));
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleInputKeyPress = (event) => {
        if (event.key === "Enter" && inputValue.trim() !== "") {
            const area = inputValue.trim();
            if (selectedAreas.length < 5 && !selectedAreas.includes(area)) {
                setSelectedAreas((prevSelected) => [...prevSelected, area]);
                setInputValue("");
            } else if (selectedAreas.length >= 5) {
                window.alert("You can select up to 5 items.");
            }
        }
    };

    return (

        <div className="personalStep2__form" style={{marginTop: 0}}>
            <h1>Target Sectors*</h1>

            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleInputKeyPress}
                placeholder="Include you own"
            />

            <div className="personalStep2__chip-container">
                {selectedAreas.length > 0 && <h2>Selcted target sectors:</h2>}

                {selectedAreas.map((area) => (
                    <>
                        <div key={area} className="personalStep2__chip selected">
                            {area}
                            <span
                                className="personalStep2__close-icon"
                                onClick={() => handleChipRemove(area)}
                            >
                                &times;
                            </span>
                        </div>
                    </>
                ))}
            </div>
            <div className="personalStep2__area-list">
                <h2>Some top target sectors: </h2>
                {areaList.map((area) => (
                    <div
                        key={area}
                        className={`personalStep2__chip ${selectedAreas.includes(area) ? "selected" : ""
                            }`}
                        onClick={
                            selectedAreas.includes(area) ?
                                () => handleChipRemove(area)
                                :
                                () => handleChipClick(area)
                        }
                    >
                        {area}
                    </div>
                ))}
            </div>
        </div>
    );
};

const EditIntro = ({ intro, onClose, user }) => {
    const [introductionData, setIntroductionData] = useState({ ...intro });
    const [userData, setUserData] = useState({ ...user });
    const [sendLoading, setSendLoading] = useState(false);

    const [selectedArea, setSelectedArea] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);

    console.log(selectedCategory)

    useEffect(() => {
        if (intro?.areaOfMentorship.length > 0) {
            setSelectedArea(intro.areaOfMentorship)
        }
        if (intro?.targetSector.length > 0) {
            setSelectedCategory(intro.targetSector)
        }
    }, [])


    function useDisplayImage() {

        const [result, setResult] = useState(false);

        function uploader(e) {
            const imageFile = e.target.files[0];

            const reader = new FileReader();
            reader.addEventListener('load', (e) => {
                setResult(e.target.result);
            });
            reader.readAsDataURL(imageFile);
        }
        return { result, uploader };
    }

    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);

    const { result: result1, uploader: uploader1 } = useDisplayImage();
    const { result: result2, uploader: uploader2 } = useDisplayImage();

    const handleImage1Change = (e) => {
        uploader1(e);
        setImage1(e.target.files[0]);
    };

    const handleImage2Change = (e) => {
        uploader2(e);
        setImage2(e.target.files[0]);
    };

    const handleUserInputChange = (event, fieldName) => {
        const { value } = event.target;
        setUserData((prevIntroData) => ({
            ...prevIntroData,
            [fieldName]: value,
        }));
    };

    const handleInputChange = (event, fieldName) => {
        const { value } = event.target;
        setIntroductionData((prevIntroData) => ({
            ...prevIntroData,
            [fieldName]: value,
        }));
    };

    const handleRemunerationSelection = (type) => {
        setIntroductionData((prevIntroData) => ({
            ...prevIntroData,
            paidOptions: type,
        }));
    };

    const handleSave = async () => {
        try {
            const newAvatar1 = result1 ? result1 : user?.picture;
            const newAvatar2 = result2 ? result2 : introductionData?.companyImage;
            if (
                !userData.name ||
                !introductionData.companyName ||
                !introductionData.jobRole ||
                !newAvatar2 ||
                !introductionData.experience ||
                !introductionData.sessions ||
                !introductionData.followup ||
                !introductionData.mobileNumber ||
                !selectedArea ||
                !selectedCategory ||
                !introductionData.paidOptions ||
                !introductionData.remunerationValue
            ) {
                toast.error('Please fill all required fields.');
                return;
            }
            setSendLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                },
            };

            const response = await axios.post(
                `${API_URL}/api/mentor/addmentor`,
                {
                    name: userData.name,
                    picture: newAvatar1,
                    companyName: introductionData.companyName,
                    jobRole: introductionData.jobRole,
                    location: introductionData.location,
                    companyImage: newAvatar2,
                    experience: introductionData.experience,
                    sessions: introductionData.sessions,
                    followup: introductionData.followup,
                    mobileNumber: introductionData.mobileNumber,
                    alternateMobileNumber: introductionData.alternateMobileNumber,
                    areaOfMentorship: selectedArea,
                    targetSector: selectedCategory,
                    remunerationType: "price",
                    // remunerationType: introductionData.remunerationType,
                    paidOptions: introductionData.paidOptions,
                    remunerationValue: introductionData.remunerationValue,
                },
                config
            );

            if (response.status === 202) {
                window.location.reload();
                setSendLoading(false)
                onClose(); // Close the modal
            } else {
                console.log('Failed to update education');
                setSendLoading(false)
            }
        } catch (error) {
            console.log('Error updating education:', error);
            setSendLoading(false)
        }
    };

    const getCategoryOptions = () => {
        if (selectedArea[0] === "Career Mentorship") {
            return [
                "AI/ML",
                "Backend",
                "Business",
                "Cloud",
                "Consulting",
                "Cyber Security",
                "Data Science",
                "Design",
                "Finance",
                "Frontend",
                "Full Stack",
                "Game Development",
                "Human Resource",
                "IOT",
                "Job",
                "Management",
                "Marketing",
                "Network",
                "Operations",
                "Product",
                "Project Management",
                "Prompt Engineering",
                "R&D",
                "Research & Management",
                "Sales"

            ];
        } else if (selectedArea[0] === "Startup Mentorship") {
            return [
                "AI/ML",
                "Agriculture",
                "Automobile",
                "B2B",
                "B2C",
                "BioTech",
                "D2C",
                "E-commerce",
                "Ed Tech",
                "Fashion Tech",
                "Fin Tech",
                "Food Tech",
                "Gaming",
                "Green/Clean Tech",
                "Health Tech",
                "Insurance Tech",
                "Manufacturing",
                "Marketing/Media",
                "NGO",
                "Proptech",
                "SAAS",
                "Sports",
                "Tech",
                "Transportation Tech",
                "Travel Tech",
                "Wealth Tech",
            ];
        } else {
            return [
                "Networking",
                "Cybersecurity",
                "Cloud Computing",
                "Software Development",
                "Web Development",
                "Database Management",
                "Artificial Intelligence (AI)",
                "Machine Learning (ML)",
                "Internet of Things (IoT)",
                "Data Science",
                "DevOps",
                "Mobile App Development",
                "Virtual Reality (VR)",
                "Augmented Reality (AR)",
                "Blockchain Technology",
                "IT Project Management",
                "System Administration",
                "Computer Graphics",
                "Embedded Systems",
                "Robotics",
                "Bioinformatics",
                "E-commerce Solutions",
                "User Experience (UX) Design",
                "Quality Assurance (QA) and Testing",
                "Geographic Information Systems (GIS)",
                "Network Administration",
                "Quantum Computing",
                "IT Consulting",
                "IT Governance",
                "Knowledge Management Systems"
            ];
        }
    };

    const categoryOptions = getCategoryOptions();


    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={sendLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='editIntro__container'>
                <div className="editIntro__main">
                    <h1>Introduction:
                        <span onClick={() => { onClose() }} >
                            <CloseIcon fontSize='4rem' color='white' />
                        </span>
                    </h1>

                    <div className='editInto__image'>
                        <div>
                            {result1 ? (
                                <Avatar src={result1} sx={{ width: '16rem', height: '16rem' }}></Avatar>
                            ) : (
                                <Avatar src={user?.picture} sx={{ width: '16rem', height: '16rem' }}></Avatar>
                            )}
                        </div>
                        <p>Prefer size "500x450px"</p>
                    </div>
                    <div className='editIntro__avatar-upload'>
                        {!result1 ? (
                            <div className='editIntro__avatar-input'>
                                Select Image
                                <input type='file' name='image' onChange={handleImage1Change} />
                            </div>
                        ) : (
                            <div className='editIntro__avatar-input'>
                                Change Image
                                <input type='file' name='image' onChange={handleImage1Change} />
                            </div>
                        )}

                    </div>
                    <div>

                        <div className='editIntro__formcol'>
                            <div>
                                <h1>Full Name*</h1>
                                <input
                                    type="text"
                                    placeholder='Enter your full name'
                                    value={userData?.name}
                                    onChange={(e) => handleUserInputChange(e, 'name')}
                                />
                            </div>
                        </div>

                        <div className='editIntro__formcol'>
                            <div className='editIntro__form-margin'>
                                <h1>Company name*</h1>
                                <input
                                    type="text"
                                    placeholder='Company where you work'
                                    value={introductionData.companyName}
                                    onChange={(e) => handleInputChange(e, 'companyName')}
                                />
                            </div>
                            <div>
                                <h1>Your role in company*</h1>
                                <input
                                    type="text"
                                    placeholder='Your role in company'
                                    value={introductionData.jobRole}
                                    onChange={(e) => handleInputChange(e, 'jobRole')}

                                />
                            </div>
                        </div>

                        <div className='editIntro__formcol'>
                            <div className='editIntro__form-margin'>
                                <h1>Location of company</h1>
                                <input
                                    type="text"
                                    placeholder='Location of company'
                                    value={introductionData.location}
                                    onChange={(e) => handleInputChange(e, 'location')}
                                />
                            </div>
                            <div>
                                <h1>Company Image*</h1>
                                <div className='editIntro__upload'>
                                    <div className='editIntro__upload-img'>
                                        {!introductionData.companyImage ? 'Choose Image' : 'Change'}
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImage2Change}
                                        />
                                    </div>
                                    {!result2 ? <>
                                        {introductionData.companyImage &&
                                            <div className='editIntro__upload-viewimg'>
                                                <img src={introductionData.companyImage} alt="Uploaded" />
                                            </div>
                                        }
                                    </> : (
                                        <div className='editIntro__upload-viewimg'>
                                            <img src={result2} alt="Uploaded" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='editIntro__formcol'>
                            <div className='editIntro__form-margin'>
                                <h1>Total year of experience*</h1>
                                <input
                                    type="text"
                                    placeholder='Add your experience in number Ex: 8'
                                    value={introductionData.experience}
                                    onChange={(e) => handleInputChange(e, 'experience')}

                                />
                            </div>
                            <div className='editIntro__form-margin'>
                                <h1>Sessions per week*</h1>
                                <input
                                    type="text"
                                    placeholder='No. of sessions in week'
                                    value={introductionData.sessions}
                                    onChange={(e) => handleInputChange(e, 'sessions')}

                                />
                            </div>
                            <div>
                                <h1>Tasks & followup*</h1>
                                <input
                                    type="text"
                                    placeholder='In how many days you take followup'
                                    value={introductionData.followup}
                                    onChange={(e) => handleInputChange(e, 'followup')}

                                />
                            </div>
                        </div>

                        <div className='editIntro__formcol'>
                            <div className='editIntro__form-margin'>
                                <h1>Mobile number*</h1>
                                <input
                                    type="text"
                                    placeholder='Add Mobile number'
                                    value={introductionData.mobileNumber}
                                    onChange={(e) => handleInputChange(e, 'mobileNumber')}

                                />
                            </div>
                            <div>
                                <h1>Aleternate Mobile number</h1>
                                <input
                                    type="text"
                                    placeholder='Add alternate mobile number'
                                    value={introductionData.alternateMobileNumber}
                                    onChange={(e) => handleInputChange(e, 'alternateMobileNumber')}

                                />
                            </div>
                        </div>

                        <div className='editIntro__formcol'>
                            <div >
                                <h1>Area of Mentorship for*</h1>
                                <SingleSelectDropDown
                                    title={"Mentorship Area"}
                                    options={areaOptions}
                                    selectedOptions={selectedArea}
                                    onSelect={setSelectedArea}
                                />
                            </div>
                            {/* <div>
                                <h1>Target Sectors*</h1>
                                <MultiSelectDropDown
                                    title={"Target Sectors"}
                                    options={categoryOptions}
                                    selectedOptions={selectedCategory}
                                    onSelect={setSelectedCategory}
                                />
                            </div> */}
                        </div>

                        <div className='editIntro__formcol mb-[20px]'>
                            <div>
                                <StartupRegStep2
                                    areaList={categoryOptions}
                                    selectedAreas={selectedCategory}
                                    setSelectedAreas={setSelectedCategory}
                                />
                            </div>
                        </div>

                        <div className='editIntro__formcol'>
                            <div className='editIntro__form-margin'>
                                <h1>Is Only Paid Mentorship*</h1>
                                <div className='editIntro__roles-main editIntro__form-margin'>
                                    <div
                                        onClick={() => handleRemunerationSelection("true")}
                                        className={`editIntro__roles ${introductionData.paidOptions === "true" ? "selected" : ""}`}
                                    >
                                        True
                                    </div>
                                    <span>or</span>
                                    <div
                                        onClick={() => handleRemunerationSelection("false")}
                                        className={`editIntro__roles ${introductionData.paidOptions === "false" ? "selected" : ""}`}
                                    >
                                        False
                                    </div>

                                </div>
                            </div>


                            <div>
                                <h1>How Much Price*</h1>
                                <input
                                    type="text"
                                    placeholder={`Enter price ₹499'}`}
                                    value={introductionData.remunerationValue}
                                    onChange={(e) => handleInputChange(e, 'remunerationValue')}
                                />
                            </div>

                        </div>

                        <div className='editIntro__save'>
                            <div onClick={handleSave}>
                                Save
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditIntro;

const areaOptions = [
    "Career Mentorship",
    "Startup Mentorship",
    "Industry Specific expertise"
];
