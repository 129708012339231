import React from 'react'
import "./MentorList.css";
import Skeleton from '@mui/material/Skeleton';

const MentorcardLoading = () => {
    return (
        <>
            <div className='mc__main-loading'>
                <div className="mc__l-loading">
                    <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'75%'} />
                    <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'20%'} />
                </div>
                <div className="mc__m-loading">
                    <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'30%'} />
                    <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'30%'} />
                    <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'30%'} />
                </div>
                <div className="mc__r-loading">
                    <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'1000%'} />
                </div>
            </div>
        </>
    )
}

export default MentorcardLoading