import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const VideoMeeting = () => {
    const [roomCode, setRoomCode] = useState();
    const navigate = useNavigate();

    const handleFormSubmit = (e) => {
        e.preventDefault();
        navigate(`/room/${roomCode}`);
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen w-screen">
            <form className="flex flex-col items-center justify-center">
                <label className='text-2xl text-slate-900'>Enter Room Code</label>
                <input
                    type="text"
                    required
                    className='h-20 w-[55%] border-slate-900'
                    placeholder='Enter Room Code'
                    value={roomCode}
                    onChange={(e) => setRoomCode(e.target.value)}
                />
                <button type="button" onClick={handleFormSubmit}>Submit</button>
            </form>
        </div>
    )
}

export default VideoMeeting