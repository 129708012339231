import React, { useState } from "react";
import StartupRegStep1 from "./StartupRegStep1";
import StartupRegStep2 from "./StartupRegStep2";
import StartupRegStep3 from "./StartupRegStep3";
import "./css/StartupRegMain.css";
import { Link } from "react-router-dom";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";

const StartupRegMain = () => {
  const [activeFormStep, setActiveFormStep] = useState(1);
  const [selectedAreas, setSelectedAreas] = useState([]);

  const handleNext = () => {
    setActiveFormStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setActiveFormStep((prevStep) => prevStep - 1);
  };

  return (
    <>
      <div className="srm__wrapper-main">
        <div className='srm__wrapper'>
          {(activeFormStep === 1 || activeFormStep === 2) &&
            <div className="srm__body">
              <div className="srm__l">
                <Link to="/">
                  <div className="login__back">
                    <ArrowCircleLeftRoundedIcon sx={{ fontSize: "2rem" }} />
                    Home
                  </div>
                </Link>
                <div className="srm__l-content">
                  <h1>
                    GROW
                    <br />
                    YOUR STARTUP
                    <br />
                    WITH US!
                  </h1>
                  <img src="./assets/mentorFormImg.svg" alt="" loading="lazy" />
                </div>
              </div>

              <div className="srm__r">
                {activeFormStep === 1 &&
                  <StartupRegStep1
                    key={1}
                    onNext={handleNext}
                  />
                }
                {activeFormStep === 2 && (
                  <StartupRegStep2
                    key={2}
                    onNext={handleNext}
                    selectedAreas={selectedAreas}
                    setSelectedAreas={setSelectedAreas}
                    onPrevious={handlePrevious}
                  />
                )}
              </div>
            </div >}

          {activeFormStep === 3 && (
            <div className="srm__startup">
              <StartupRegStep3
                key={3}
                selectedAreas={selectedAreas}
                setSelectedAreas={setSelectedAreas}
                onPrevious={handlePrevious}
              />
            </div>
          )}
        </div >
      </div >
    </>
  );
};

export default StartupRegMain;
{/* <div className="srm__main">
                <div className="srm">
                  <Link to="/">
                    <div className="login__back">
                      <ArrowCircleLeftRoundedIcon sx={{ fontSize: "2rem" }} />
                      Home
                    </div>
                  </Link>
                  <div className="srm__left">
                    <div className="srm__left-main">
                      <h1>
                        GROW
                        <br />
                        YOUR STARTUP
                        <br />
                        WITH US!
                      </h1>
                      <img src="./assets/mentorFormImg.svg" alt="" loading="lazy" />
                    </div>
                  </div>
                  {activeFormStep === 1 &&
                    <StartupRegStep1
                      key={1}
                      onNext={handleNext}
                    />
                  }
                  {activeFormStep === 2 && (
                    <StartupRegStep2
                      key={2}
                      onNext={handleNext}
                      selectedAreas={selectedAreas}
                      setSelectedAreas={setSelectedAreas}
                      onPrevious={handlePrevious}
                    />
                  )}
                </div>
                <div className="srm__startup">
                  {activeFormStep === 3 && (
                    <StartupRegStep3
                      key={3}
                      selectedAreas={selectedAreas}
                      setSelectedAreas={setSelectedAreas}
                      onPrevious={handlePrevious}
                    />
                  )}
                </div>
              </div> */}