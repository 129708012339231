import React from 'react'
import "./EventsCard.css";
import { Skeleton } from '@mui/material';

const EventCardLoading = () => {

    return (
        <>
            <div className="eventcard-loading">
                <div>
                    <div className="eventcard__tophead-loading">
                        <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'70%'} height={'100%'} />
                    </div>
                    <div className="eventcard__bio-loading">
                        <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'100%'} />
                    </div>
                    <div className='eventcard__head-loading'>
                        <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'70%'} height={'100%'} />
                        <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'100%'} />
                    </div>
                </div>

                <div className="eventcard__btm">
                    <div className='eventcard__mentor-loading'>
                        <Skeleton variant="circular" sx={{ bgcolor: 'grey.900', minWidth: '50px' }} width={50} height={50} />
                        <div>
                            {/* <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'70%'} height={'100%'} /> */}
                            <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'100%'} />
                        </div>
                    </div>
                    <div className="eventcard__tophead-loading">
                        <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.900', borderRadius: '1rem' }} width={'100%'} height={'100%'} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventCardLoading