import { useState, useRef, useEffect } from "react";
import axios from "axios";
import API_URL from "./api/Api";
import { SSE } from "sse";

const Testing = () => {
  const [prompt, setPrompt] = useState("");
  const [isloading, setIsLoading] = useState(false);
  let [result, setResult] = useState("");

  const resultRef = useRef();

  useEffect(() => {
    resultRef.current = result;
  }, [result]);

  let handleSubmitPromptBtnClicked = async () => {
    if (prompt !== "") {
      setIsLoading(true);
      setResult("");
      let url = `http://localhost:5000/api/openai-stream`;
      let data = {
        query: prompt,
      };

      let source = new SSE(url, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        payload: JSON.stringify(data),
      });

      source.addEventListener("message", (e) => {
        if (e.data != "[DONE]") {
          let payload = JSON.parse(e.data);
          let { content } = payload.choices[0].delta || {};
          // console.log("payload: " + payload);

          if (content != undefined) {
            console.log("content: " + content);
            resultRef.current = resultRef.current + content;
            console.log("ResultRef.current: " + resultRef.current);
            setResult(resultRef.current);
          }
        } else {
          source.close();
        }
      });

      source.addEventListener("readystatechange", (e) => {
        if (e.readyState >= 2) {
          setIsLoading(false);
        }
      });

      source.stream();
    } else {
      alert("Please insert a prompt!");
    }
  };

  const [streamedData, setStreamedData] = useState("");

  //   const fetchData = async () => {
  //     const response = await axios.get(
  //       `http://localhost:5000/api/openai-stream?prompt=${prompt}`,
  //       {
  //         responseType: "stream",
  //       }
  //     );

  //     if (response.data) {
  //       console.log(response?.data?.choices[0]?.delta?.content);

  //       setStreamedData((prev) => prev + response.data.choices[0].delta.content);
  //     }
  //     // const reader = response.data.getReader();
  //     // const decoder = new TextDecoder("utf-8");
  //     // let result = "";

  //     // while (true) {
  //     //   const { done, value } = await reader.read();
  //     //   if (done) break;
  //     //   result += decoder.decode(value);
  //     //   setStreamedData((prev) => prev + decoder.decode(value));
  //     // }
  //   };

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">Streaming Formatted Text</h1>
        <input
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          className="border border-gray-600 bg-gray-800 text-white px-3 py-2 rounded-md mr-2 focus:outline-none"
          placeholder="Enter prompt"
        />
        <button
          // onClick={() => prompt.trim() ? handleSubmitPromptBtnClicked() : undefined}
          onClick={() => handleSubmitPromptBtnClicked()}
          type="submit"
          //   disabled={isloading}
          //   className={`bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none ${
          //     isloading ? "opacity-50 cursor-not-allowed" : ""
          //   }`}
          className={`bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none`}
        >
          {/* {isloading ? "Loading..." : "Submit"} */}
          Send
        </button>
        {result != "" && <code>{result}</code>}
      </div>
    </div>
  );
};

export default Testing;

// const [chat, setChat] = useState([]);
// const [chatHistory, setChatHistory] = useState([]);
// const [title, setTitle] = useState('');
// const handleSend2 = async () => {
//     if (input.trim) {
//         setChat([...chat, { role: 'user', content: input }]);
//         setInput('');
//         const response = await fetch(`${API_URL}/api/message/stream`,
//             {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify({
//                     messages: [
//                         ...chat,
//                         { role: 'user', content: input }
//                     ]
//                 })
//             })

//         //eslint-disable-next-line
//         const readData = response.body.pipeThrough(new TextDecoderStream()).getReader();

//         let aiRes = '';
//         while (true) {
//             const { done, value } = await readData.read();
//             if (done) {
//                 break;
//             }

//             aiRes += value;

//             // const resdata = JSON.parse(value);

//             setChat([...chat, { role: 'user', content: input }, { role: 'assistant', content: aiRes }])
//         }

//         // setChat([...chat, { role: 'user', content: input }, resdata?.choices[0]?.message]);
//     }
// }

// const handleSend = async () => {
//     if (input.trim) {
//         setChat([...chat, { role: 'user', content: input }]);
//         setInput('');
//         const response = await fetch(`${API_URL}/api/message/stream`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 messages: [
//                     ...chat,
//                     { role: 'user', content: input },
//                 ]
//             })
//         });

//         //eslint-disable-next-line
//         const readData = response.body.pipeThrough(new TextDecoderStream()).getReader();
//         let aiRes = '';
//         while (true) {
//             const { done, value } = await readData.read();
//             if (done) {
//                 break;
//             }
//             aiRes += value;
//             setChat([...chat, { role: 'user', content: input }, { role: 'assistant', content: aiRes }]);
//         }

//         // if (!title) {
//         //     const createTitle = await fetch('http://localhost:8000/api/title', {
//         //         method: 'POST',
//         //         headers: {
//         //             'Content-Type': 'application/json',
//         //         },
//         //         body: JSON.stringify({
//         //             title: input,
//         //         }),
//         //     });

//         //     const title = await createTitle.json();
//         //     setTitle(title?.title);
//         //     setChatHistory([...chatHistory, title]);
//         // }
//     }
// }

{
  /* {chat?.map((item, index) => (
        <div key={index} className='leading-loose'>
        <span className="mr-6">{item.role}:-</span>
        <span>
            {item.content}
        </span>
    </div>
))} */
}
