import React, { useState, useEffect, useRef } from "react";
import "./css/MakePost.css";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import API_URL from "../../api/Api";
import ImageIcon from "@mui/icons-material/Image";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Modal from "@mui/material/Modal";
// import UploadDes from "./UploadDes";

const MakePost = ({ onClose }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [sendLoading, setSendLoading] = useState(false);

  const [image, setImage] = React.useState("");
  const imageRef = React.useRef("null");

  function useDisplayImage() {
    const [result, setResult] = React.useState(false);

    function uploader(e) {
      const imageFile = e.target.files[0];

      const reader = new FileReader();
      reader.addEventListener("load", (e) => {
        setResult(e.target.result);
      });

      reader.readAsDataURL(imageFile);
    }

    return { result, uploader };
  }

  const { result, uploader } = useDisplayImage();

  const handleSave = async () => {
    try {
      setSendLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        },
      };

      const response = await axios.post(
        `${API_URL}/api/mentor/addmentor`,
        {},
        config
      );

      if (response.status === 202) {
        window.location.reload();
        setSendLoading(false);
        onClose();
      } else {
        console.log("Failed to update education");
        setSendLoading(false);
      }
    } catch (error) {
      console.log("Error updating education:", error);
      setSendLoading(false);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={sendLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mpost__container">
        <div className="mpost__main">
          <div className="upload__mainBox">
            <div className="upload__left">
              {/* left -1 */}

              <div className="upload__leftContent">
                <div className="upload__leftHead">
                  <p>Upload Options</p>
                </div>
                <ul>
                  <li>
                    <div className="upload__leftIcons">
                      <input
                        type="file"
                        name="image"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                          uploader(e);
                        }}
                      />
                      <ImageIcon />
                      <p>Image</p>
                    </div>
                  </li>

                  <li>
                    <div className="upload__leftIcons" onClick={handleOpen}>
                      <SettingsSharpIcon />
                      <p>Settings</p>
                    </div>
                  </li>
                  <li>
                    <div className="upload__leftIcons" onClick={handleOpen}>
                      <ArrowForwardIosIcon />
                      <p>Continue</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="upload__right">
              {result ? (
                <img ref={imageRef} src={result} alt="" />
              ) : (
                <>
                  <h1>Upload Image:</h1>
                  <div className="upload__rightContent">
                    <div className="upload__rightIcons">
                      <div className="upload__rightInner">
                        <input
                          type="file"
                          name="image"
                          onChange={(e) => {
                            setImage(e.target.files[0]);
                            uploader(e);
                          }}
                        />
                        <ImageIcon />
                      </div>
                      <h1>Image</h1>
                      <h6>Post size must be less than 75kb </h6>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakePost;
