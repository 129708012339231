import * as React from 'react';
import './EditEducation.css'; // Replace with the actual CSS file path
import { useState } from 'react';
import axios from 'axios'; // Import Axios for API requests
import API_URL from '../../../api/Api';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';

const EditEducation = ({ edu, onClose }) => {
    const [educationData, setEducationData] = useState({ ...edu });
    const [deleteBtn, setDeleteBtn] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);

    const imageRef = React.useRef(null);

    function useDisplayImage() {
        const [result, setResult] = React.useState(false);

        function uploader(e) {
            const imageFile = e.target.files[0];

            const reader = new FileReader();
            reader.addEventListener('load', (e) => {
                setResult(e.target.result);
            });

            reader.readAsDataURL(imageFile);
        }

        return { result, uploader };
    }

    const { result, uploader } = useDisplayImage();

    const handleInputChange = (event, fieldName) => {
        const { value } = event.target;
        setEducationData((prevEducationData) => ({
            ...prevEducationData,
            [fieldName]: value,
        }));
    };

    const handleSave = async () => {
        try {
            setSendLoading(true)
            const config = {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                },
            };

            const response = await axios.patch(
                `${API_URL}/api/mentor/education/${edu._id}`,
                {
                    school: educationData.school,
                    schoolImage: result,
                    degree: educationData.degree,
                    fieldOfStudy: educationData.fieldOfStudy,
                    startYear: educationData.startYear,
                    endYear: educationData.endYear,
                },
                config
            );

            if (response.status === 202) {
                console.log(response.data);
                onClose(); // Close the modal
                setSendLoading(false)
                toast.success('Education data updated');
            } else {
                console.log('Failed to update education');
                toast.error('Failed to update education');
                setSendLoading(false)
            }
        } catch (error) {
            console.log('Error updating education:', error);
            toast.error('Error updating education');
            setSendLoading(false)
        }
    };

    const handleDelete = async () => {
        try {
            setSendLoading(true)
            await axios.delete(
                `${API_URL}/api/mentor/education/${edu._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
                    },
                }
            );
            onClose();
            setSendLoading(false)
            toast.success('Education data deleted');
        } catch (error) {
            console.log(error);
            toast.error('Error deleting education');
        }
    };

    return (
        <div className='editEducation__container'>
                 <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={sendLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="editEducation__main">
                <h1>Edit Education:
                    <span onClick={() => { onClose() }}>
                        <CloseIcon fontSize='4rem' color='white' />
                    </span>
                </h1>
                <div>
                    <div className='editEducation__map'>
                        <div className='editEducation__formcol'>
                            <div>
                                <h1>School*</h1>
                                <input
                                    type="text"
                                    placeholder='Enter your school/college/university name'
                                    value={educationData.school}
                                    onChange={(e) => handleInputChange(e, 'school')}
                                />
                            </div>
                        </div>

                        <div className='editEducation__formcol'>
                            <div className='editEducation__form-margin'>
                                <h1>Degree*</h1>
                                <input
                                    type="text"
                                    placeholder='Enter Degree'
                                    value={educationData.degree}
                                    onChange={(e) => handleInputChange(e, 'degree')}
                                />
                            </div>

                            <div>
                                <h1>School Image*</h1>
                                <div className='editEducation__upload'>
                                    <div className='editEducation__upload-img'>
                                        {!educationData.schoolImage ? 'Choose Image' : 'Change'}
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => uploader(e)}
                                        />
                                    </div>

                                    {!result ? <>
                                        {educationData.schoolImage &&
                                            <div className='editEducation__upload-viewimg'>
                                                <img src={educationData.schoolImage} alt="Uploaded" />
                                            </div>
                                        }
                                    </> : (
                                        <>
                                            <div className='editEducation__upload-viewimg'>
                                                <img ref={imageRef} src={result} alt="Uploaded" />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='editEducation__formcol'>
                            <div>
                                <h1>Field of study*</h1>
                                <input
                                    type="text"
                                    placeholder='Field of study'
                                    value={educationData.fieldOfStudy}
                                    onChange={(e) => handleInputChange(e, 'fieldOfStudy')}
                                />
                            </div>
                        </div>

                        <div className='editEducation__formcol'>
                            <div className='editEducation__form-margin'>
                                <h1>Start year*</h1>
                                <input
                                    type="text"
                                    placeholder='year'
                                    value={educationData.startYear}
                                    onChange={(e) => handleInputChange(e, 'startYear')}
                                />
                            </div>

                            <div>
                                <h1>End year*</h1>
                                <input
                                    type="text"
                                    placeholder='year'
                                    value={educationData.endYear}
                                    onChange={(e) => handleInputChange(e, 'endYear')}
                                />
                            </div>
                        </div>

                        <div className='editEducation__delete'>
                            {!deleteBtn && <div onClick={() => { setDeleteBtn(true) }}>
                                Delete
                            </div>}
                            {deleteBtn && <div onClick={() => { setDeleteBtn(false) }}>
                                <CloseIcon sx={{ fontSize: '2.2rem' }} />
                            </div>}
                            {deleteBtn && <div onClick={handleDelete} style={{ marginLeft: '1.2rem' }}>
                                <DoneIcon sx={{ fontSize: '2.2rem' }} />
                            </div>}
                        </div>
                    </div>




                    <div className='editEducation__save' onClick={handleSave}>
                        <div>
                            Save
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditEducation;
