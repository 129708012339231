import React, { useEffect, useState } from 'react';
import Navbar2 from '../../components/Navbar2';
import { Footer } from '../../components';
import './css/Feed.css';
import CreatePost from './CreatePost';
import Post from './Post';
import API_URL from '../../api/Api';
import axios from 'axios';
import { DataState } from '../../context/DataProvider';

const Feed = () => {
    const { user, accessToken } = DataState();

    const [posts, setPosts] = useState([]);

    const getPosts = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/post/getall`);
            console.log(response.data)
            setPosts(response.data);
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    useEffect(() => {
        getPosts();
    }, []);

    return (
        <>
            <Navbar2 />
            <div className="feed__main">
                <div className="feed__container">
                    <div className="feed__body">
                        <CreatePost />
                        {posts.map((post, index) => (
                            <Post key={index} post={post} />
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Feed