import React from "react";
import { Dialog, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = ({ open, handleClose, children }) => {
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        style={{ zIndex: 10000 }}
      >
        <div className="min-h-full max-w-screen flex items-center justify-center">
          {children}
          <div className="dialog__cancel" onClick={handleClose}>
            <IconButton>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default CustomDialog;
