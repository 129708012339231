import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import axios from "axios";
import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";

//Importing Pages
import {
  About,
  AiChat,
  AskMentors,
  BookCallPage,
  Contact,
  Events,
  Feed,
  Home,
  Login,
  Mentor,
  MentorDashboard,
  MentorList,
  PrivacyPolicy,
  Profile,
  Services,
  StartupRegMain,
  Success,
  TermsCondition,
} from "./Pages";
import PreviousPayment from "./components/PreviousPayment/PreviousPayment";
import BookFreeTrial from "./Pages/BookFreeTrial/BookFreeTrial";
import BookingSuccessfullScreen from "./Pages/PaidSessions/PaidSessions";
import {
  NotFound,
  PaymentCancelledScreen,
  ProtectedRoute,
  SingleQuestion,
} from "./components";

// Import the specific language definition for JSX
import "prismjs/themes/prism.css";
import "prismjs/components/prism-jsx";
import BookPaidSession from "./Pages/PaidSessions/BookPaidSession";
import Testgpt from "./Testgpt";
import Testing from "./Testing";
import toast, { Toaster } from "react-hot-toast";

import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import Cookie from "js-cookie"; // You may need to install a cookie library, like js-cookie
import API_URL from "./api/Api";
import Dummyuser from "./DummyUser/Dummyuser";
import VideoMeeting from "./Pages/VideoMeeting/VideoMeeting";
import VideoRoom from "./Pages/VideoMeeting/VideoRoom";

const Routing = () => {
  return (
    <Routes>
      <Route exact path="/dummy" element={<Dummyuser />} />

      <Route exact path="/" element={<Home />} />
      <Route exact path="/services" element={<Services />} />
      <Route exact path="/contact" element={<Contact />} />
      {/* <Route exact path="/mentor" element={<Mentor />} /> */}
      <Route exact path="/startup-registration" element={<StartupRegMain />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/terms-and-conditions" element={<TermsCondition />} />
      <Route exact path="/about-us" element={<About />} />
      {/* <Route exact path="/test" element={<Testgpt />} /> */}

      <Route exact path="/mentorlist" element={<MentorList />} />
      <Route
        exact
        path="/profile"
        element={<ProtectedRoute Component={Profile} />}
      />

      <Route
        exact
        path="/login"
        element={<ProtectedRoute Component={Login} />}
      />
      <Route
        exact
        path="/events"
        element={<ProtectedRoute Component={Events} />}
      />
      <Route
        exact
        path="/askmentors"
        element={<ProtectedRoute Component={AskMentors} />}
      />

      <Route exact path="/feed" element={<ProtectedRoute Component={Feed} />} />
      <Route
        exact
        path="/mentordashboard"
        element={<ProtectedRoute Component={MentorDashboard} />}
      />
      <Route
        exact
        path="/question"
        element={<ProtectedRoute Component={SingleQuestion} />}
      />
      <Route exact path="/success" element={<Success />} />
      <Route exact path="/testing" element={<Testing />} />
      {/* <Route exact path="/ai" element={<ProtectedRoute Component={AiChat} />} /> */}

      <Route
        exact
        path="/booksessions"
        element={<ProtectedRoute Component={BookingSuccessfullScreen} />}
      />
      <Route
        exact
        path="/payment-cancelled"
        element={<ProtectedRoute Component={PaymentCancelledScreen} />}
      />

      <Route
        exact
        path="/book-free-sessions"
        element={<ProtectedRoute Component={BookFreeTrial} />}
      />

      <Route
        exact
        path="/book-sessions"
        element={<ProtectedRoute Component={BookPaidSession} />}
      />

      <Route
        exact
        path="/book-sessions-payment"
        element={<ProtectedRoute Component={PreviousPayment} />}
      />
      <Route
        exact
        path="/meetings"
        element={<ProtectedRoute Component={VideoMeeting} />}
      />

      <Route
        exact
        path="/room/:roomId"
        element={<ProtectedRoute Component={VideoRoom} />}
      />

      <Route exact path="/book-call" element={<BookCallPage />} />

      {/* Route not available */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FormDialog = ({
  open,
  handleClose,
  showEmailInput,
  handleEmailSubmit,
  onlyClose,
  email,
  setEmail,
}) => {
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        style={{ zIndex: 10000 }}
      >
        <Toaster />

        <div className="bg-gray-900 dialog__main">
          <div className="dialog">
            <h3>Unlock Your Career Potential with Exclusive Mentorship!</h3>
            <p>
              "Embark on a journey to success with personalized mentorship
              designed to elevate your career and startup ventures. Sign up now
              to join our thriving community of ambitious individuals. Receive
              expert guidance, valuable insights, and tailored support to
              accelerate your professional growth. Don't miss out – let's pave
              the way to success together! Simply enter your email below and
              take the first step towards unlocking your full potential."
            </p>

            {showEmailInput ? (
              <form onSubmit={handleEmailSubmit} className="dialogBox__form">
                <input
                  type="text"
                  placeholder="Enter your email"
                  className="input-style"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <button className="button-style" onClose={handleClose}>
                  Get started
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </form>
            ) : (
              <p>Email successfully submitted. Thank you!</p>
            )}
            <h6 onClick={onlyClose}>Remind me later</h6>
          </div>
          <div
            className="absolute inset-0 m-auto max-w-xs h-[600] blur-[118px] sm:max-w-md md:max-w-lg"
            style={{
              background:
                "linear-gradient(106.89deg, rgba(192, 132, 252, 0.11) 15.73%, rgba(14, 165, 233, 0.41) 15.74%, rgba(232, 121, 249, 0.26) 56.49%, rgba(79, 70, 229, 0.4) 115.91%)",
            }}
          ></div>
          <div className="dialog__cancel" onClick={handleClose}>
            <IconButton>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

function App() {
  const [open, setOpen] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(true);
  const [email, setEmail] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCookieExpiration();
  };

  const onlyClose = () => {
    setOpen(false);
    const twoHours = 1 / 12;
    const closedCount = Cookie.get("closedCount") || 0;
    Cookie.set("closedCount", parseInt(closedCount) + 1, { expires: twoHours });
  };

  const setCookieExpiration = () => {
    const closedCount = Cookie.get("closedCount") || 0;
    const expirationDays = getExpirationDays(closedCount);
    Cookie.set("closedCount", parseInt(closedCount) + 1, {
      expires: expirationDays,
    });
  };

  const getExpirationDays = (closedCount) => {
    switch (parseInt(closedCount)) {
      case 0:
        return 1; // hide for 1 day after the first close
      case 1:
        return 3; // hide for 3 days after the second close
      default:
        return 15; // hide for 15 days after the third and subsequent closes
    }
  };

  useEffect(() => {
    const hasProvidedEmail = Cookie.get("hasProvidedEmail");
    const closedCount = Cookie.get("closedCount") || 0;
    if (!hasProvidedEmail && closedCount === 0) {
      setTimeout(() => {
        handleClickOpen();
      }, 5000);
    }
  }, []);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate email
    if (!email) {
      toast.error("Enter email address");
      return;
    }
    if (!emailRegex.test(email)) {
      toast.error("Invalid email address");
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/saveEmail`, { email });
      if (response.data.ok) {
        setShowEmailInput(false);
        Cookie.set("hasProvidedEmail", true, { expires: 365 });
        setTimeout(() => {
          handleClose();
        }, 2000);
        setEmail("");
      } else {
        toast.error("Error saving email");
        console.error(response.data.error);
      }
    } catch (error) {
      toast.error("Error saving email");
      console.error(error);
    }
  };

  return (
    <>
      <Theme>
        <Toaster />
        <FormDialog
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          showEmailInput={showEmailInput}
          handleEmailSubmit={handleEmailSubmit}
          onlyClose={onlyClose}
          email={email}
          setEmail={setEmail}
        />
        <Routing />
      </Theme>
    </>
  );
}

export default App;
