import React from 'react'
import './css/Success.css'
import { Link } from 'react-router-dom';

const Success = () => {
    return (
        <div className='success'>
            <div className='success__img'>
                <img src="./assets/success.svg" alt="" />
            </div>
            <div className='success__btn'>
                <Link to='/'>
                    <div className='success__btn-home'>
                        Go Home
                    </div>
                </Link >
                <Link to='/mentorlist' >
                    <div className='nav3__link-mentor'>
                        <h3>See Mentors</h3>
                        <img src="./assets/mentor-user-tie.svg" alt="" />
                    </div>
                </Link>
            </div>
        </div >
    )
}
export default Success