import React, { useEffect, useState } from 'react'
import API_URL from '../../api/Api';
import axios from 'axios';
import { Link } from 'react-router-dom';
import OtherMentors from './OtherMentors';
import { EventsCard } from '../../components';
import './css/ProfileNetwork.css'

const ProfileNetwork = ({ mentorData, checkFreeSessions }) => {
    const [events, setEvents] = useState([]);
    const [otherMentors, setOtherMentors] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/events`);
                setEvents(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching events:", error);
                setLoading(false);
            }
        };

        fetchEvents();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/mentor/getall?page=1&approved=true&areaOfMentorship=&targetSector=&remunerationType=`);
                setOtherMentors(response.data.mentors);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <div className="profile__start-mentorship-main">
                <div className="profile__start-mentorship">
                    <h1>Start Your Mentorship with {mentorData?.user?.name}</h1>
                    <div className="profile__start-mentorship-body">
                        <div className="profile__start-mentorship-left">
                            {/* div 1 */}
                            <div className="profile__start-mentorship-left-card">
                                <div className="profile__start-mentorship-left-card-float">
                                    {mentorData?.sessions} sessions(s)/week
                                </div>
                                <div className="profile__start-mentorship-left-card-img">
                                    <img src="./assets/profile-phone.svg" alt="" />
                                </div>
                                <h2>Within 12 hours</h2>
                                <p>
                                    Solve doubts, get your conflicts cleared, create
                                    Buisness plan with personalised mentoring sessions
                                </p>
                            </div>

                            {/* div 2 */}
                            <div className="profile__start-mentorship-left-card">
                                <div className="profile__start-mentorship-left-card-float">
                                    Within 12 hours
                                </div>
                                <div className="profile__start-mentorship-left-card-img">
                                    <img src="./assets/profile-chat.svg" alt="" />
                                </div>
                                <h2>Chat Support</h2>
                                <p>
                                    Your mentor is always available when you need them,
                                    reach them out whenever you feel stuck
                                </p>
                            </div>

                            {/* div 3 */}
                            <div className="profile__start-mentorship-left-card">
                                <div className="profile__start-mentorship-left-card-float">
                                    Every 2 days
                                </div>
                                <div className="profile__start-mentorship-left-card-img">
                                    <img src="./assets/profile-pitch.svg" alt="" />
                                </div>
                                <h2>Resume building</h2>
                                <p>
                                    Mentors will help you in crafting a standout resume by offering insights, sharing experiences, and guiding strategic choices. They empower you to showcase your skills and achievements effectively.
                                </p>
                            </div>

                            {/* div 4 */}
                            <div className="profile__start-mentorship-left-card">
                                <div className="profile__start-mentorship-left-card-float">
                                    Community Led
                                </div>
                                <div className="profile__start-mentorship-left-card-img">
                                    <img src="./assets/profile-refer.svg" alt="" />
                                </div>
                                <h2>Job referrals</h2>
                                <p>
                                    Get referred into your inQbator mentor's community once
                                    your mentor feels you're ready! to be referred.
                                </p>
                            </div>
                        </div>

                        <div className="profile__start-mentorship-right">
                            <div className="profile__start-mentorship-right-book">
                                <div>
                                    <h1>
                                        Book Your Long term Mentorship
                                    </h1>
                                    <div className="profile__start-mentorship-right-book-price">
                                        <h1>₹{mentorData?.remunerationValue}/ Session</h1>
                                    </div>
                                </div>
                                <Link to={`/book-sessions?userId=${mentorData._id}`}>
                                    <div className="profile__start-mentorship-right-book-btn">
                                        <p>Book Long term Mentorship</p>
                                        <img src="./assets/user-tie-black.svg" alt="" />
                                    </div>
                                </Link>
                            </div>

                            {/* {checkFreeSessions?.freeSession ? */}
                            {mentorData?.paidOptions === "true" ? (
                                <Link to={`/book-sessions?userId=${mentorData._id}`}>
                                    <div className="profile__start-mentorship-right-trial">
                                        <p>
                                            Get clarity about how the mentor will personally help
                                            you achieve your goals over the next few months.
                                        </p>
                                        <h1>
                                            Get Session
                                            <i className="fa-solid fa-arrow-right" />
                                        </h1>
                                    </div>
                                </Link>
                            ) : (
                                <>
                                    {checkFreeSessions?.freeSession ? (
                                        <Link to={`/book-free-sessions?userId=${mentorData._id}`}>
                                            <div className="profile__start-mentorship-right-trial">
                                                <p>
                                                    Get clarity about how the mentor will personally help
                                                    you achieve your goals over the next few months.
                                                </p>
                                                <h1>
                                                    Free Trial Session
                                                    <i className="fa-solid fa-arrow-right" />
                                                </h1>
                                            </div>
                                        </Link>
                                    ) : (
                                        <Link to={`/book-sessions?userId=${mentorData._id}`}>
                                            <div className="profile__start-mentorship-right-trial">
                                                <p>
                                                    Get clarity about how the mentor will personally help
                                                    you achieve your goals over the next few months.
                                                </p>
                                                <h1>
                                                    Get Session
                                                    <i className="fa-solid fa-arrow-right" />
                                                </h1>
                                            </div>
                                        </Link>
                                    )}
                                </>)
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="profile__network-main">
                <div className="profile__network">
                    <h1>
                        {mentorData?.user?.name} mentorship is part of the inQbator
                        mentorship network.
                    </h1>
                    <p>
                        Our network of mentors was built for determined individuals
                        who want to excel. Our mentors don’t only train you with the
                        Business Plans & Pitch Deck or you need to succeed but do
                        everything in their power to help you get Funding .{" "}
                    </p>
                    <div className="profile__network-container">
                        <div className="profile__network-card">
                            <div className="profile__network-card-div-br-purple">
                                <img src="./assets/profile-home.svg" alt="" />
                            </div>
                            <h1>Vetted, Verified & Experinced Mentors</h1>
                            <p>
                                Choose a subscription duration after clicking the book
                                button. Monthly plans are available, but longer plans or
                                an Equity Exchange offer discounts and less hassle.
                                Discuss with your mentor to find the optimal duration for
                                you.
                            </p>
                        </div>
                        <div className="profile__network-card">
                            <div className="profile__network-card-div-bg">
                                <img src="./assets/profile-guard-tick.svg" alt="" />
                            </div>
                            <h1>Vetted, Verified & Experinced Mentors</h1>
                            <p>
                                Choose a subscription duration after clicking the book
                                button. Monthly plans are available, but longer plans or
                                an Equity Exchange offer discounts and less hassle.
                                Discuss with your mentor to find the optimal duration for
                                you.
                            </p>
                        </div>
                        <div className="profile__network-card">
                            <div className="profile__network-card-div-br-purple">
                                <img src="./assets/profile-search.svg" alt="" />
                            </div>
                            <h1>Vetted, Verified & Experinced Mentors</h1>
                            <p>
                                Choose a subscription duration after clicking the book
                                button. Monthly plans are available, but longer plans or
                                an Equity Exchange offer discounts and less hassle.
                                Discuss with your mentor to find the optimal duration for
                                you.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="profile__moreMentor">
                    <h1>Other Software Developer Mentors</h1>
                    <div className="profile__moreMentor-container">
                        {otherMentors?.map((mentor) => (
                            <>
                                <OtherMentors key={mentor._id} mentor={mentor} />
                                <OtherMentors key={mentor._id} mentor={mentor} />
                                <OtherMentors key={mentor._id} mentor={mentor} />
                            </>
                        ))}
                    </div>
                </div>
            </div>

            <div className="profile__events-main">
                <div className="profile__events-body">
                    <h1>Upcoming Group Sessions</h1>
                    <div className="profile__events-container">
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            <>
                                {events?.map((event) => (
                                    <EventsCard key={event._id} event={event} />
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default ProfileNetwork