import Navbar2 from "../../components/Navbar2";
import React, { useState, useRef, useEffect } from "react";
import "./MentorList.css";
import "../../components/miscellenius/css/DropDown.css";
import { Footer, MentorCard, ScrollTop } from "../../components";
import API_URL from "../../api/Api";
import BookCall from "../BookCall/BookCall";
import { Modal } from "@mui/material";
import axios from "axios";
import MentorcardLoading from "./MentorcardLoading";
import { DataState } from "../../context/DataProvider";

const itemsData = [
  { id: 1, title: "All", imageUrl: "All" },
  // { id: 27, title: "Job", imageUrl: "Job" },
  // { id: 26, title: "Startup", imageUrl: "Startup" },
  { id: 2, title: "AI/ML", imageUrl: "ai-ml" },
  { id: 3, title: "Backend", imageUrl: "Backend" },
  { id: 4, title: "Business", imageUrl: "Business" },
  { id: 5, title: "Cloud", imageUrl: "Cloud" },
  { id: 6, title: "Consulting", imageUrl: "Consulting" },
  { id: 7, title: "Cyber Security", imageUrl: "Cyber Security" },
  { id: 8, title: "Data Science", imageUrl: "Data Science" },
  { id: 9, title: "Design", imageUrl: "Design" },
  { id: 10, title: "Finance", imageUrl: "Finance" },
  { id: 11, title: "Frontend", imageUrl: "Frontend" },
  { id: 12, title: "Full Stack", imageUrl: "full-stack" },
  { id: 13, title: "Game Development", imageUrl: "Game Development" },
  { id: 14, title: "Human Resource", imageUrl: "Human Resource" },
  { id: 15, title: "IOT", imageUrl: "IOT" },
  { id: 16, title: "Management", imageUrl: "Management" },
  { id: 17, title: "Marketing", imageUrl: "Marketing" },
  { id: 18, title: "Network", imageUrl: "Network" },
  { id: 19, title: "Operations", imageUrl: "Operations" },
  { id: 20, title: "Product", imageUrl: "Product" },
  { id: 21, title: "Project Management", imageUrl: "Project Management" },
  { id: 22, title: "Prompt Engineering", imageUrl: "Prompt Engineering" },
  { id: 23, title: "R&D", imageUrl: "R&D" },
  { id: 24, title: "Research & Management", imageUrl: "Research & Management" },
  { id: 25, title: "Sales", imageUrl: "Sales" },
];

const Scrollbar = ({ selectedItem, setSelectedItem }) => {
  const scrollbarRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [scrollStartX, setScrollStartX] = useState(0);

  const handleItemClick = (title) => {
    setSelectedItem(title);
  };

  const handleScrollLeft = () => {
    scrollbarRef.current.scrollLeft -= 100; // Adjust the scroll amount based on your needs
  };

  const handleScrollRight = () => {
    scrollbarRef.current.scrollLeft += 100; // Adjust the scroll amount based on your needs
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStartX(event.clientX);
    setScrollStartX(scrollbarRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const dx = event.clientX - dragStartX;
    scrollbarRef.current.scrollLeft = scrollStartX - dx;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div
      className="mentorlist__scrollable-tabs-container"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div className="mentorlist__left-arrow" onClick={handleScrollLeft}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </div>
      <ul ref={scrollbarRef}>
        {itemsData.map((item) => (
          <li
            key={item.id}
            className={`mentorlist__scrollable-item ${
              selectedItem === item.title ? "selected" : ""
            }`}
            onClick={() => handleItemClick(item.title)}
            type="button"
            onDragStart={(e) => e.preventDefault()}
          >
            <div>
              <img
                src={`./targetSectorImages/${item.imageUrl}.svg`}
                alt={item.title}
              />
            </div>
            <h3>{item.title}</h3>
          </li>
        ))}
      </ul>
      <div className="mentorlist__right-arrow">
        <svg
          onClick={handleScrollRight}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </div>
    </div>
  );
};

const SearchInput = ({ searchTerm, setSearchTerm }) => {
  const handleChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  return (
    <div className="mentorlist__filter-search">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleChange}
      />
    </div>
  );
};

const MentorList = () => {
  const { searchTerm, setSearchTerm } = DataState();
  const [openCallModal, setOpenCallModal] = useState(false);

  const [selectedPriceEquity, setSelectedPriceEquity] = useState("");
  const [selectedDomain, setSelectedDomain] = useState(""); //for
  const [selectedCategory, setSelectedCategory] = useState("");
  const [mentors, setMentors] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mentorCardLoading, setMentorCardLoading] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");

  // One time check for trials remaining
  const { accessToken } = DataState();
  const [checkFreeSessions, setCheckFreeSessions] = useState();

  const checkFreeSessionsFetch = async () => {
    try {
      const response = await axios.get(
        // `${API_URL}/api/paidMentorMentees?mentorId=${mentor._id}`
        `${API_URL}/api/checkTrial`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Response trial:", response.data);
      setCheckFreeSessions(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  useEffect(() => {
    checkFreeSessionsFetch();
    return (() => setSearchTerm(""));
  }, []);

  const searchMentors = async () => {
    try {
      setMentorCardLoading(true);

      const response = await fetch(
        `${API_URL}/api/mentor/search?searchTerm=${searchTerm.trim()}&page=${page}&approved=true&areaOfMentorship=${selectedDomain}&targetSector=${
          selectedCategory === "All" ? "" : selectedCategory
        }&remunerationType=${selectedPriceEquity}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setMentors(data.mentors);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Error fetching mentors:", error);
    } finally {
      setMentorCardLoading(false);
    }
  };

  const isFirstRender = useRef(true);

  // useEffect to call searchMentors() after initial render
  useEffect(() => {
    if (!isFirstRender.current) {
      // Call searchMentors() only after the initial render
      searchMentors();
    } else {
      // Set isFirstRender to false after the initial render
      isFirstRender.current = false;
    }
  }, [page]);

  useEffect(() => {
    if (page == 1) {
      searchMentors();
    } else {
      setPage(1);
    }
  }, [searchTerm, selectedPriceEquity, selectedCategory, selectedDomain]);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <>
      <Navbar2 />
      <div className="mentorlist__container-main">
        <div className="mentorlist__container">
          <div className="mentorlist__nav">
            <Scrollbar
              selectedItem={selectedCategory}
              setSelectedItem={setSelectedCategory}
            />
            <div className="mentorlist__filter">
              <div className="mentorlist__filter-search">
                <SearchInput
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              </div>
              <div className="mentorlist__filter-right">
                <div className="mentorlist__filter-price">
                  <select
                    name="price-equity"
                    id="price-equity"
                    value={selectedDomain}
                    onChange={(e) => {
                      setSelectedDomain(e.target.value);
                    }}
                  >
                    <option value="">All</option>
                    <option value="Career Mentorship">Career Mentorship</option>
                    <option value="Startup Mentorship">
                      Startup Mentorship
                    </option>
                    <option value="Domain Specific Expertise">
                      Domain Specific Expertise
                    </option>
                  </select>
                </div>
                <div className="mentorlist__filter-area">
                  <select
                    value={selectedCategory}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                    }}
                  >
                    {categoryOptions.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            className="xsm:text-slate-700 xsm:from
           mentorlist__contact"
          >
            Can’t decide on a mentor? Don’t worry, we will help you out.
            <span onClick={() => setOpenCallModal(true)}>Talk with Us</span>
            <div className="mentordash__modal">
              <Modal
                open={openCallModal}
                onClose={() => setOpenCallModal(false)}
              >
                <BookCall onClose={() => setOpenCallModal(false)} />
              </Modal>
            </div>
          </div>
          <div className="mentorlist__card-container">
            {!mentorCardLoading ? (
              <>
                {mentors?.length === 0
                  ? "No search results found"
                  : mentors?.map((mentor) => (
                      <MentorCard
                        key={mentor._id}
                        mentor={mentor}
                        checkFreeSessions={checkFreeSessions}
                      />
                    ))}
              </>
            ) : (
              <>
                <MentorcardLoading />
                <MentorcardLoading />
                <MentorcardLoading />
                <MentorcardLoading />
              </>
            )}
          </div>
          <div className="mentorlist__pageBtn_Con">
            <button
              onClick={() => {
                scrollToTop();
                setPage((prevPage) => Math.max(prevPage - 1, 1));
              }}
              disabled={page === 1}
              className="mentorlist__pageBtn"
            >
              Previous Page
            </button>
            <button
              onClick={() => {
                scrollToTop();
                setPage((prevPage) => Math.min(prevPage + 1, totalPages));
              }}
              disabled={page === totalPages}
              className="mentorlist__pageBtn"
            >
              Next Page
            </button>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollTop />
    </>
  );
};

export default MentorList;

const categoryOptions = [
  "All",
  "AI/ML",
  "Artificial Intelligence (AI)",
  "Augmented Reality (AR)",
  "Backend",
  "Bioinformatics",
  "Blockchain Technology",
  "Business",
  "Cloud",
  "Cloud Computing",
  "Computer Graphics",
  "Consulting",
  "Cyber Security",
  "Cybersecurity",
  "Data Science",
  "Data Science",
  "Database Management",
  "Design",
  "DevOps",
  "E-commerce Solutions",
  "Embedded Systems",
  "Finance",
  "Frontend",
  "Full Stack",
  "Game Development",
  "Geographic Information Systems (GIS)",
  "Human Resource",
  "IOT",
  "Internet of Things (IoT)",
  "IT Consulting",
  "IT Governance",
  "IT Project Management",
  "Management",
  "Marketing",
  "Machine Learning (ML)",
  "Mobile App Development",
  "Network",
  "Network Administration",
  "Networking",
  "Operations",
  "Product",
  "Project Management",
  "Prompt Engineering",
  "Quantum Computing",
  "R&D",
  "Research & Management",
  "Robotics",
  "Sales",
  "Software Development",
  "System Administration",
  "User Experience (UX) Design",
  "Virtual Reality (VR)",
  "Web Development",
  "Quality Assurance (QA) and Testing",
];
