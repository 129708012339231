import React, { useState } from 'react'
import './Footer.css';
import { Link, useLocation } from 'react-router-dom';
import { Modal } from "@mui/material";
import BookCall from '../../Pages/BookCall/BookCall';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  const [openCallModal, setOpenCallModal] = useState(false);
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='footer__main'>
      <div className="footer">
        <div className="footer__text">
          <div className="footer__left">
            <Link to="/" onClick={() => {
              if (location.pathname === '/') {
                scrollToTop()
              }
            }}>
              <div className="footer__logo">
                <img src="./assets/main-logo.svg" alt=''/>
              </div>
            </Link>
            <p>InQbator is a platform dedicated to empowering individuals, professionals, students, and aspiring career enthusiasts to connect and engage on a singular platform. It provides an avenue to receive mentorship for career development and contribute to a dynamic community of like-minded individuals. Through InQbator, users can seek valuable guidance from mentors, validating and implementing their career aspirations. This platform serves as a blend of social media and educational resources, fostering interaction and offering a means to achieve impactful career mentorship.</p>
            <div className='footer_social'>
              <p>Follow us on</p>
              <a href='https://www.instagram.com/inqbator.in' target='_blank' rel='noreferrer'>
              <InstagramIcon sx={{fontSize: '3rem'}}/>
              </a>
              <a href='https://www.linkedin.com/company/inqbatorllp' target='_blank' rel='noreferrer'>
                <LinkedInIcon sx={{fontSize: '3rem'}}/>
              </a>
            </div>
            <h1>Copyright 2024 &copy; inQbator Technologies LLP . All RIght Reserved.</h1>
          </div>
          <div className="footer__right">

            <div className="footer__links">
              <h1>PLATFORM</h1>
              <a href='https://discord.gg/qTWxjfebR3' target='_blank' rel='noreferrer'>Join Discord Community</a>

              <Link to='/mentorlist' onClick={() => {
                if (location.pathname === '/mentorlist') {
                  scrollToTop()
                }
              }}>Free Mentorship</Link>

              <Link to='/mentorlist' onClick={() => {
                if (location.pathname === '/mentorlist') {
                  scrollToTop()
                }
              }}>Become a Member</Link>

              <Link to='/mentorlist' onClick={() => {
                if (location.pathname === '/mentorlist') {
                  scrollToTop()
                }
              }} >Book a Free Session</Link>
            </div>

            <div className="footer__links">
              <h1>COMPANY</h1>
              <Link to="/about-us" onClick={() => {
                if (location.pathname === '/about-us') {
                  scrollToTop()
                }
              }}>About</Link>
              <Link to="/privacy-policy" onClick={() => {
                if (location.pathname === '/privacy-policy') {
                  scrollToTop()
                }
              }}>Privacy Policy</Link>
              <Link to="/terms-and-conditions" onClick={() => {
                if (location.pathname === '/terms-and-conditions') {
                  scrollToTop()
                }
              }}>Terms & Conditions</Link>
            </div>


            <div className="footer__links">
              <h1>QUICK LINKS</h1>
              <Link to='/mentorlist' onClick={() => {
                if (location.pathname === '/mentorlist') {
                  scrollToTop()
                }
              }}>Mentorship</Link>

              <Link to="/startup-registration" onClick={() => {
                if (location.pathname === '/startup-registration') {
                  scrollToTop()
                }
              }}>Startup Registration</Link>

              <Link to="/book-call" onClick={() => {
                if (location.pathname === '/services') {
                  scrollToTop()
                }
              }}>Book Call for Startups</Link>

              <Link onClick={() => setOpenCallModal(true)}>Contact Us</Link>
            </div>
            {/* <div className="footer__links">
              <h1>RESOURCES</h1>
              <Link>Newsletter</Link>
              <Link>Podcast</Link>
              <Link>Case Studies</Link>
              <Link>Perks</Link>
            </div> */}
            {/* <div className="footer__links">
              <h1>SUPPORT</h1>
              <Link>FAQ</Link>
              <Link onClick={() => setOpenCallModal(true)}>Contact Us</Link>
            </div> */}
          </div>
        </div>
        <div className="footer__mainImage">
          <img src="./assets/inQbator-text.svg" alt="" />
        </div>
      </div>

      <div className="mentordash__modal">
        <Modal
          open={openCallModal}
          onClose={() => setOpenCallModal(false)}
        >
          <BookCall
            onClose={() => setOpenCallModal(false)}
          />
        </Modal>
      </div>
    </div>
  )
}

export default Footer