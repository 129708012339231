import React from 'react'
import './HomeCycle.css'
import { Link } from 'react-router-dom';

const HomeCycle = () => {
    return (
        <>
            <div className='homecycle__main'>
                <div className="t-section">
                    <div className="container-1280-width w-container">
                        <div className="margin-bottom-64 side">
                            <div className="skill-h3-max">
                                <h2 className="t-h2-heading">How does inQbator long term Mentorship Work?</h2>
                            </div>
                            <Link to='mentorlist'>
                                <div className="homecycle__headBtn">
                                    <p>Select Your Mentor</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>


                <div className="hiw-image">
                    <img src="./homePageImages/roadMapImage.svg" loading="lazy" alt="" className="image-100--rel" />
                </div>
            </div>
        </>
    )
}

export default HomeCycle