import React from "react";
import "./MentorCard.css";
import { Link } from "react-router-dom";
import { DataState } from "../../context/DataProvider";
import { Menu, MenuItem } from "@mui/material";

const MentorCard = ({ mentor, checkFreeSessions }) => {
  //about
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //skills
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  let showAllSkills;
  const skills = mentor?.skills[0]?.skills;
  const skillsToShow = showAllSkills ? skills : skills?.slice(0, 6);

  const { accessToken, user } = DataState();
  console.log("user", user);

  return (
    <div className="mc__main">
      {/* Left side */}
      <div className="mc__l">
        <div className="mc__l-img">
          <img src={mentor?.user?.picture} alt="" />
        </div>
        <div className="mc__l-com-main">
          {mentor?.experienceData && (
            <div className="mc__l-com">
              <div className="mc__l-com-img">
                <img
                  src={mentor?.experienceData[0]?.companyImage}
                  alt={mentor?.experienceData[0]?.companyName}
                />
              </div>
              <div className="mc__l-com-text">
                <h3>{mentor?.experienceData[0]?.companyName}</h3>
                <p>{mentor?.experienceData[0]?.title}</p>
              </div>
            </div>
          )}

          {mentor?.experienceData && (
            <div className="mc__l-com">
              <div className="mc__l-com-img">
                <img
                  src={mentor?.experienceData[1]?.companyImage}
                  alt={mentor?.experienceData[1]?.companyName}
                />
              </div>
              <div className="mc__l-com-text">
                <h3>{mentor?.experience}+ Years of Experience</h3>
                <p>also worked at {mentor?.experienceData[1]?.companyName}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Mid */}
      <div className="mc__m">
        {/* Mid bio */}
        <div className="mc__m-bio">
          <h1>{mentor?.user?.name}</h1>
          <Link to={`/profile?userId=${mentor._id}`}>
            <p>
              {mentor?.about[0]?.about?.slice(0, 150)}
              {mentor?.about[0]?.about?.length > 150 && (
                <span onClick={handleClick}> . . . more</span>
              )}
            </p>
          </Link>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "hidden",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                border: "1px solid gray",
                mt: 1.5,
                bgcolor: "#121212",
                maxHeight: "35rem",
              },
            }}
            transformOrigin={{ horizontal: "center", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            sx={{ padding: "0px" }}
          >
            <MenuItem onClick={handleClose} sx={{ padding: "0px" }}>
              <div className="mc__about-menu-main">
                <div className="mc__about-menu">
                  <p>{mentor?.about[0]?.about}</p>
                </div>
              </div>
            </MenuItem>
          </Menu>
        </div>

        {/* Mid skill */}
        <div className="mc__m-skill">
          <div className="mc__m-skill-h">
            <div className="mc__m-skill-h-logo">
              <img src="./assets/mentorlist-book.svg" alt="" />
            </div>
            <h2>Skills & Experties</h2>
          </div>
          <Link to={`/profile?userId=${mentor._id}`}>
            <div className="mc__m-skill-container">
              {skillsToShow?.map((skill, index) => (
                <div key={index}>{skill}</div>
              ))}
              {skills?.length > 6 && <span>+ more</span>}
            </div>
          </Link>
          <Menu
            anchorEl={anchorEl2}
            id="account-menu2"
            open={open2}
            onClose={handleClose2}
            onClick={handleClose2}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                border: "1px solid gray",
                mt: 1.5,
                bgcolor: "#121212",
              },
            }}
            transformOrigin={{ horizontal: "center", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            sx={{ padding: "0px" }}
          >
            <MenuItem onClick={handleClose} sx={{ padding: "0px" }}>
              <div className="mc__about-menu-main">
                <div className="mc__about-menu">
                  <div className="mc__m-skill-container-menu">
                    {mentor?.skills[0]?.skills?.map((skill, index) => (
                      <div key={index}>{skill}</div>
                    ))}
                  </div>
                </div>
              </div>
            </MenuItem>
          </Menu>
        </div>

        {/* Mid area */}
        <div className="mc__m-area-main">
          <div className="mc__m-area">
            <div className="mc__m-area-h">
              <div>
                <img src="./assets/mentorcard-area.svg" alt="" />
              </div>
              <h2>For: </h2>
            </div>
            <div className="mc__m-area-text">
              {mentor?.areaOfMentorship.map((str, index) => (
                <span key={index}>
                  {str}
                  {index < mentor?.areaOfMentorship.length - 1 && " ○ "}
                </span>
              ))}
            </div>
          </div>

          <div className="mc__m-area">
            <div className="mc__m-area-h">
              <div>
                <img src="./assets/mentorcard-sector.svg" alt="" />
              </div>
              <h2>Target Sector:</h2>
            </div>
            <div className="mc__m-area-text">
              {mentor?.targetSector.map((str, index) => (
                <span key={index}>
                  {str}
                  {index < mentor?.targetSector.length - 1 && " ○ "}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Right side */}
      <div className="mc__r">
        <div className="mc__r-body">
          <div className="mc__r-top">
            <div>
              <div className="mc__r-area w-64">
                <div className="mc__r-area-h">
                  <div>
                    <img src="./assets/mentorcard-phone.svg" alt="" />
                  </div>
                  <h2>Sessions:</h2>
                </div>
                <div className="mc__r-area-text">
                  {mentor?.sessions} session(s)/week
                </div>
              </div>

              <div className="mc__r-area w-64">
                <div className="mc__r-area-h">
                  <div>
                    <img src="./assets/mentorcard-chat.svg" alt="" />
                  </div>
                  <h2>Chat: </h2>
                </div>
                <div className="mc__r-area-text">Within 12 hours</div>
              </div>
            </div>
            <div>
              <div className="mc__r-area w-64">
                <div className="mc__r-area-h">
                  <div>
                    <img src="./assets/mentorcard-tick.svg" alt="" />
                  </div>
                  <h2>Tasks & Followup:</h2>
                </div>
                <div className="mc__r-area-text">
                  Every {mentor?.followup} days
                </div>
              </div>
              <div className="mc__r-area w-64">
                <div className="mc__r-area-h">
                  <div>
                    <img src="./assets/mentorcard-bag.svg" alt="" />
                  </div>
                  <h2>Job referrals:</h2>
                </div>
                <div className="mc__r-area-text">& more</div>
              </div>
            </div>
          </div>

          <div className="mc__r-btm">
            {user?.freeTrialsRemaining === 0 ? (
              <h1>₹{mentor?.remunerationValue}/ Session</h1>
              ) : (
              <h1></h1>
            )}

            {mentor?.remunerationType === "equity" && (
              <h1>Equity {mentor?.remunerationValue}%</h1>
            )}
            <Link to={`/profile?userId=${mentor._id}`}>
              <div className="mc__viewprofile">View Profile</div>
            </Link>

            {accessToken ? (
              user?.freeTrialsRemaining > 0 ? (
                <Link to={`/book-free-sessions?userId=${mentor._id}`}>
                  <div className="mc__booksession">
                    Book a FREE Mentorship Session
                  </div>
                </Link>
              ) : (
                <Link to={`/book-sessions?userId=${mentor._id}`}>
                  <div className="mc__booksession">Book Session</div>
                </Link>
              )
            ) : (
              <Link to={`/book-free-sessions?userId=${mentor._id}`}>
                <div className="mc__booksession">
                  Book a FREE Mentorship Session
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorCard;

// {checkFreeSessions?.freeSession ? (
//   <Link to={`/book-free-sessions?userId=${mentor._id}`}>
//     <div className="mc__booksession">
//       {" "}
//       Book a FREE Mentorship Session
//     </div>
//   </Link>
// ) : (
//   <>
//     {mentor?.remunerationType === "price" ? (
//       <Link to={`/book-sessions?userId=${mentor._id}`}>
//         <div className="mc__booksession"> Book Session</div>
//       </Link>
//     ) : (
//       <>
//         <div
//           className="mc__booksession"
//           onClick={() => {
//             alert("We call you regarding equity based mentorship");
//           }}
//         >
//           {" "}
//           Book Session for equity
//         </div>
//       </>
//     )}
//   </>
// )}
