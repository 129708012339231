import React from 'react';
import './css/SelectImage.css';

const SelectImage = ({selected, setSelected}) => {

    const handleDivClick = (heading) => {
        setSelected((prevSelected) =>
            prevSelected.includes(heading)
                ? prevSelected.filter((divHeading) => divHeading !== heading)
                : [...prevSelected, heading]
        );
    };

    return (
        <>
            {/* div 1 */}
            <div
                className={`startup__min-img ${selected.includes('Ideation and validity') ? 'selected' : ''}`}
                onClick={() => handleDivClick('Ideation and validity')}
            >
                <div>
                    <img className='startup__min-img-size' src="./assets/mentor-form1.svg" alt="" />
                </div>
                <p>Ideation and validity</p>
            </div>

            {/* div 2 */}
            <div
                className={`startup__min-img ${selected.includes('Started implementing') ? 'selected' : ''}`}
                onClick={() => handleDivClick('Started implementing')}
            >
                <div>
                    <img src="./assets/mentor-form2.svg" alt="" />
                </div>
                <p>Started implementing</p>
            </div>

            {/* div 3 */}
            <div
                className={`startup__min-img ${selected.includes('Building MVP') ? 'selected' : ''}`}
                onClick={() => handleDivClick('Building MVP')}
            >
                <div>
                    <img className='startup__min-img-size' src="./assets/mentor-form3.svg" alt="" />
                </div>
                <p>Building MVP</p>
            </div>

            {/* div 4 */}
            <div
                className={`startup__min-img ${selected.includes('Ready MVP') ? 'selected' : ''}`}
                onClick={() => handleDivClick('Ready MVP')}
            >
                <div>
                    <img className='startup__min-img-size' src="./assets/mentor-form4.svg" alt="" />
                </div>
                <p>Ready MVP</p>
            </div>

            {/* div 5 */}
            <div
                className={`startup__min-img ${selected.includes('MVP in Market') ? 'selected' : ''}`}
                onClick={() => handleDivClick('MVP in Market')}
            >
                <div>
                    <img src="./assets/mentor-form5.svg" alt="" />
                </div>
                <p>MVP in Market</p>
            </div>

            {/* div 6 */}
            <div
                className={`startup__min-img ${selected.includes('Established in Market') ? 'selected' : ''}`}
                onClick={() => handleDivClick('Established in Market')}
            >
                <div>
                    <img className='startup__min-img-size' src="./assets/mentor-form6.svg" alt="" />
                </div>
                <p>Established in Market</p>
            </div>
        </>
    )
}

export default SelectImage