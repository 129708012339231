import React, { useEffect, useState } from "react";
import "./BookingSuccess.css";
import API_URL from "../../api/Api";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { DataState } from "../../context/DataProvider";
import jwtDecode from 'jwt-decode';

const BookingSuccess = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  var id = searchParams.get("id");
  var data = searchParams.get("data");

  const [paymentData, setPaymentData] = useState();
  const { accessToken } = DataState();
  const [getSchdule, setGetSchdule] = useState();
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const timeFormatter = (start_time, end_time) => {
    const startDateTime = new Date(start_time);
    const endDateTime = new Date(end_time);

    const formattedStartTime = startDateTime.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    const formattedEndTime = endDateTime.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  useEffect(() => {
    const getBookedSchdule = async () => {
      if (data) {
        try {
          setShowSuccessScreen(true);
          var USER_CREDENTIAL;
          USER_CREDENTIAL = jwtDecode(data);
          setPaymentData(USER_CREDENTIAL)
          console.log("User Credential:",USER_CREDENTIAL);
          if (USER_CREDENTIAL?.id?.bookingId) {
          // if (USER_CREDENTIAL?.bookingId) {
            const response = await axios.get(
              // `${API_URL}/api/bookMentorSlot/getschedule?bookingId=${USER_CREDENTIAL?.id?.bookingId}`
              `${API_URL}/api/session/getschedule?bookingId=${USER_CREDENTIAL?.id?.bookingId}`
              // `${API_URL}/api/session/getschedule?bookingId=${USER_CREDENTIAL?.bookingId}`
              ,

              {
                headers: {
                  Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                },
              }
            );
            setGetSchdule(response.data[0]);
            console.log(response.data[0]);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setTimeout(() => {
            setShowSuccessScreen(false);
          }, 5000);
        }
      }

      if (id) {
        try {
          const response = await axios.get(
            // `${API_URL}/api/bookMentorSlot/getschedule?bookingId=${id}`
            `${API_URL}/api/session/getschedule?bookingId=${id}`
            ,
            {
              headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
              },
            }
          );
          setGetSchdule(response.data[0]);
          console.log(response.data[0]);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }

    }
    getBookedSchdule()
  }, []);

  console.log(getSchdule)

  if (showSuccessScreen) {
    return (
      <div className="bookingLoad_confirm_body">
        <div className="mainLoad_details">
          <div className="bookingLoad__tick">
            <img src="/assets/payment tick.png" alt="" />
          </div>
          <h3>Your Payment is Successful</h3>
          <p>"Fantastic news! Your payment has gone through smoothly. We're thrilled to have you on board!"
          </p>

          <div className="bookingLoad__namaste">
            <img src="/assets/namaste.svg" alt="" />
          </div>

        </div>
      </div>
    );
  }

  return (
    <div className="booking_confirm_body">
      <div className="main_details">
        <div className="booking_heading">
          <div>
            <h3 className="booking_head">This meeting is scheduled</h3>
            <img src="/assets/paymentTick.png" alt="" />
          </div>
          <p>
            We emailed you and the other attendees a calendar invitation with all
            the details
          </p>
        </div>
        <div className="booking_details">
          <div>
            <h2 className="booking_head">What</h2>
            <p className="booking_text">
              30 Min Session between {getSchdule?.mentorDetails[0]?.name} and{" "}
              {getSchdule?.mentee[0]?.name}
            </p>
          </div>
          <div>
            <h2 className="booking_head">When</h2>
            <p className="booking_text">
              {timeFormatter(getSchdule?.start_time, getSchdule?.end_time)}
            </p>
          </div>
          <div>
            <h2 className="booking_head">When</h2>
            <p className="booking_text">
              {timeFormatter(getSchdule?.start_time, getSchdule?.end_time)}
            </p>
          </div>
          <div>
            <h2>Who</h2>
            <div className="booking_email_details">

              <div className="booking_email_details_name">
                <p className="booking_text">{getSchdule?.mentorDetails[0]?.name}</p>
                <div className="mentor_box_mentor">Mentor</div>
              </div>
              <p className="booking_text">{getSchdule?.mentorDetails[0]?.email}</p>
           
              <div className="booking_email_details_name">
                <p className="booking_text">{getSchdule?.mentee[0]?.name}</p>
                <div className="mentee_box_mentee">Mentee</div>
              </div>
              <p className="booking_text">{getSchdule?.mentee[0]?.email}</p>

            </div>
          </div>
        </div>
        <Link to="/mentordashboard">
          <div className="make_changes_cta">
            Need to make a change ? <span> Visit Dashboard</span>
          </div>
        </Link>
      </div>
      <div className="calender_icon">
        <img src="/assets/calendar.png" alt="" />
      </div>
    </div >
  );
};

export default BookingSuccess;
