import React, { useState, useEffect } from "react";
import axios from "axios";
import "./BasicDetails.css";
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation, useNavigate } from "react-router-dom";
import API_URL from "../../../api/Api";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import { Backdrop, CircularProgress } from "@mui/material";
import toast from "react-hot-toast";

const ChipSelector = ({ listItems, title, placeholderValue, selectedAreas, setSelectedAreas }) => {
  const [inputValue, setInputValue] = useState("");

  const Items1 = [
    "Upto 1 months",
    "Upto 6 months",
    "Upto 1 year",
  ];

  const handleChipClick = (area) => {
    if (selectedAreas.length < 5 && !selectedAreas.includes(area)) {
      setSelectedAreas((prevSelected) => [...prevSelected, area]);
    } else if (selectedAreas.length >= 5) {
      toast.error("You can select up to 5 items.");
    }
  };

  const handleChipRemove = (area) => {
    setSelectedAreas((prevSelected) => prevSelected.filter((c) => c !== area));
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      const area = inputValue.trim();
      if (selectedAreas.length < 5 && !selectedAreas.includes(area)) {
        setSelectedAreas((prevSelected) => [...prevSelected, area]);
        setInputValue("");
      } else if (selectedAreas.length >= 5) {
        toast.error("You can select up to 5 items.");
      }
    }
  };

  return (
    <div className="bd__form">
      <h1>{title}</h1>

      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
        placeholder={placeholderValue}
      />

      <div className="bd__chip-container">
        {selectedAreas.map((area) => (
          <>
            <div key={area} className="bd__chip selected">
              {area}
              <CloseIcon onClick={() => handleChipRemove(area)} sx={{ marginLeft: '.7rem', fontSize: '1.5rem' }} />
            </div>
          </>
        ))}
      </div>
      <div className="bd__area-list">
        {listItems.map((area) => (
          <div
            key={area}
            className={`bd__chip ${selectedAreas.includes(area) ? "disabled" : ""
              }`}
            onClick={() => handleChipClick(area)}
          >
            {area}
          </div>
        ))}
      </div>
    </div>
  )
}

// const BasicDetails = ({ onNext, trailSessionId, setTrailSessionId }) => {
const BasicDetails = ({ onNext , setSessionFormId}) => {
  const location = useLocation()
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Navigates to the previous page
  };

  const searchParams = new URLSearchParams(location.search);
  var userId = searchParams.get('userId');

  const [whyNeedMentorShip, setWhyNeedMentorShip] = useState();
  const [describeYourNeed, setDescribeYourNeed] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [selectedHowMentorship, setSelectedHowMentorship] = useState([]);
  const [selectedExpectedTimeline, setSelectedExpectedTimeline] = useState([]);
  const [sendLoading, setSendLoading] = useState(false);

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^(\+?\d{1,4}\s?)?(\d{10})$/;

    return phoneNumberRegex.test(phoneNumber);
  };

  const handleBasicDetails = async () => {
    try {
      if (!whyNeedMentorShip || !describeYourNeed || !selectedHowMentorship || !selectedExpectedTimeline || !mobileNumber) {
        toast.error("fill all fields first")
        return;
      }
      const phoneNumber = isValidPhoneNumber(mobileNumber);
      if (!phoneNumber) {
        toast.error("Enter Valid Phone No.");
        return;
      }
      setSendLoading(true)
      // const response = await axios.post(`${API_URL}/api/mentor/freetrial/create`
      const response = await axios.post(`${API_URL}/api/session/saveForm?sessionType=free`
      ,
        {
          mentor: userId,
          number: mobileNumber,
          whyNeedMentorShip: whyNeedMentorShip,
          describeYourNeed: describeYourNeed,
          howMentorship: selectedHowMentorship,
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
          }
        }
      )
      console.log(response.data.id)
      if (response.data.ok) {
        // setTrailSessionId(response.data.id)
        setSessionFormId(response.data.id);

        setSendLoading(false)
      onNext();
      }
    } catch (error) {
      console.log(error)
      setSendLoading(false)
    }
  }

  const areFieldsEmpty = () => {
    return (
      !whyNeedMentorShip ||
      !describeYourNeed ||
      !mobileNumber ||
      selectedHowMentorship.length === 0 ||
      selectedExpectedTimeline.length === 0
    );
  };

  return (
    <div className="bd__main">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={sendLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="bd__body">
        <div className="bd__progressBar">
          <div className="bd__back" onClick={goBack}>
            {/* Go Back */}
            <ArrowCircleLeftRoundedIcon sx={{ fontSize: "3.4rem" }} />
          </div>
          <div className='bd__progressBar-img'>
            <img
              src="./assets/progress bar 1.png"
              alt=""
            />
          </div>
        </div>

        <h1 className="bd__heading-main">
          Let Mentor know about your goals & problems and Vision
          <img className="imgTop" src="./assets/arrow.png" alt="" />
        </h1>

        <form className="bd__form-main">
          <div className="bd__form">
            <h1> Why you need mentorship?</h1>
            <textarea
              name="whyNeedMentorShip"
              placeholder="Ex: In Resume building / career mentorship / Interview preparations"
              value={whyNeedMentorShip}
              onChange={(e) => setWhyNeedMentorShip(e.target.value)}
              required
            />
          </div>

          <div className="bd__form">
            <h1>Please describe your need ?</h1>
            <textarea
              placeholder="Describe your need , Problems , Conflicts You are facing and  get Solved "
              name="describeYourNeed"
              value={describeYourNeed}
              onChange={(e) => setDescribeYourNeed(e.target.value)}
              required
            />
          </div>

          <div className="bd__form">
            <h1>Enter your number</h1>
            <input
              placeholder="Your number required to connected with you"
              name="describeYourNeed"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              required
            />
          </div>

          <ChipSelector selectedAreas={selectedHowMentorship} setSelectedAreas={setSelectedHowMentorship} listItems={Items} placeholderValue={'Include Your Own'} title='How do you want the mentor to help via long-term mentorship?*' />

          <ChipSelector selectedAreas={selectedExpectedTimeline} setSelectedAreas={setSelectedExpectedTimeline} listItems={Items1} placeholderValue={'Include Your Own'} title='Your expected Mentorship timeline?*' />

          {areFieldsEmpty() ? (
            // If fields are empty, disable the div
            <div className="bd__submit disabled">
              <div className='disabledTrialBtn'>
                <p className="btn_schedule">Schedule</p>
                <img className="imgBtn" src="./assets/maki_arrow.png" alt="" />
              </div>
            </div>
          ) : (
            // If fields have values, enable the div
            <div className="bd__submit" onClick={() => {
              handleBasicDetails();
            }}>
              <div>
                <p className="btn_schedule">Schedule</p>
                <img className="imgBtn" src="./assets/maki_arrow.png" alt="" />
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default BasicDetails;

const Items = [
  "Clear path",
  "Solve doubts",
  "Connections",
  "Job & Referrals",
  // "Social Impact",
  // "Legal Terms",
  // "Consultation & End to End Support",
];

const Items1 = [
  "Upto 1 week",
  "Upto 1 months",
  "Upto 6 months",
  "Upto 1 year",
];