import React from "react";
import { Link } from "react-router-dom";
import "./css/ProfileSays.css";

const ProfileSays = ({ mentorData, checkFreeSessions }) => {
  return (
    <>
      <div className="profile__says-main">
        <div className="profile__says">
          <div className="profile__says-left">
            <h1>
              What Founder mentees are saying about{" "}
              <span>{mentorData?.user?.name}</span>
            </h1>
            <div className="profile__says-left-stats">
              <div className="profile__says-left-stats-border">
                <h1>{mentorData?.rating}</h1>
                <p>TOTAL RATING</p>
              </div>
              <div className="profile__says-left-stats-border">
                <h1>{mentorData?.mentorshipProvided}+</h1>
                <p>SESSIONS</p>
              </div>
              <div>
                <h1>{mentorData?.mentorshipProvided}+</h1>
                <p>NO OF MENTEES</p>
              </div>
            </div>
            <p>
              Get clarity about how the mentor will personally help you achieve
              your goals over the next few months.
            </p>

            {mentorData?.paidOptions === "true" ? (
              <Link to={`/book-sessions?userId=${mentorData._id}`}>
                <div className="profile__says-left-trial">
                  Get Session<i className="fa-solid fa-play"></i>
                </div>{" "}
              </Link>
            ) : (
              <>
                {checkFreeSessions?.freeSession ? (
                  <Link to={`/book-free-sessions?userId=${mentorData._id}`}>
                    <div className="profile__says-left-trial">
                      free trial session<i className="fa-solid fa-play"></i>
                    </div>{" "}
                  </Link>
                ) : (
                  <Link to={`/book-sessions?userId=${mentorData._id}`}>
                    <div className="profile__says-left-trial">
                      Get Session<i className="fa-solid fa-play"></i>
                    </div>{" "}
                  </Link>
                )
                }
              </>
            )}

          </div>
          <div className="profile__says-right">
            <div>
              <p>
                The mentorship session covered a diverse range of topics, including career advice, open source contributions, job applications, and interviews. Each topic was addressed with depth and clarity, providing me with a wealth of knowledge that I can apply to my own career development.
                <br />
                What stood out most was the genuine interest Satyam Bhalla showed in understanding my specific concerns and aspirations.
                <br />
                I wholeheartedly recommend Satyam Bhalla as a mentor, and I am thankful for the positive and enriching experience they provided.
              </p>
              <h3>-Harivansh</h3>
            </div>
            <div>
              <p>
                He was great at analysing my whole portfolio. And in the first
                call only, he had given me a lot of points to improve. It was
                great interacting with him.
              </p>
              <h3>-Daman kumar</h3>
            </div>
            <div>
              <p>
                He was great at analysing my whole portfolio. And in the first
                call only, he had given me a lot of points to improve. It was
                great interacting with him.
              </p>
              <h3>-sidharth goyal</h3>
            </div>
            <div>
              <p>
                He was great at analysing my whole portfolio. And in the first
                call only, he had given me a lot of points to improve. It was
                great interacting with him.
              </p>
              <h3>-Gurpreet Singh</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSays;
