import React, { useEffect, useState } from "react";
import "./EventsCard.css";
import Avatar from "@mui/material/Avatar";
import API_URL from "../../api/Api";
import axios from "axios";
import { DataState } from "../../context/DataProvider";
import { Backdrop, CircularProgress } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { MdEventAvailable } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

const EventsCard = ({ event, isCreateEvent = false }) => {
  const { user } = DataState();

  const dateTime = new Date(event?.date);

  // Get date in the format YYYY-MM-DD
  const date = dateTime.toISOString().split("T")[0];

  // Get time in the format HH:mm AM/PM
  const options = { hour: "numeric", minute: "2-digit" };
  const time = dateTime.toLocaleTimeString(undefined, options);

  // Get date in the format "day month year"
  const options1 = { day: "numeric", month: "long", year: "numeric" };
  const date2 = dateTime.toLocaleDateString(undefined, options1);

  const [eventLoading, setEventLoading] = useState(false);

  const [isBooked, setIsBooked] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const handleBookEvent = async () => {
    try {
      setEventLoading(true);
      const data = {
        eventId: event._id,
      };

      const response = await axios.post(
        `${API_URL}/api/events/bookEvent`,
        data,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
      // console.error("Error:", response.data);
      setIsBooked(true);
      setShowSuccess(true);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setEventLoading(false);
      toast.success("Registered successfully", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={eventLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="eventcard">
        <Toaster />
        <div>
          <div className="eventcard__tophead">
            <img src="./assets/logo.svg" alt="" />
            <h1>inQbator | Level-Up</h1>
          </div>

          <div
            className="eventcard__bio"
            style={{ backgroundColor: `${event?.color}` }}
          >
            <div className="eventcard__bio-img">
              <Avatar
                src={event?.image}
                sx={{ height: "9rem", width: "9rem" }}
              />
            </div>
            <h3>{event?.title}</h3>
            <h1>{event?.tag}</h1>
            <div className="eventscard__bio-bottom">
              <div className="eventscard__bio-bottom1">
                <h3>
                  <span>Date: </span>
                  {date}
                </h3>
                <h3>
                  <span>Time: </span>
                  {time}
                </h3>
              </div>
              <div className="eventscard__bio-bottom2">
                <h3>{event?.name}</h3>
                <p>{event?.profession}</p>
              </div>
            </div>
          </div>

          <div className="eventcard__head">
            <h1>
              {(event?.tag).slice(0, 100)}
              {(event?.tag).length > 100 && " . . ."}
            </h1>
          </div>
        </div>

        <div className="eventcard__btm">
          <div className="eventcard__mentor">
            <div className="eventcard__mentor-img">
              <Avatar
                src={event?.image}
                sx={{ height: "5rem", width: "5rem" }}
              />
            </div>
            <div className="eventcard__mentor-text">
              <h1>{event?.name}</h1>
              <p>{event?.profession}</p>
            </div>
          </div>
          <div className="eventcard__btn">
            <div className="eventcard__btn-time">
              <img src="./assets/events-calender.svg" alt="" />
              <h1>
                {date2}, {time}
              </h1>
            </div>
            {event?.attendees.includes(user?._id) || isBooked ? (
              <div
                className="eventcard__btn-main bg-[#97214a]"
                onClick={() => {
                  toast("Already registered!", {
                    style: {
                      borderRadius: "10px",
                      background: "#333",
                      color: "#fff",
                    },
                  });
                }}
              >
                Registered
              </div>
            ) : isCreateEvent ? (
              <div className="eventcard__btn-main cursor-default">Register</div>
            ) : (
              <div
                className={`eventcard__btn-main ${
                  isBooked ? "bg-[#97214a]" : ""
                }`}
                onClick={handleBookEvent}
              >
                {isBooked ? "Registered" : "Register"}
              </div>
            )}

            {showSuccess && (
              <div className="fixed inset-0 bg-gray-900 bg-opacity-10 flex items-center justify-center z-50">
                <div
                  onClick={() => setShowSuccess(false)}
                  className="bg-black bg-opacity-50 fixed inset-0 backdrop-blur-sm"
                ></div>
                <div className="bg-gray-800 rounded-3xl p-8 max-w-md w-full relative">
                  <RxCross2
                    onClick={() => setShowSuccess(false)}
                    className="text-3xl absolute top-5 right-5"
                  />
                  <div className="flex flex-col items-center">
                    <MdEventAvailable className="text-9xl text-green-500" />
                    <h2 className="text-3xl text-center text-white">
                      You are successfully registered for the event.
                    </h2>
                    <h2 className="text-xl text-center text-white">
                      An email has been sent to your registered account.
                    </h2>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsCard;
