import React, { useEffect, useState } from 'react'
import './Answers.css'
import { Avatar } from '@mui/material'
import API_URL from '../../api/Api'
import axios from 'axios'
import { DataState } from '../../context/DataProvider'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLocation } from 'react-router-dom'
import CommentCard from './CommentCard'

const Answers = ({ ans }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    var queId = searchParams.get("queId");

    const { user, accessToken } = DataState()
    const [userData, setUserData] = useState(null);
    const [disliked, setDisliked] = useState(false);
    const [showAnswers, setShowAnswers] = useState(false);
    const [likeAndDislike, setLikeAndDislike] = useState(false);

    // To fetch user info of particular answer
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/particular-user-data/${ans?.user}`);
                setUserData(response.data.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [ans?.user]);


    // To handle the reply operation
    const [replyInputText, setReplyInputText] = useState('');
    const [openReply, setOpenReply] = useState(false);

    const handleReplyClick = () => {
        setOpenReply(!openReply);
        if (!openReply) {
            setReplyInputText('');
        }
    };

    const handleReplyInputChange = (e) => {
        setReplyInputText(e.target.value);
    };

    // To handle the like and dislike operation
    const handleLikeAndDislike = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/question/answers/getLikeAndDislike/${ans._id}`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            });
            setLikeAndDislike(response.data.data[0])
        } catch (error) {
            console.log(error)
        }
    };

    const handleLike = async () => {
        try {
            const response = await axios.post(`${API_URL}/api/question/answers/like/${ans._id}`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            handleLikeAndDislike()
        } catch (error) {
            console.log(error)
        }
    }

    const handleDislike = async () => {
        try {
            const response = await axios.post(`${API_URL}/api/question/answers/dislike/${ans._id}`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            handleLikeAndDislike()
        } catch (error) {
            console.log(error)
        }
    };
    
    useEffect(() => {
        handleLikeAndDislike()
    }, [])

    const [comments, setComments] = useState([]);
    const [commentsLoading, setCommentsLoading] = useState(false);
    const fetchComments = async () => {
        try {
            setCommentsLoading(true)
            const response = await axios.get(`${API_URL}/api/question/comment/getComment/${ans?._id}`, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
                }
            });
            console.log("comments", response);
            setComments(response.data.data);
            setCommentsLoading(false)
        } catch (error) {
            console.error('Error fetching categories:', error);
            setCommentsLoading(false)
        }
    };

    // To handle the copy operation
    const handleCommentReply = async () => {
        try {
            const response = await axios.post(`${API_URL}/api/question/comment`, {
                message: replyInputText,
                questionId: queId,
                answerId: ans?._id,
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            fetchComments()
            handleReplyClick()
            setReplyInputText('')
            // handleLikeAndDislike()
        } catch (error) {
            console.log(error)
        }
    }
    const toggleAnswers = () => {
        setShowAnswers((prevShowAnswers) => !prevShowAnswers);
    };

    useEffect(() => {
        fetchComments();
    }, []);

    const [showFullText, setShowFullText] = useState(false);

    const toggleFullText = () => {
        setShowFullText(!showFullText);
    };

    // const ans = { message: "Your long message here..." };

    return (
        <>
            <div className="answers__main">
                <div className="answers__ans">
                    <h3>Answers</h3>
                    <code>
                        {/* {ans?.message.slice(0, 500)} {ans?.message.length > 500 && <span> . . .(read more)</span>} */}
                        {showFullText ? ans?.message : ans?.message.slice(0, 500)}
                        {ans?.message.length > 500 && (
                            <span onClick={toggleFullText}>
                                {showFullText ? " . . .(show less)" : " . . .(read more)"}
                            </span>
                        )}
                    </code>
                </div>
                <div className="answers__profile-main">
                    <div className='answers__profile'>
                        <div className='answers__profile-view'>
                            <Avatar src={userData?.picture}
                                sx={{ width: "6rem", height: "6rem" }}
                            ></Avatar>
                            <div>
                                <h2>{userData?.name}</h2>
                                <div>
                                    <div>
                                        <img src="./assets/ms-logo.svg" alt="" />
                                    </div>
                                    <div>
                                        <h2>Microsoft</h2>
                                        <p>UI/UX Designer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='answers__profile-bio'>
                            <p>As an experienced Senior Product Manager with a rich history of shaping product lifecycles, added product design experience, and empathy driven approach, I'm here to offer my expertise and support to <span> . . .</span></p>
                        </div>
                    </div>
                    <h2>
                        View Profile
                    </h2>
                </div>

                <div className="answers__reply-main">
                    <div className="answers__reply-btn" onClick={() => setOpenReply(ans?._id)}>
                        <p>Reply</p>
                    </div>
                    <div className="answers__reply">
                        <div className='answers__reply-like'>
                            {!likeAndDislike?.likes?.includes(user?._id) ? (
                                <ThumbUpOffAltIcon
                                    onClick={handleLike}
                                    sx={{ fontSize: '2.5rem' }}
                                />
                            ) : (
                                <ThumbUpAltIcon
                                    onClick={handleLike}
                                    sx={{
                                        fontSize: '2.5rem',
                                    }}
                                />
                            )}
                            <span>{likeAndDislike?.likes?.length}</span>
                        </div>
                        <div className='answers__reply-dislike'>
                            {!likeAndDislike?.dislikes?.includes(user?._id) ? (
                                <ThumbDownOffAltIcon
                                    onClick={handleDislike}
                                    sx={{
                                        fontSize: '2.5rem',
                                    }}
                                />
                            ) : (
                                <ThumbDownAltIcon
                                    onClick={handleDislike}
                                    sx={{
                                        fontSize: '2.5rem',
                                    }}
                                />
                            )}
                            <span>{likeAndDislike?.dislikes?.length}</span>
                        </div>
                    </div>

                </div>

                {openReply === ans?._id ? (<div className="ccard__reply">
                    <Avatar src={user?.picture} sx={{ width: '3.4rem', height: '3.4rem' }} />
                    <div className='ccard_reply-input'>
                        <input type='text' placeholder='Add a reply' value={replyInputText} onChange={handleReplyInputChange} />
                        <div>
                            <span onClick={handleReplyClick}>Cancel</span>
                            <span onClick={handleCommentReply}>Reply</span>
                        </div>
                    </div>
                </div>) : ('')}


                <div className='answers__btm'>
                    {comments?.length === 0 ?
                        (<></>
                            // <div className='answers__hideComment'>
                            //     <p>No Answers | Start answering with reply button</p>
                            // </div>
                        ) : (
                            <>
                                <div onClick={toggleAnswers} className='answers__hideComment'>
                                    <ArrowDropDownIcon sx={{ fontSize: '2.5rem' }} />
                                    <p>{!showAnswers ? (`${comments?.length} ${comments?.length === 1 ? 'reply' : "replies"}`) : ("Hide Answers")}</p>
                                </div>
                                <div className={`answers__comment-container ${showAnswers ? `` : `closed`}`}>
                                    {comments?.map((com) => (
                                        <>
                                            <CommentCard key={com._id} com={com} />
                                        </>
                                    ))}
                                </div>
                            </>
                        )}
                </div>
            </div >
        </>
    )
}

export default Answers