import React, { useState, useEffect } from "react";
import { Footer, ScrollTop } from "../../components";
import "./MentorDashboard.css";
import StarIcon from "@mui/icons-material/Star";
import { Avatar, Modal } from "@mui/material";
import EditAvatar from "./Avatar/EditAvatar";
import EditAbout from "./About/EditAbout";
import EditSkills from "./Skills/EditSkills";
import EditIntro from "./Intro/EditIntro";
import AddEducation from "./Education/AddEducation";
import EditEducation from "./Education/EditEducation";
import AddExperience from "./Experience/AddExperience";
import EditExperience from "./Experience/EditExperience";
import API_URL from "../../api/Api";
import axios from "axios";
import Navbar2 from "../../components/Navbar2";
import RescheduleModal from "./RescheduleModal";
import { Link } from "react-router-dom";
import DeleteChatModal from "../InqAi/DeleteChatModal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import CreateIcon from "@mui/icons-material/Create";

import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
import TimeSlotSelectionModal from "./TimeSlotSelectionModal";
import toast from "react-hot-toast";
import CreateEvent from "../Events/CreateEvent";

const MentorSlotForm = () => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [gMeetLink, setGMeetLink] = useState("");
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await axios.post(
        `${API_URL}/api/mentorAvailableSlot/add`,
        {
          start_time: startTime,
          end_time: endTime,
          gMeetLink,
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        }
      );

      setSuccessMsg(response.data.msg);
      // Clear form fields after successful submission
      setStartTime("");
      setEndTime("");
      setGMeetLink("");
    } catch (error) {
      setError(error.response.data.msg);
    }
  };

  return (
    <div className="bg-gray-900 text-white p-6">
      <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
        <label htmlFor="startTime" className="text-gray-300">
          Start Time:
        </label>
        <input
          type="datetime-local"
          id="startTime"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          className="bg-gray-800 text-white p-2 rounded"
        />

        <label htmlFor="endTime" className="text-gray-300">
          End Time:
        </label>
        <input
          type="datetime-local"
          id="endTime"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          className="bg-gray-800 text-white p-2 rounded"
        />

        <label htmlFor="gMeetLink" className="text-gray-300">
          Google Meet Link:
        </label>
        <input
          type="text"
          id="gMeetLink"
          value={gMeetLink}
          onChange={(e) => setGMeetLink(e.target.value)}
          className="bg-gray-800 text-white p-2 rounded"
        />

        <button
          type="submit"
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Submit
        </button>
      </form>

      {error && <p className="text-red-500 mt-4">Error: {error}</p>}
      {successMsg && <p className="text-green-500 mt-4">{successMsg}</p>}
    </div>
  );
};

const CircularProgressBar = ({ value }) => {
  const radius = 15; // The radius of the circular progress bar
  const circumference = 2 * Math.PI * radius; // Calculate the circumference

  // Calculate the stroke dashoffset to animate the progress
  const strokeDashoffset = circumference - (value / 100) * circumference;

  return (
    <svg
      className="circular-progress-bar"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="circular-progress-bar__background"
        cx="30"
        cy="30"
        r={radius}
      />
      <circle
        className="circular-progress-bar__fill"
        cx="30"
        cy="30"
        r={radius}
        style={{
          strokeDasharray: circumference,
          strokeDashoffset: strokeDashoffset,
        }}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        fontFamily="Poppins"
        fontSize="10"
        fill="rgba(255, 255, 255, 0.47)"
      >
        {value}
      </text>
    </svg>
  );
};

const DateTimeConverter = ({ utcTimestamp }) => {
  const convertUtcToIst = (utcTimestamp) => {
    // Create a Date object from the UTC timestamp
    const utcDate = new Date(utcTimestamp);

    // Convert to IST (Indian Standard Time, UTC+05:30)
    utcDate.setHours(utcDate.getHours()); // Add 5 hours
    utcDate.setMinutes(utcDate.getMinutes()); // Add 30 minutes

    // Format the date and time
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      day: "numeric",
      month: "short",
      year: "numeric",
      // timeZoneName: "short",
    };

    return utcDate.toLocaleDateString("en-US", options);
  };

  const formattedDateTime = convertUtcToIst(utcTimestamp);

  return <>{formattedDateTime}</>;
};

const CountdownTimer = ({ targetUtcTime }) => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const targetTime = new Date(targetUtcTime).getTime();
    const timeDifference = targetTime - now;

    if (timeDifference <= 0) {
      // The target time has already passed
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetUtcTime]);

  // Generate the formatted time string
  const formattedTime = `${
    timeRemaining.days > 0 ? `${timeRemaining.days}d` : ""
  } ${timeRemaining.hours > 0 ? `${timeRemaining.hours}h` : ""} ${
    timeRemaining.minutes > 0 ? `${timeRemaining.minutes}m` : ""
  } ${timeRemaining.seconds > 0 ? `${timeRemaining.seconds}s` : "0s"}`;

  return <div>Your next session is in {formattedTime}</div>;
};

const SlotForm = () => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [gMeetLink, setGMeetLink] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const slotData = {
      start_time: startTime,
      end_time: endTime,
      gMeetLink: gMeetLink,
    };

    // Example using fetch:
    fetch("/api/create-slot", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(slotData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle response if needed
        console.log(data);
      })
      .catch((error) => {
        // Handle error if any
        console.error("Error:", error);
      });
  };

  return (
    <div className="max-w-md mx-auto">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="startTime" className="block font-medium">
            Start Time
          </label>
          <input
            type="datetime-local"
            id="startTime"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300"
            required
          />
        </div>
        <div>
          <label htmlFor="endTime" className="block font-medium">
            End Time
          </label>
          <input
            type="datetime-local"
            id="endTime"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300"
            required
          />
        </div>
        <div>
          <label htmlFor="gMeetLink" className="block font-medium">
            Google Meet Link
          </label>
          <input
            type="text"
            id="gMeetLink"
            value={gMeetLink}
            onChange={(e) => setGMeetLink(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300"
            required
          />
        </div>
        <div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
          >
            Create Slot
          </button>
        </div>
      </form>
    </div>
  );
};

const MentorDashboard = () => {
  const storedRole = localStorage.getItem("selectedRole");
  // Avatar section
  const [user, setUser] = useState();
  const [openEditAvatar, setOpenEditAvatar] = useState(false);
  const [openEditIntro, setOpenEditIntro] = useState(false);
  const [openTimeSlotSelection, setOpenTimeSlotSelection] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/user-info`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        });
        setUser(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUser(null);
      }
    };
    fetchUserData();
  }, [openEditAvatar, openEditIntro]);

  // Intro section
  const [introduction, setIntroduction] = useState();
  useEffect(() => {
    const fetchIntroData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/mentor`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        });
        if (response.data.ok === true) {
          setIntroduction(response.data.data);
        }
      } catch (error) {
        console.log("about", error);
      }
    };
    fetchIntroData();
  }, [openEditAvatar, openEditIntro]);

  // About section
  const [about, setAbout] = useState("");
  const [openEditAbout, setOpenEditAbout] = useState(false);
  useEffect(() => {
    const fetchABoutData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/mentor/about/getone`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        });
        setAbout(response.data.about);
      } catch (error) {
        console.log("about", error);
      }
    };
    fetchABoutData();
  }, [openEditAbout]);

  // Introduction section
  const [skills, setSkills] = useState([]);
  const [openEditSkills, setOpenEditSkills] = useState(false);
  useEffect(() => {
    const fetchSkillsData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/mentor/skills/getone`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("token")
              )}`,
            },
          }
        );
        setSkills(response.data.skills);
      } catch (error) {
        console.log("about", error);
      }
    };
    fetchSkillsData();
  }, [openEditSkills]);

  // Education seection
  const [education, setEducation] = useState([]);
  const [openAddEducation, setOpenAddEducation] = useState(false);
  const [openEditEducation, setOpenEditEducation] = useState(false);

  useEffect(() => {
    const fetchEducationData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/mentor/education/getone`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("token")
              )}`,
            },
          }
        );
        // console.log("Education", response.data);
        setEducation(response.data.data);
      } catch (error) {
        console.log("education", error);
      }
    };
    fetchEducationData();
  }, [openAddEducation, openEditEducation]);

  //Experience
  const [experience, setExperience] = useState([]);
  const [openAddExperience, setOpenAddExperience] = useState(false);
  const [openEditExperience, setOpenEditExperience] = useState(false);
  const [openReschedule, setOpenReschedule] = useState(false);
  const [openCancelSlot, setOpenCancelSlot] = useState(false);

  useEffect(() => {
    const fetchExperienceData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/mentor/experience/getone`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(
                localStorage.getItem("token")
              )}`,
            },
          }
        );
        setExperience(response.data.data);
      } catch (error) {
        console.log("Experience", error);
      }
    };
    fetchExperienceData();
  }, [openAddExperience, openEditExperience]);

  const [sessionData, setSessionData] = useState([]);

  const fetchFutureSessionData = async () => {
    try {
      const response = await axios.get(
        // `${API_URL}/api/bookMentorSlot/getFutureBookings`
        `${API_URL}/api/session/getFutureBookings`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
      console.log(response.data);
      setSessionData(response.data);
    } catch (error) {
      console.log("Experience", error);
    }
  };
  useEffect(() => {
    fetchFutureSessionData();
  }, [openReschedule, openCancelSlot]);

  const [pastSessionData, setPastSessionData] = useState([]);
  const fetchPastSessionData = async () => {
    try {
      const response = await axios.get(
        // `${API_URL}/api/bookMentorSlot/getPastBookings`
        `${API_URL}/api/session/getPastBookings`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
      setPastSessionData(response.data);
    } catch (error) {
      console.log("Experience", error);
    }
  };
  useEffect(() => {
    fetchPastSessionData();
  }, [openReschedule, openCancelSlot]);

  const [mentorSessionData, setMentorSessionData] = useState([]);
  const fetchMentorSessionData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/session/getMentorBooking?mentorId=${introduction?._id}`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
      setMentorSessionData(response.data);
    } catch (error) {
      console.log("Experience", error);
    }
  };
  useEffect(() => {
    if (introduction?._id) {
      fetchMentorSessionData();
    }
  }, [introduction?._id]);

  const handleCancelSlot = async (cancelScheduleId) => {
    try {
      const response = await axios.delete(
        // `${API_URL}/api/bookMentorSlot/delete/${cancelScheduleId}`
        `${API_URL}/api/session/delete/${cancelScheduleId}`,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("token")
            )}`,
          },
        }
      );
      fetchFutureSessionData();
      fetchPastSessionData();
      toast.success("Booking canceled");
    } catch (error) {
      console.log("Experience", error);
      toast.error("Error while cancel booking");
    }
  };

  const [linkToCopy, setLinkToCopy] = useState(
    `https://inqbator.in//profile?userId=${introduction?._id}`
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openEventCreate, setEvenCreate] = useState(false);

  const handleOpenEvent = () => {
    setEvenCreate(true);
  };

  const handleCloseEvent = () => {
    setEvenCreate(false);
  };

  console.log("introduction", introduction);
  console.log("mentorSessionData", mentorSessionData);

  return (
    <>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={availSlotLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Navbar2 />
      <div className="mentordash__wrapper-main">
        <div className="mentordash__wrapper">
          <div className="mentordash__body pb-10">
            <div className="mentordash__"></div>

            {/* -------Main Profile------- */}
            <div className="mentordash__main">
              <div className="mentordash__main-banner"></div>
              <div className="mentordash__main-about">
                <span onClick={() => setOpenEditIntro(true)}>
                  <img src="./assets/black-pen.svg" alt="" />
                </span>
                <div className="mentordash__modal">
                  <Modal
                    open={openEditIntro}
                    onClose={() => setOpenEditIntro(false)}
                  >
                    <EditIntro
                      intro={introduction}
                      user={user}
                      onClose={() => setOpenEditIntro(false)}
                    />
                  </Modal>
                </div>
                <div className="mentordash__main-about-left">
                  <div
                    className="mentordash__main-about-left-img"
                    onClick={() => setOpenEditAvatar(true)}
                  >
                    <Avatar
                      src={user?.picture}
                      sx={{ width: "100%", height: "100%" }}
                    />
                  </div>

                  <div className="mentordash__modal">
                    <Modal
                      open={openEditAvatar}
                      onClose={() => setOpenEditAvatar(false)}
                    >
                      <EditAvatar
                        avatar={user?.picture}
                        onClose={() => setOpenEditAvatar(false)}
                      />
                    </Modal>
                  </div>

                  <h1>{user?.name}</h1>
                  {introduction && (
                    <>
                      <h3>{introduction?.address}</h3>

                      <div className="mentordash__main-about-left-comp">
                        <span className="mr-3">
                          <img src={introduction?.companyImage} alt="" />
                        </span>
                        {introduction?.jobRole}
                      </div>
                      <div className="mentordash__main-about-left-followup">
                        <div className="mentordash__main-about-left-followup-icon">
                          <img src="./assets/profile-phone.svg" alt="" />
                        </div>
                        <div className="mentordash__main-about-left-followup-text">
                          <p>{introduction?.sessions} session(s)/week</p>
                        </div>
                      </div>
                      <div className="mentordash__main-about-left-followup">
                        <div className="mentordash__main-about-left-followup-icon">
                          <img src="./assets/profile-tick.svg" alt="" />
                        </div>
                        <div className="mentordash__main-about-left-followup-text">
                          <p>
                            Tasks & Followup in Every {introduction?.followup}{" "}
                            days
                          </p>
                        </div>
                      </div>

                      <h2>{introduction?.experience}+ Years of Experience</h2>
                      <div className="mentordash__main-about-left-btn">
                        <div className="mentordash__main-about-left-btn-stat">
                          <StarIcon fontSize="30px" /> {introduction?.rating}
                        </div>
                        <div
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          className="mentordash__main-about-left-btn-button"
                        >
                          <span>
                            <img src="./assets/share.svg" alt="" />
                          </span>{" "}
                          Share Profile
                        </div>
                      </div>
                      <CreateEvent
                        open={openEventCreate}
                        setOpen={handleOpenEvent}
                        handleClose={handleCloseEvent}
                      />
                      {storedRole === "mentor" && (
                        <button
                          onClick={handleOpenEvent}
                          className="events__create"
                        >
                          Create Event
                          <CreateIcon />
                        </button>
                      )}

                      <div>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem
                            onClick={handleClose}
                            sx={{ padding: "0px" }}
                          >
                            <LinkedinShareButton url={linkToCopy}>
                              <div className="qcard__reply-share-items">
                                <LinkedInIcon sx={{ fontSize: "2.5rem" }} />
                                <span>Share on Linkedin</span>
                              </div>
                            </LinkedinShareButton>
                          </MenuItem>
                          <MenuItem
                            onClick={handleClose}
                            sx={{ padding: "0px" }}
                          >
                            <TwitterShareButton url={linkToCopy}>
                              <div className="qcard__reply-share-items">
                                <TwitterIcon sx={{ fontSize: "2.5rem" }} />
                                <span>Share on Twitter</span>
                              </div>
                            </TwitterShareButton>
                          </MenuItem>
                          <MenuItem
                            onClick={handleClose}
                            sx={{ padding: "0px" }}
                          >
                            <FacebookShareButton url={linkToCopy}>
                              <div className="qcard__reply-share-items">
                                <FacebookIcon sx={{ fontSize: "2.5rem" }} />
                                <span>Share on Facebook</span>
                              </div>
                            </FacebookShareButton>
                          </MenuItem>
                        </Menu>
                      </div>
                    </>
                  )}
                </div>

                <div className="mentordash__main-about-right">
                  {introduction && (
                    <>
                      <div>
                        <h1>
                          <span>
                            <img src="./assets/area-black.svg" alt="" />
                          </span>{" "}
                          For:
                        </h1>
                        {introduction?.areaOfMentorship.map((str, index) => (
                          <span key={index}>
                            {str}
                            {index <
                              introduction?.areaOfMentorship.length - 1 &&
                              " | "}
                          </span>
                        ))}
                      </div>

                      <div>
                        <h1>
                          <span style={{ lineHeight: ".7rem" }}>
                            <img src="./assets/category-black.svg" alt="" />
                          </span>{" "}
                          Target Sector:
                        </h1>
                        {introduction?.targetSector.map((str, index) => (
                          <span key={index}>
                            {str}
                            {index < introduction?.targetSector.length - 1 &&
                              " | "}
                          </span>
                        ))}
                        {/* <span >{introduction?.targetSector}</span> */}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* ---------About section---------- */}
            <div className="mentordash__section">
              <h1>
                About:
                <span
                  onClick={() => setOpenEditAbout(true)}
                  className="cursor-pointer"
                >
                  <img src="./assets/white-pen.svg" alt="" />
                </span>
              </h1>
              <div className="mentordash__modal">
                <Modal
                  open={openEditAbout}
                  onClose={() => setOpenEditAbout(false)}
                >
                  <EditAbout
                    about={about}
                    setAbout={setAbout}
                    setOpenEditAbout={setOpenEditAbout}
                    onClose={() => setOpenEditAbout(false)}
                  />
                </Modal>
              </div>
              <code>{about}</code>
              {!about && <p className="metordash__noData">No About Data</p>}
            </div>

            {/* ---------Skills section---------- */}
            <div className="mentordash__section">
              <h1>
                Skills & Experties:
                <span className="cursor-pointer">
                  <img
                    src="./assets/white-pen.svg"
                    alt=""
                    onClick={() => setOpenEditSkills(true)}
                  />
                </span>
              </h1>
              <div className="mentordash__modal">
                <Modal
                  open={openEditSkills}
                  onClose={() => setOpenEditSkills(false)}
                >
                  <EditSkills
                    skills={skills}
                    setSkills={setSkills}
                    onClose={() => setOpenEditSkills(false)}
                  />
                </Modal>
              </div>

              <div className="mentordash__skills-container">
                {skills?.map((skill) => (
                  <div>{skill}</div>
                ))}
              </div>
              {skills?.length === 0 && (
                <p className="metordash__noData">No skills Added</p>
              )}
            </div>

            {storedRole === "mentor" && (
              <div className="mentordash__section">
                <h1>Select When you are available:</h1>
                <div className="flex flex-row item-center justify-center w-full mt-6 mb1-16 text">
                  <button
                    className="bg-white text-black py-4 px-8 rounded-full w-[20] font-bold"
                    onClick={() => setOpenTimeSlotSelection(true)}
                  >
                    Set Available slots
                  </button>
                </div>
                <div className="mentordash__modal">
                  <Modal
                    open={openTimeSlotSelection}
                    onClose={() => setOpenTimeSlotSelection(false)}
                  >
                    <TimeSlotSelectionModal
                      userId={introduction?._id}
                      onClose={() => setOpenTimeSlotSelection(false)}
                    />
                  </Modal>
                </div>
                {/* <SlotSelection
                userId={introduction?._id}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
              />
             */}
              </div>
            )}

            {/* ---------Education section---------- */}
            <div className="mentordash__section">
              <h1>
                Education:
                <i
                  className="fa-solid fa-plus"
                  onClick={() => setOpenAddEducation(true)}
                />
              </h1>

              <div className="mentordash__modal">
                <Modal
                  open={openAddEducation}
                  onClose={() => setOpenAddEducation(false)}
                >
                  <AddEducation onClose={() => setOpenAddEducation(false)} />
                </Modal>
              </div>

              <div className="metordash__edu-container">
                {education?.map((edu) => (
                  <div>
                    <div key={edu._id}>
                      <div className="metordash__edu-container-img">
                        <div>
                          <img src={edu?.schoolImage} alt="" />
                        </div>
                      </div>
                      <div className="metordash__edu-container-text">
                        <h1>{edu.school}</h1>
                        <h2>
                          {edu.degree} - {edu.fieldOfStudy}
                        </h2>
                        <p>
                          {edu.startYear} - {edu.endYear}
                        </p>
                      </div>
                      <div className="mentordash__modal">
                        <Modal
                          open={openEditEducation === edu._id}
                          onClose={() => setOpenEditEducation(null)}
                        >
                          <EditEducation
                            edu={edu}
                            onClose={() => setOpenEditEducation(null)}
                          />
                        </Modal>
                      </div>
                    </div>
                    <img
                      src="./assets/white-pen.svg"
                      alt=""
                      onClick={() => setOpenEditEducation(edu._id)}
                    />
                  </div>
                ))}

                {education?.length === 0 && (
                  <p className="metordash__noData">No education Added</p>
                )}
              </div>
            </div>

            {/* ---------Experience section---------- */}
            <div className="mentordash__section">
              <h1>
                Experience:
                <span>
                  <i
                    className="fa-solid fa-plus"
                    onClick={() => setOpenAddExperience(true)}
                  />
                </span>
              </h1>
              <div className="mentordash__modal">
                <Modal
                  open={openAddExperience}
                  onClose={() => setOpenAddExperience(false)}
                >
                  <AddExperience onClose={() => setOpenAddExperience(false)} />
                </Modal>
              </div>

              <div className="metordash__exp-container">
                {experience?.map((experience) => (
                  <div>
                    <div key={experience.id}>
                      <div className="metordash__exp-container-img">
                        <div>
                          <img src={experience.companyImage} alt="" />
                        </div>
                      </div>
                      <div className="metordash__exp-container-text">
                        <h1>{experience.title}</h1>
                        <h2>
                          {experience.companyName} - {experience.employmentType}
                        </h2>
                        <h2>
                          {experience.startMonth} {experience.startYear} -{" "}
                          {experience.endMonth} {experience.endYear}
                        </h2>
                        <h2>{experience.location}</h2>
                        <p>
                          {experience.description && "- "}{" "}
                          {experience.description}
                        </p>
                      </div>
                      <div className="mentordash__modal">
                        <Modal
                          open={openEditExperience === experience._id}
                          onClose={() => setOpenEditExperience(null)}
                        >
                          <EditExperience
                            exp={experience}
                            onClose={() => setOpenEditExperience(null)}
                          />
                        </Modal>
                      </div>
                    </div>
                    <img
                      src="./assets/white-pen.svg"
                      alt=""
                      onClick={() => setOpenEditExperience(experience._id)}
                    />
                  </div>
                ))}

                {experience?.length === 0 && (
                  <p className="metordash__noData">No education Added</p>
                )}
              </div>
            </div>

            {/* ---------Mentors sessions section---------- */}
            {storedRole === "mentor" && mentorSessionData?.length > 0 && (
              <div className="mentordash__section">
                <h1>Upcoming sessions:</h1>
                {mentorSessionData?.length === 0 && "No Upcoming sessions"}
                {mentorSessionData?.map((s) => (
                  <div className="metordash__up-s-container" key={s._id}>
                    <div className="metordash__up-s-time">
                      <h1>
                        <CountdownTimer targetUtcTime={s?.start_time} />
                      </h1>
                      <p>
                        <DateTimeConverter utcTimestamp={s?.start_time} />
                      </p>
                    </div>
                    <div className="metordash__up-s-slot">
                      <div className="metordash__up-s-schdule">
                        <Avatar src={s?.mentee[0].picture} />
                        <p>Session with {s?.mentee[0].name}</p>
                      </div>
                      <div className="metordash__up-s-btn">
                        <a href={`/room/${s?._id}`} target="_blank">
                          <div className="metordash__up-s-btn1">Join</div>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {storedRole != "mentor" && sessionData?.length > 0 && (
              <div className="mentordash__section">
                <h1>Upcoming sessions:</h1>
                {sessionData?.length === 0 && "No Upcoming sessions"}
                {sessionData?.map((s) => (
                  <div className="metordash__up-s-container" key={s._id}>
                    <div className="metordash__up-s-time">
                      <h1>
                        <CountdownTimer targetUtcTime={s?.start_time} />
                      </h1>
                      <p>
                        <DateTimeConverter utcTimestamp={s?.start_time} />
                      </p>
                    </div>
                    <div className="metordash__up-s-slot">
                      <div className="metordash__up-s-schdule">
                        <Avatar src={s.mentorUser[0].picture} />
                        <p>Session with {s.mentorUser[0].name}</p>
                      </div>
                      <div className="metordash__up-s-btn">
                        {/* <a href={s?.gMeetLink} target="_blank"> */}
                        <a href={`/room/${s?._id}`} target="_blank">
                          <div className="metordash__up-s-btn1">Join</div>
                        </a>
                        <div
                          className="metordash__up-s-btn1"
                          onClick={() => setOpenReschedule(s._id)}
                        >
                          Reschedule
                        </div>
                        {s.sessionType === "free" ? (
                          <div
                            className="metordash__up-s-btn2"
                            onClick={() => setOpenCancelSlot(s._id)}
                          >
                            Cancel
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="mentordash__modal">
                          <Modal
                            open={openCancelSlot === s._id}
                            onClose={() => setOpenCancelSlot(null)}
                          >
                            <DeleteChatModal
                              handleDeleteChat={() => {
                                handleCancelSlot(s._id);
                              }}
                              onClose={() => setOpenCancelSlot(null)}
                              title="Confirm cancel session"
                              btn="Confirm"
                            />
                          </Modal>
                        </div>
                      </div>
                      <div className="mentordash__modal">
                        <Modal
                          open={openReschedule === s._id}
                          onClose={() => setOpenReschedule(null)}
                        >
                          <RescheduleModal
                            mentorUserId={s.mentorUserId}
                            idWhichReschedule={s._id}
                            onClose={() => setOpenReschedule(null)}
                          />
                        </Modal>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* ---------past sessions section---------- */}
            {storedRole != "mentor" && pastSessionData?.length > 0 && (
              <div className="mentordash__section">
                <h1>Past sessions:</h1>
                {pastSessionData?.length === 0 && "No Past sessions"}
                {pastSessionData?.map((s) => (
                  <div className="metordash__up-s-container" key={s._id}>
                    <div className="metordash__up-s-time">
                      <h1>
                        Your session was on{" "}
                        <DateTimeConverter utcTimestamp={s?.start_time} />
                      </h1>
                      <p></p>
                    </div>
                    <div className="metordash__up-s-slot">
                      <div className="metordash__up-s-schdule">
                        <Avatar src={s.mentorUser[0].picture} />
                        <p>Session with {s.mentorUser[0].name}</p>
                      </div>
                      <div className="metordash__up-s-btn">
                        <Link to={`/profile?userId=${s.mentorUserId}`}>
                          <div className="metordash__up-s-btn1">Book again</div>
                        </Link>
                        <div className="mentordash__modal">
                          <Modal
                            open={openCancelSlot === s._id}
                            onClose={() => setOpenCancelSlot(null)}
                          >
                            <DeleteChatModal
                              handleDeleteChat={() => {
                                handleCancelSlot(s._id);
                              }}
                              onClose={() => setOpenCancelSlot(null)}
                            />
                          </Modal>
                        </div>
                      </div>
                      <div className="mentordash__modal">
                        <Modal
                          open={openReschedule === s._id}
                          onClose={() => setOpenReschedule(null)}
                        >
                          <RescheduleModal
                            mentorUserId={s.mentorUserId}
                            idWhichReschedule={s._id}
                            onClose={() => setOpenReschedule(null)}
                          />
                        </Modal>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {storedRole === "mentor" && (
              <div className="mentordash_stats-main">
                <h1>
                  You have given mentorship
                  <br />
                  to more than {introduction?.mentorshipProvided} mentees
                  <br /> till now.....
                </h1>

                <div className="mentordash_stats">
                  <div>
                    <CircularProgressBar
                      value={introduction?.mentorshipProvided}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <ScrollTop />
    </>
  );
};

export default MentorDashboard;
