// AnotherComponent.js
import React from "react";
import BookingSuccess from "../../components/BookingSuccess/BookingSuccess";

function BookingSuccessfullScreen() {
  return (
    <div>
      <BookingSuccess />
    </div>
  );
}

export default BookingSuccessfullScreen;
