import "../BookFreeTrial/BookFreeTrial.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
// import CalDetails from "./CalDetails";
import PaidBasicDetails from "./PaidBasicDetails";
import PaidSlotDetails from "./PaidSlotDetails";
import API_URL from "../../api/Api";

const BookPaidSession = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    var userId = searchParams.get('userId');

    const [activeFormStep, setActiveFormStep] = useState(1);
    const [selectedAreas, setSelectedAreas] = useState([]);

    const handleNext = () => {
        setActiveFormStep((prevStep) => prevStep + 1);
    };

    const handlePrevious = () => {
        setActiveFormStep((prevStep) => prevStep - 1);
    };

    const navigate = useNavigate();
    const [selectedHowMentorship, setSelectedHowMentorship] = useState([]);
    const [selectedExpectedTimeline, setSelectedExpectedTimeline] = useState([]);
    const [trailSessionId, setTrailSessionId] = useState();

    const howMentorshipArray = [
        "Clear path",
        "Solve doubts",
        "Connections",
        "Investor & Referrals",
        "Social Impact",
        "Legal Terms",
        "Consultation & End to End Support",
    ];

    const expectedTimelineArray = [
        "Upto 1 months",
        "Upto 6 months",
        "Upto 1 year",
    ];

    const handleHowMentorshipChipClick = (area) => {
        setSelectedHowMentorship((prevSelected) => [...prevSelected, area]);
        console.log(selectedHowMentorship);
    };

    const handleSelectedExpectedTimelineChipClick = (area) => {
        setSelectedExpectedTimeline((prevSelected) => [...prevSelected, area]);
        console.log(selectedExpectedTimeline);
    };
    const [sessionFormId, setSessionFormId] = useState();
    const [formData, setFormData] = useState({
        user: "",
        mentor: "",
        whyNeedMentorShip: "",
        describeYourNeed: "",
        howMentorship: [],
        expectedTimeline: [],
        Date: Date,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            formData.howMentorship = selectedHowMentorship;
            formData.expectedTimeline = selectedExpectedTimeline;
            console.log(formData);

            // const response = await axios.post(
            //   `${API_URL}/api/BasicDetails/createBooking`,
            //   formData
            // );
            // if (response.data.ok) {
            //   navigate("/success");
            // } else {
            //   alert("Error");
            // }

            // console.log("Data submitted:", response.data);
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    const [userData, setUserData] = useState(null);
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/api/infoById/${userId}`
                );
                console.log(response.data.mentorData[0].user);
                setUserData(response.data.mentorData[0].user);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [userId]);
    return (
        <>
            <div className="bft__wrapper-main">
                <div className="bft__wrapper">
                    <div className="bft__body">
                        <div className="bft__form">

                            {/* {activeFormStep === 1 && <BasicDetails key={1} onNext={handleNext} trailSessionId={trailSessionId} setTrailSessionId={setTrailSessionId} />}
                            {activeFormStep === 2 && <CalDetails key={2} onNext={handleNext} trailSessionId={trailSessionId} />} */}
                            {activeFormStep === 1 && <PaidBasicDetails key={1} onNext={handleNext} setSessionFormId={setSessionFormId}/>}
                            {activeFormStep === 2 && <PaidSlotDetails key={2} onNext={handleNext} sessionFormId={sessionFormId} userData={userData}/>}

                        </div>
                        <div className="bft__intruct">
                            {activeFormStep === 1 &&
                                // <img key={3} src="./assets/bft-bg1.svg" alt="" />
                                <>
                                    <div className="bft__r-main">
                                        <h1>How to use Trial Session?</h1>
                                        <div>
                                            <div>
                                                <h2>Tell mentor about your problems , goals & Targets </h2>
                                                <p>The clearer you are the better the mentorship can help.</p>
                                            </div>
                                            <div>
                                                <h2>Understand how the mentor plans to achieve your goals</h2>
                                                <p>Having a list of questions & Confusions to ask will help you immensely</p>
                                            </div>
                                            <div>
                                                <h2>Finalise the duration & structure of your 1:1 Mentorship</h2>
                                                <p>Clarify all your doubts about how it will work</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {activeFormStep === 2 &&
                                <>
                                    <div className="bft__r-main">
                                        <h1>Trial Session Ground Rules</h1>
                                        <div>
                                            <div>
                                                <h2>Make sure to join the session on time</h2>
                                                <p>Please respect and value your mentor’s time. Failing to attend will result in session cancellation. Option to book again can only be given by the mentor.</p>
                                            </div>
                                            <div>
                                                <h2>Understand how the mentor plans to achieve your goals</h2>
                                                <p>In case of unavailability, you and the mentor both have an option to reschedule the session, before the scheduled time. Unattendance after this can cause cancellation.</p>
                                            </div>
                                            <div>
                                                <h2>Finalise the duration & structure of your 1:1 Mentorship</h2>
                                                <p>It is a good practice to always text your mentor via chat and keep them informed. You can also reach out to Preplaced support for any assistance.</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="bft__wrapper-main">
                <div className="bft__wrapper">
                    <div className="bft__body">
                    <div className="bft__body">
                        <div className="bft__form">
                            {activeFormStep === 1 && <PaidBasicDetails key={1} onNext={handleNext} trailSessionId={trailSessionId} setTrailSessionId={setTrailSessionId} />}
                            {activeFormStep === 2 && <PaidSlotDetails key={2} onNext={handleNext} trailSessionId={trailSessionId} />}
                        </div>
                        <div className="bft__intruct">
                            {activeFormStep === 1 && <img key={3} src="./assets/bft-bg1.svg" alt="" />}
                            {activeFormStep === 2 && <img key={4} src="./assets/bft-bg2.svg" alt="" />}
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};
export default BookPaidSession;
