import './Slider.css';
import React from 'react';

const Slider = () => {

  return (<>
    <div className="slider__slide-padders">
      <div className="slider__slide-container-1280">
        <div className="slider__slide-container-1280-width">

          <div className="slider__sliding">
            <div className='slider__slide__main'>

              <h1>Our Top Mentors Come From</h1>

              <div className="slider__slide__company">

                <div className="slider__slide-logos-main">
                <div className="slider__slide-logos">
                  <div className="slider__slide-logos-slide">

                    <img src="./assets/google.png" alt="" />
                    <img src="./assets/microsoft.png" alt="" />
                    <img src="./assets/goldmanSachs.png" alt="" />
                    <img src="./assets/harvard.png" alt="" />
                    <img src="./assets/IIMB.png" alt="" />
                    <img src="./assets/IIT Bombay.png" alt="" />

                  </div>
                  <div className="slider__slide-logos-slide">
                    <img src="./assets/google.png" alt="" />
                    <img src="./assets/microsoft.png" alt="" />
                    <img src="./assets/goldmanSachs.png" alt="" />
                    <img src="./assets/harvard.png" alt="" />
                    <img src="./assets/IIMB.png" alt="" />
                    <img src="./assets/IIT Bombay.png" alt="" />
                  </div>
                </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="mentors__statsMain">
        <div className="mentors__stats">
          <img src="./assets/Stats_star.png" alt="" />
          <div>
            <h1>100+</h1>
            <p>Mentorship Provided</p>
          </div>
        </div>
        <div className="mentors__stats">
          <img src="./assets/Stats_hike.png" alt="" />
          <div>
            <h1>150%</h1>
            <p>Average Hike</p>
          </div>
        </div>
      </div>
    </div>

  </>
  )
}

export default Slider
