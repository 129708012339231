import React, { useEffect, useState } from "react";
import "./PreviousPayment.css";
import API_URL from "../../api/Api";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { DataState } from "../../context/DataProvider";
import { Avatar } from "@mui/material";

const PreviousPayment = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  var userId = searchParams.get("userId");
  // var sessionId = searchParams.get("sessionId");
  // var bookingId = searchParams.get("bookingId");
  const sessionFormId = searchParams.get("sessionFormId");
  const availableSlotId = searchParams.get("availableSlotId");
  const menteeUserId = searchParams.get("menteeUserId");
  

  const { accessToken } = DataState();
  const [mentorDetails, setMentorDetails] = useState();

  const getMentorDetails = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/mentor/getBasicMentorDetails/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response.data.mentorData[0]);
      setMentorDetails(response.data.mentorData[0]);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    getMentorDetails();
  });

  return (
    <>
      <div className="payment_body">
        <div className="payment_body_left">
          <div className="mentor_details">
            <Avatar src={mentorDetails?.user?.picture} />
            <div className="mentor_name">{mentorDetails?.user?.name}</div>
          </div>
          <div className="mentor_payment_about">
            {mentorDetails?.about[0]?.about?.slice(0, 300)}
            {mentorDetails?.about[0]?.about?.slice(0, 300) > 300 && (
              <span>. . .</span>
            )}
          </div>
          <div className="choose_plan">Choose Plan</div>
          <div className="choose_plan_desc">
            A duration that adjusts to your goal and learning pace
          </div>
          <div className="payment_monthly_plans_parent">
            <div className="payment_monthly_plans">
              <div className="payment_monthly_plans_left">
                <div className="payment_monthly_plans_left_month">1 Month</div>
                <div className="payment_monthly_plans_left_month_desc">
                  Ideal for entrepreneurs requiring quick, impactful adjustments
                  to their business approach
                </div>
              </div>
              <div className="payment_monthly_plans_right">
                <span>₹3000</span> /month
              </div>
            </div>
            <div className="payment_monthly_plans">
              <div className="payment_monthly_plans_left">
                <div className="payment_monthly_plans_left_month">3 Months</div>
                <div className="payment_monthly_plans_left_month_desc">
                  Ideal for entrepreneurs requiring quick, impactful adjustments
                  to their business approach
                </div>
              </div>
              <div className="payment_monthly_plans_right">
                <span>₹7000</span> /month
              </div>
            </div>{" "}
            {/* <div className="payment_monthly_plans">
              <div className="payment_monthly_plans_left">
                <div className="payment_monthly_plans_left_month">
                  Equity Based Mentorship
                </div>
                <div className="payment_monthly_plans_left_month_desc">
                  Founders who seek mentorship for the long haul recognize the
                  value of consistent guidance, support, and strategic insights
                  throughout their startup's growth. It's not just about quick
                  fixes; it's about building a strong foundation for sustainable
                  success.
                </div>
              </div>
              <div className="payment_monthly_plans_right">
                <span id="negotiable_equity_price">Negotiable % </span> /year
              </div>
            </div> */}
          </div>
        </div>
        <div className="payment_body_right">
          <h3 className="payment_body_info">Payment Info</h3>
          <h4 className="payment_body_text1">Reserve your spot today.</h4>
          <div className="payment_body_text2">1:1 Long Term Mentorship</div>
          <div className="payment_body_session_price">
            {mentorDetails?.remunerationType === "price" && (
              <>
                <span>₹{mentorDetails?.remunerationValue}</span>/ Session
              </>
            )}
            {mentorDetails?.remunerationType === "equity" && (
              <span>Equity {mentorDetails?.remunerationValue}%</span>
            )}
          </div>
          <div className="payment_body_coupon">
            <input
              className="payment_coupon_code"
              type="text"
              placeholder="Gift Card or Discount Code"
            />
            <button className="payment_body_coupon_btn">Apply</button>
          </div>
          <div className="payment_details_order_summany">Order Summary</div>
          <hr />
          <div className="payment_details_first">
            <div className="payment_details_first_long_term">
              Long Term Mentorship
            </div>
            <div className="payment_details_first_price">
              {mentorDetails?.remunerationType === "price" && (
                <>
                  <span>₹{mentorDetails?.remunerationValue}</span>
                </>
              )}
              {mentorDetails?.remunerationType === "equity" && (
                <span>Equity {mentorDetails?.remunerationValue}%</span>
              )}
            </div>
          </div>
          {/* <div className="payment_details_first">
            <div className="payment_details_first_long_term">
              Long Term Mentorship
            </div>
            <div className="payment_details_first_price">₹499</div>
          </div> */}
          <hr />
          <div className="spaceing_for_mobile"></div>
          <div className="payment_final_div">
            <div className="payment_details_final">
              <div className="payment_details_final_long_term">
                Order Total :
              </div>
              <div className="payment_details_final_price">
                {mentorDetails?.remunerationType === "price" && (
                  <>
                    <span>₹{mentorDetails?.remunerationValue}</span>
                  </>
                )}
                {mentorDetails?.remunerationType === "equity" && (
                  <span>Equity {mentorDetails?.remunerationValue}%</span>
                )}
              </div>
            </div>
            {/* <Link to='http://www.inqbator.in/api/phonepe'> */}
            <Link
              // to={`${API_URL}/api/phonepe?amount=${mentorDetails?.remunerationValue}&bookingId=${bookingId}`}
              to={`${API_URL}/api/phonepe?amount=${mentorDetails?.remunerationValue}&userId=${userId}&sessionFormId=${sessionFormId}&menteeUserId=${menteeUserId}&availableSlotId=${availableSlotId}`}
              
            >
              <button className="payment_final_button">Pay</button>
            </Link>
            <div className="phonepe_secured_div">
              <div className="phonepe_secured_by_word">Secured by</div>
              <img src="./assets/PhonePe svg.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviousPayment;
