import React from 'react'
import './css/ShowEducationModal.css'
import CloseIcon from '@mui/icons-material/Close';

const ShowEducationModal = ({ eduData, onClose }) => {
  return (
    <>
      <div className='sem__container'>
        <div className="sem__main">
          <h1>All Educations
            <span onClick={() => { onClose() }} >
              <CloseIcon fontSize='4rem' color='white' />
            </span>
          </h1>
          <div className="profile__edu profile__about-exp">
              {eduData?.map((edu, index) => (
                <div className="profile__about-exp-comp" key={index}>
                  <div className="profile__about-exp-comp-img">
                    <img src={edu.schoolImage} alt="" />
                    <h2>{edu.school}</h2>
                  </div>
                  <div className="profile__about-exp-comp-text">
                    <h1>
                      {edu.degree} {edu.fieldOfStudy.length === 0 && "-"} {edu.fieldOfStudy}
                    </h1>
                    <span>
                      {edu.startYear} - {edu.endYear}
                    </span>
                  </div>
                </div>
              ))}
            </div>
        </div>
      </div>
    </>
  )
}

export default ShowEducationModal