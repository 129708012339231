import React, { useState, useEffect } from "react";
import "../BookFreeTrial/CalDetails.css";
import CalenderTest from "../BookFreeTrial/CalenderTest";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import API_URL from "../../api/Api";
import { DataState } from "../../context/DataProvider";
import { Backdrop, CircularProgress } from "@mui/material";
import toast from "react-hot-toast";

const PaidSlotDetails = ({ onNext, sessionFormId, userData }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search);
    var userId = searchParams.get('userId');

    const [selectedTime, setSelectedTime] = useState(null);
    const [sendLoading, setSendLoading] = useState(false);
    const { accessToken } = DataState();

    const bookSlots = async () => {
        try {
            setSendLoading(true)
            // const response = await axios.post(`${API_URL}/api/bookMentorSlot/book?availableSlotId=${selectedTime}&paidSessions=${trailSessionId}&mentorUserId=${userId}&sessionType=paid`
            const response = await axios.post(`${API_URL}/api/session/book?availableSlotId=${selectedTime}&mentorUserId=${userId}&sessionFormId=${sessionFormId}&sessionType=paid`
            , {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log(response.data)
            if (response.data.ok === true) {
                setSendLoading(false)
                // navigate(`/book-sessions-payment?userId=${userId}&sessionId=${trailSessionId}&bookingId=${response.data.id}`)
                // Here bookingId = sessionId( id of the session created in backend )
                navigate(`/book-sessions-payment?userId=${userId}&sessionFormId=${response.data.sessionFormId}&availableSlotId=${response.data.availableSlotId}&menteeUserId=${response.data.menteeUserId}`)
            } else {
                setSendLoading(false)
                toast.error('Some Error occur')
            }
        } catch (error) {
            console.log(error)
            setSendLoading(false)
        }
    }


    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={sendLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="cd__main">
                <div className="cd__body">
                    <div className="bd__progressBar">
                        <img src="./assets/progress bar 1.png" alt="" />
                    </div>
                    <h1 className="bd__heading-main">
                        Schedule your paid session call with {userData?.name}
                        <img className="imgTop" src="./assets/arrow.png" alt="" />
                    </h1>
                    <div className="cd__content">
                        <div className='cd__cal'>
                            <CalenderTest userId={userId} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
                        </div>
                        {selectedTime ? 
                        <div className={`cd__submit`}
                            onClick={bookSlots}
                        >
                            <div>
                                <p>Reserve slot</p>
                                <img src="./assets/maki_arrow.png" alt="" />
                            </div>
                        </div>
                        :
                        <div className={`cd__submit disabled`}
                        >
                            <div>
                                <p>Reserve slot</p>
                                <img src="./assets/maki_arrow.png" alt="" />
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaidSlotDetails;
